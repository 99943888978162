section.account .account-assistance .contact .call-us-box a.green-button {
    text-decoration: none!important;
    display: flex;
    align-items: center;
    min-height: auto;
    padding: 11px 12px 11px 18px;
    color: #FFFFFF!important;
    height: 46px;
}
section.account .account-assistance .contact .call-us-box a.green-button svg {
    margin-left: auto;
}