section.free-call-medic {
    max-width: 400px;
    margin: auto;
}
section.free-call-medic .title-section {
    margin-top: 40px;
    margin-bottom: 40px;
}
section.free-call-medic .title-section h1 {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 110%;
    text-align: center;
    color: #001315;
    margin: 0;
}
section.free-call-medic button svg {
    position: absolute;
    right: 20px;
}
section.free-call-medic .or {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #80898A;
    margin: 18px 0;
}
section.free-call-medic form .custom-checkbox {
    margin-top: 10px;
    margin-bottom: 20px;
}
section.free-call-medic .info-message {
    background: #E5E7E8;
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #80898A;
    margin-bottom: 10px;
    margin-top: 40px;
}
section.free-call-medic .box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
}
section.free-call-medic .box .medic-avatar {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    width: 159px;
    height: 159px;
    border-radius: 100%;
    border: 1px solid rgba(0, 19, 21, 0.1);
    margin-bottom: 12px;
}
section.free-call-medic .box svg.custom-icon {
    margin-bottom: 12px;
}
section.free-call-medic .box .searching-circles-orange-icon {
    background-image: url("../../shared/assets/icons/searching-circles-orange-icon.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center center;
    display: inline-block;
    width: 46px;
    height: 12px;
    margin-bottom: 18px;
}
section.free-call-medic .box h1 {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: #001315;
    padding: 0 40px;
    margin: 0;
}
section.free-call-medic .box ul {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
}
section.free-call-medic .box ul li {
    border-bottom: 1px solid #F2F3F3;
    margin-bottom: 15px;
    padding-bottom: 12px;
}
section.free-call-medic .box ul li:last-child {
    border: 0;
    margin: 0;
    padding: 0;
}
section.free-call-medic .box ul li .field-title {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #80898A;
    margin-bottom: 5px;
}
section.free-call-medic .box ul li .field-value {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
}
section.free-call-medic .box .will-start {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: #001315;
}
section.free-call-medic .box .timer {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: #FF6A39;
}