section.account .account-rates input.form-control:focus,
section.account .account-rates input.form-control {
    border: 2px solid #E5E7E8!important;
}
section.account .account-rates .green-button {
    margin-top: 10px;
}

.doctor-services-box {
    border: 1px solid #E5E7E8;
    border-radius: 5px;
    padding: 20px;
}

.doctor-services-box-rates {
    border: 0px solid #E5E7E8;
    background: #FFFFFF;
}

.subtitle-services {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.1em;
    color: #80898A;
    margin-bottom: 10px;
    display: flex;
}

.specializations-rates .specialization-item {
    border: 1px solid #E5E7E8;
}

.specializations-rates .specialization-item.disabled {
    border: 1px solid #E5E7E8;
    background-color: #DDDDDD;
    cursor: auto;
}

.title-section-with-back {
    display: flex;
}

.title-section-with-back .icon-wrapper{
    margin-right: 20px;
}

