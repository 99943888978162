section.dashboard .title-section {
    margin-top: 40px;
    margin-bottom: 40px;
}

section.dashboard .title-section .small-title-text {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #001315;
}

section.dashboard .title-section h1 {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 110%;
    text-align: center;
    color: #001315;
    margin: 0;
}

section.dashboard .subtitle {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #80898A;
    margin-top: 20px;
    margin-bottom: 10px;
}

section.dashboard .right-column-dashboard .subtitle:first-child {
    margin-top: 0;
}

section.dashboard .income-received-box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 10px 0;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
}

section.dashboard .income-received-box .amount-this-month {
    border-right: 1px solid #F2F3F3;
    display: inline-flex;
    width: 65%;
    flex-direction: column;
    padding-left: 20px;
    padding-right: 35px;
    font-family: 'Rota';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 19px;
    color: #001315;
}

section.dashboard .income-received-box .amount-this-month .value {
    font-family: 'Rota';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: #00CFB4;
}

section.dashboard .income-received-box .amount-this-month span {
    width: 70%;
}

section.dashboard .income-received-box .missed-calls {
    display: inline-flex;
    width: 35%;
    flex-direction: column;
    padding-left: 20px;
    padding-right: 65px;
    font-family: 'Rota';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 19px;
    color: #001315;
}

section.dashboard .income-received-box .missed-calls .value {
    font-family: 'Rota';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: #80898A;
}

section.dashboard .switch-box {
    border-radius: 12px;
}

section.dashboard .switch-box .list-group-item {
    padding: 0 20px;
    border: 0;
}

section.dashboard .switch-box .list-group-item .custom-switch {
    padding-top: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid #F2F3F3;
}

section.dashboard .switch-box .list-group-item:last-child .custom-switch {
    border: 0;
}

section.dashboard .next-fast-box {
    margin-bottom: 10px;
    background: #FFFFFF;
    border-radius: 12px;
    padding: 14px 20px;
}

section.dashboard .next-fast-box:last-child {
    margin-bottom: 0;
}

section.dashboard .next-fast-box .green-button {
    min-height: initial;
    margin-top: 15px;
}

section.dashboard .next-fast-box .info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-decoration: none !important;
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    color: #001315;
}

section.dashboard .next-fast-box .info .fullName {
    font-size: 16px;
    line-height: 20px;
}

section.dashboard .next-fast-box .info .timer {
    font-size: 18px;
    line-height: 22px;
    display: flex;
    align-items: center;
    justify-content: end;
}

section.dashboard .next-fast-box .info .timer p {
    margin-bottom: 0;
}

section.dashboard .next-fast-box .info .status-badge {
    background: #F2F3F3;
    border-radius: 12px;
    padding: 5px 10px;
    color: #80898A;
    font-size: 14px;
    line-height: 14px;
    margin: 0;
    font-weight: 700;
}

section.dashboard .next-schedule-box .patient-box .avatar-section {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

section.dashboard .next-schedule-box .patient-box .avatar-section .user-image {
    margin: 0 !important;
    width: auto !important;
    height: auto !important;
    border-radius: 100% !important;
}

section.dashboard .next-schedule-box .patient-box .avatar-section .avatar-picture {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    width: 36px;
    height: 36px;
    border-radius: 100%;
    border: 1px solid rgba(0, 19, 21, 0.1);
    position: relative;
}

section.dashboard .next-schedule-box .patient-box .avatar-section .user-text {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Rota';
    font-style: normal;
    font-weight: 700;
    font-size: 16px !important;
    line-height: 20px !important;
    text-align: center;
    color: #80898A !important;
    width: 36px;
    height: 36px;
    border-radius: 100%;
    border: 1px solid rgba(0, 19, 21, 0.1);
}

section.dashboard .next-schedule-box .patient-box .avatar-section svg {
    margin-left: auto;
    margin-right: -8px;
}

section.dashboard .next-schedule-box .patient-box .fullName {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
}

section.dashboard .next-schedule-box .patient-box .requested-specialization {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #001315;
}

section.dashboard .next-schedule-box .patient-box .year-files {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #80898A;
    display: flex;
    align-items: center;
}

section.dashboard .next-schedule-box .patient-box .year-files svg {
    margin-right: 4px;
    margin-left: 4px;
}

section.dashboard .call-now-box {
    margin-bottom: 10px;
    background: #FFFFFF;
    border-radius: 12px;
    padding: 14px 20px;
}

section.dashboard .call-now-box .actions {
    display: flex;
    align-items: center;
}

section.dashboard .call-now-box .actions .accept-button {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    color: #FFFFFF !important;
    text-decoration: none !important;
    background: #00CFB4;
    border: 0;
    box-sizing: border-box;
    border-radius: 12px;
    padding: 14px 8px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0 5px;
}

section.dashboard .call-now-box .actions .accept-button > svg {
    margin-right: 10px;
}

section.dashboard .call-now-box .actions .cancel-button {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: #EB3300;
    background: #FFFFFF;
    border: 1px solid #E5E7E8;
    box-sizing: border-box;
    border-radius: 12px;
    padding: 14px 8px;
    display: inline-flex;
    justify-content: center;
    width: 100%;
    margin: 0 5px;
}

section.dashboard .call-now-box .patient-box {
    display: flex;
    flex: 1;
    flex-direction: column;
    text-decoration: none !important;
    align-items: center;
    margin-bottom: 15px;
}

section.dashboard .call-now-box .patient-box .avatar-section {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

section.dashboard .call-now-box .patient-box .avatar-section .user-image {
    margin: 0 !important;
    width: auto !important;
    height: auto !important;
    border-radius: 100% !important;
}

section.dashboard .call-now-box .patient-box .avatar-section .avatar-picture {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    width: 36px;
    height: 36px;
    border-radius: 100%;
    border: 1px solid rgba(0, 19, 21, 0.1);
    position: relative;
}

section.dashboard .call-now-box .patient-box .avatar-section .user-text {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Rota';
    font-style: normal;
    font-weight: 700;
    font-size: 16px !important;
    line-height: 20px !important;
    text-align: center;
    color: #80898A !important;
    width: 36px;
    height: 36px;
    border-radius: 100%;
    border: 1px solid rgba(0, 19, 21, 0.1);
}

section.dashboard .call-now-box .patient-box .avatar-section svg {
    margin-left: auto;
    margin-right: -8px;
}

section.dashboard .call-now-box .patient-box .fullName {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
}

section.dashboard .call-now-box .patient-box .year-files {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #80898A;
    display: flex;
    align-items: center;
}

section.dashboard .call-now-box .patient-box .year-files svg {
    margin-right: 4px;
    margin-left: 4px;
}

section.dashboard .next-schedule-box {
    display: flex;
    margin-bottom: 10px;
}

section.dashboard .next-schedule-box:last-child {
    margin-bottom: 0;
}

section.dashboard .next-schedule-box .hour-box {
    background: #FFFFFF;
    border-radius: 12px;
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #001315;
    margin-right: 10px;
    width: 66px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

section.dashboard .start-consultation-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF !important;
    text-decoration: none !important;
}

section.dashboard .start-consultation-btn.not-ready {
    padding: 0;
    pointer-events: none;
    background: #E5E7E8 !important;
    border-color: #E5E7E8 !important;
    color: #80898A !important;
}

section.dashboard .start-consultation-btn .timer p {
    margin-bottom: 0;
    margin-left: 5px;
    min-width: 42.5px;
}

section.dashboard .start-consultation-btn svg {
    margin-right: 10px;
}

section.dashboard .next-schedule-box .patient-box {
    display: flex;
    flex-direction: column;
    text-decoration: none !important;
    background: #FFFFFF;
    border-radius: 12px;
    padding: 14px 20px;
    width: calc(100% - 66px);
}

section.dashboard .next-schedule-box .patient-box .avatar-section {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

section.dashboard .next-schedule-box .patient-box .avatar-section .user-image {
    margin: 0 !important;
    width: auto !important;
    height: auto !important;
    border-radius: 100% !important;
}

section.dashboard .next-schedule-box .patient-box .avatar-section .avatar-picture {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    width: 36px;
    height: 36px;
    border-radius: 100%;
    border: 1px solid rgba(0, 19, 21, 0.1);
    position: relative;
}

section.dashboard .next-schedule-box .patient-box .avatar-section .user-text {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Rota';
    font-style: normal;
    font-weight: 700;
    font-size: 16px !important;
    line-height: 20px !important;
    text-align: center;
    color: #80898A !important;
    width: 36px;
    height: 36px;
    border-radius: 100%;
    border: 1px solid rgba(0, 19, 21, 0.1);
}

section.dashboard .next-schedule-box .patient-box .avatar-section svg {
    margin-left: auto;
    margin-right: -8px;
}

section.dashboard .next-schedule-box .patient-box .fullName {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
}

section.dashboard .next-schedule-box .patient-box .year-files {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #80898A;
    display: flex;
    align-items: center;
}

section.dashboard .next-schedule-box .patient-box .year-files svg {
    margin-right: 4px;
    margin-left: 4px;
}

section.dashboard .next-schedule-box .empty-container {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #80898A;
}

section.dashboard .quick-access-box {
    border-radius: 12px;
}

section.dashboard .quick-access-box .list-group-item {
    padding: 0 20px;
    border: 0;
}

section.dashboard .quick-access-box .list-group-item:first-child {
    border-radius: 12px 12px 0 0;
}

section.dashboard .quick-access-box .list-group-item:last-child {
    border-radius: 0 0 12px 12px;
}

section.dashboard .quick-access-box .list-group-item a {
    display: flex;
    align-items: center;
    padding-top: 12px;
    padding-bottom: 12px;
    text-decoration: none !important;
    border-bottom: 1px solid #F2F3F3;
}

section.dashboard .quick-access-box .list-group-item a .title,
section.dashboard .next-fast-box .info .title {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
}

section.dashboard .quick-access-box .list-group-item a .title .description,
section.dashboard .next-fast-box .info .title .description {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #80898A;
}

section.dashboard .quick-access-box .list-group-item a svg {
    margin-left: auto;
}

.home-start-consultation-btn {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 12px;
    padding-right: 12px;
    margin: 0;
    width: auto;
}

.home-start-consultation-btn .custom-icon {
    margin-right: 10px;
}
