section.video-call .header-top {
    margin-top: 40px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
}
section.video-call .header-top .medic-info {
    display: inline-flex;
    align-items: center;
    margin-right: auto;
}
section.video-call .header-top .medic-info .avatar {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    width: 36px;
    height: 36px;
    border-radius: 100%;
    border: 1px solid rgba(0, 19, 21, 0.1);
    position: relative;
    margin-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
section.video-call .header-top .medic-info .name {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
}
section.video-call .header-top .medic-info .specialization {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #80898A;
}
section.video-call .header-top .time-left {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: right;
    color: #80898A;
}
section.video-call .video-container {
    position: relative;
}
/*section.video-call .video-container .medic-video {*/
/*    filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.15)) drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.15));*/
/*    box-sizing: border-box;*/
/*    border-radius: 12px;*/
/*    min-height: 580px;*/
/*    background: #333;*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*}*/
/*section.video-call .video-container .medic-video > div {*/
/*    display: flex;*/
/*}*/
/*section.video-call .video-container .medic-video video {*/
/*    object-fit: contain;*/
/*    height: 580px;*/
/*}*/
/*section.video-call .video-container .patient-video {*/
/*    position: absolute;*/
/*    top: 20px;*/
/*    right: 20px;*/
/*    filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.15)) drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.15));*/
/*    background-color: #80898A;*/
/*    border-radius: 12px;*/
/*    width: 168px;*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*}*/
/*section.video-call .video-container .patient-video > div {*/
/*    display: flex;*/
/*}*/
/*section.video-call .video-container .patient-video video {*/
/*    object-fit: contain;*/
/*    width: 168px;*/
/*}*/
/*section.video-call .video-container .patient-video video,*/
/*section.video-call .video-container .medic-video video {*/
/*    max-width: 100%;*/
/*    width: 100%;*/
/*    border-radius: 12px;*/
/*}*/
/*section.video-call .video-container .local-twilio-user-image,*/
/*section.video-call .video-container .twilio-user-image {*/
/*    position: absolute;*/
/*    display: flex;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*    border-radius: 12px;*/
/*    width: 100%;*/
/*    height: 100%;*/
/*}*/
/*section.video-call .video-container .big-video-screen .local-twilio-user-image,*/
/*section.video-call .video-container .big-video-screen .twilio-user-image {*/
/*    background-color: #333333;*/
/*}*/
/*section.video-call .video-container .small-video-screen .local-twilio-user-image,*/
/*section.video-call .video-container .small-video-screen .twilio-user-image {*/
/*    background-color: #80898A;*/
/*}*/
/*section.video-call .video-container .local-twilio-user-image .avatar-picture,*/
/*section.video-call .video-container .twilio-user-image .avatar-picture {*/
/*    background-size: contain;*/
/*    background-position: center center;*/
/*    background-repeat: no-repeat;*/
/*    border-radius: 12px;*/
/*    width: 100%;*/
/*    height: 100%;*/
/*}*/
/*section.video-call .video-container .local-twilio-user-image .twilio-user-text,*/
/*section.video-call .video-container .twilio-user-image .twilio-user-text {*/
/*    font-family: Rota;*/
/*    font-style: normal;*/
/*    font-weight: 700;*/
/*    font-size: 18px!important;*/
/*    line-height: 22px!important;*/
/*    text-align: center;*/
/*    color: #FFFFFF!important;*/
/*    text-transform: uppercase;*/
/*    display: flex;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*    width: 100%;*/
/*    height: 100%;*/
/*}*/
section.video-call .video-container .info-message-container {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
}
section.video-call .video-container .info-message {
    margin: auto;
    width: 395px;
    background: #E5E7E8;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15), 0px 2px 2px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
    margin-top: 10px;
}
section.video-call .video-container .info-message.minutes-left + .info-message {
    bottom: 80px!important;;
}
section.video-call .video-container .info-message .text {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #80898A;
}
section.video-call .video-container .info-message.minutes-left {
    background: #FF6A39;
}
section.video-call .video-container .info-message.minutes-left svg path {
    fill: #FFFFFF!important;
}
section.video-call .video-container .info-message.minutes-left .text {
    color: #FFF;
}
section.video-call .video-actions {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}
section.video-call .video-actions > div {
    margin: 0 5px;
    cursor: pointer;
}
section.video-call .video-actions .microphone.inactive .circle,
section.video-call .video-actions .camera.inactive .circle {
    background: #001315;
}
section.video-call .video-actions .close .circle {
    background: #F44A0D;
}
section.video-call .video-actions .circle {
    background: #FFFFFF;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15), 0px 2px 2px rgba(0, 0, 0, 0.15);
    border-radius: 51px;
    width: 59px;
    height: 59px;
    display: flex;
    align-items: center;
    justify-content: center;
}
section.video-call .video-actions .title {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #001315;
    margin-top: 5px;
}
.modal-medical-documents .modal-dialog {
    max-width: 440px;
}
.modal-medical-documents .scroll-list {
    /*margin-top: -20px;*/
    /*margin-bottom: -20px;*/
    max-height: 600px;
    overflow-y: auto;
    margin-right: -9px;
    padding-right: 9px;
}
.modal-medical-documents .upload .subtitle {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #80898A;
    margin-top: 20px;
    margin-bottom: 10px;
}
.modal-medical-documents .upload .files-box {
    background: #FFFFFF;
    border-radius: 12px;
}
.modal-medical-documents .upload .files-box label {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 20px;
    margin: 0;
    cursor: pointer;
}
.modal-medical-documents .upload .files-box label svg {
    margin-right: 10px;
}
.modal-medical-documents .upload .files-box input {
    display: none;
}
.modal-medical-documents .new-document .subtitle {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #80898A;
    margin-top: 20px;
    margin-bottom: 10px;
}
.modal-medical-documents .new-document .uploaded-box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 20px;
}
.modal-medical-documents .new-document .uploaded-file {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
}
.modal-medical-documents .new-document .uploaded-file .file-name {
    display: inline-flex;
    align-items: center;
    max-width: 295px;
    margin-right: auto;
}
.modal-medical-documents .new-document .uploaded-file .file-name span {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.modal-medical-documents .new-document .uploaded-file .file-name svg {
    margin-right: 16px;
    min-width: 24px;
}
.modal-medical-documents .new-document .uploaded-file button {
    border: 0;
    padding: 0;
    margin: 0;
    background: none;
}
.modal-medical-documents .new-document button.orange-button {
    padding: 10px 20px;
    min-height: 46px;
}
.modal-medical-documents .choose-documents .subtitle {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #80898A;
    margin-top: 20px;
    margin-bottom: 10px;
}
.modal-medical-documents .choose-documents .documents-box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 16px 20px 0 20px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
}
.modal-medical-documents .choose-documents .documents-box .header {
    border-bottom: 1px solid #F2F3F3;
    padding-bottom: 14px;
    display: flex;
    align-items: center;
}
.modal-medical-documents .choose-documents .documents-box .header .title {
    display: inline-flex;
    margin-right: auto;
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
}
.modal-medical-documents .choose-documents .documents-box .header .custom-checkbox {
    width: 100%;
}
.modal-medical-documents .choose-documents .documents-box .header .custom-checkbox.select-all.active-label-orange .custom-control-label {
    color: #001315;
}
.modal-medical-documents .choose-documents .documents-box .header .custom-checkbox.select-all.active-label-orange .custom-control-input:checked ~ .custom-control-label {
    color: #FF6A39;
}


.medic-view-wrapper {
    filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.15)) drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.15));
    box-sizing: border-box;
    border-radius: 12px;
    height: 580px;
    max-width: 814px;
    background: #333;
    /*display: flex;*/
    justify-content: center;
    align-items: center;
}
.medic-view-wrapper .mwp {
    width: 100%;
    height: 100%;
    display: flex;
}

.medic-view-wrapper .mwp .remoteUserComponent {
    display: none !important;
}

.medic-view-wrapper .mwp .avatar-picture {
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: #000000;
    width: 100%;
    height: 100%;
    border-radius: 12px;
}

.medic-view-wrapper .vid {
    width: 100%;
    border-radius: 12px;
    display: flex;
}

.medic-view-wrapper .vid div {
    display: flex;
    border-radius: 12px;
    align-self: center;
    flex: 0 0 auto;
    height: 512px;
}

.patient-view-wrapper {
    position: absolute;
    top: 20px;
    right: 20px;
    /*filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.15)) drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.15));*/
    /*background-color: #80898A;*/
    border-radius: 12px;
    width: 168px;
    height: 137px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.patient-view-wrapper .vid {
    display: flex;
    width: 100%;
    height: 100%;
}
.patient-view-wrapper .vid div {
    border-radius: 12px;
}

.patient-view-wrapper .avatar-picture {
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: #000000;
    width: 168px;
    height: 137px;
    border-radius: 12px;
}

section.video-call .video-container .agora-user-text {
    font-family: Rota;
    font-style: normal;
    font-weight: 700;
    font-size: 18px!important;
    line-height: 22px!important;
    text-align: center;
    color: #FFFFFF!important;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.rotatedmwp {
    align-items: start;
}

section.video-call .rotated {
    transform: rotate(-90deg);
    width: 580px !important;
    height: 814px !important;
}

.rotatedvid {
    height: 500px;
    margin-top: 40px !important;
    margin-left: 117px;
}

.rotatedmwp-doctor {
    align-items: start;
}

section.video-call .rotated-doctor {
    transform: rotate(-90deg);
    width: 580px !important;
    height: 814px !important;
}

.rotatedvid-doctor {
    height: 500px;
    margin-top: -117px !important;
    margin-left: 117px;
}