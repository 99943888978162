section.account .account-favorite-medics .medic .box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 20px;
    margin-bottom: 20px;
    position: relative;
    border: 1px solid #E5E7E8;
}

section.account .account-favorite-medics .medic .box:last-child {
    margin-bottom: 0;
}

/*section.account .account-favorite-medics .medic .box .share-btn {*/
/*    position: absolute;*/
/*    top: 12px;*/
/*    right: 16px;*/
/*    cursor: pointer;*/
/*}*/
section.account .account-favorite-medics .medic .box .rating {
    font-family: Rota, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #80898A;
    margin-bottom: 15px;
    display: flex;
}

section.account .account-favorite-medics .medic .box .rating svg {
    margin-right: 5px;
}

section.account .account-favorite-medics .medic .box .medic-info {
    display: flex;
    margin-bottom: 15px;
}

section.account .account-favorite-medics .medic .box .medic-info .left-column {
    display: flex;
    flex-direction: column;
    margin-right: auto;
    min-height: 72px;
}

section.account .account-favorite-medics .medic .box .medic-info .name {
    font-family: Rota, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    color: #001315;
}

section.account .account-favorite-medics .medic .box .medic-info .specialization {
    font-family: Rota, serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #001315;
    margin-bottom: 2px;
}

section.account .account-favorite-medics .medic .box .medic-info .clinic {
    font-family: Rota, serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #80898A;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

section.account .account-favorite-medics .medic .box .medic-info .clinic .avatar {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    width: 35px;
    height: 35px;
    border-radius: 100%;
    border: 1px solid rgba(0, 19, 21, 0.1);
    position: relative;
    margin-right: 10px;
}

section.account .account-favorite-medics .medic .box .medic-info .profile-picture {
    display: flex;
    align-items: center;
    margin-left: 20px;
}

section.account .account-favorite-medics .medic .box .medic-info .profile-picture .avatar {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    width: 66px;
    height: 66px;
    border-radius: 100%;
    border: 1px solid rgba(0, 19, 21, 0.1);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

section.account .account-favorite-medics .medic .box .medic-info .profile-picture .avatar .status {
    border: 3px solid #FFFFFF;
    width: 21px;
    height: 21px;
    border-radius: 100%;
    position: absolute;
    top: -3px;
    left: -3px;
}

section.account .account-favorite-medics .medic .box .medic-info .profile-picture svg {
    position: relative;
    right: -8px;
}

section.account .account-favorite-medics .medic .box .medic-info .profile-picture .avatar .status.online {
    background: #00CFB4;
}

section.account .account-favorite-medics .medic .box .medic-info .profile-picture .avatar .status.offline {
    background: #BFC4C5;
}

section.account .account-favorite-medics .medic .box .medic-info .profile-picture .avatar .status.busy {
    background: #F6BE00;
}

section.account .account-favorite-medics .medic .box .description {
    font-family: Rota, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #80898A;
    margin-bottom: 15px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

section.account .account-favorite-medics .medic .box .price {
    font-family: Rota, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #FF6A39;
    margin-bottom: 14px;
}

section.account .account-favorite-medics .medic .box .action-buttons {
    display: flex;
    align-items: center;
    margin: 0 -10px;
}

section.account .account-favorite-medics .medic .box .action-buttons .medic-action-btn {
    font-family: Rota, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    box-sizing: border-box;
    border-radius: 12px;
    padding: 15px ;
    margin: 0 5px;
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}

section.account .account-favorite-medics .medic .box .action-buttons .medic-action-btn .align-items-center {
    font-family: Rota, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    box-sizing: border-box;
    border-radius: 12px;
    padding: 15px ;
    margin: 0 5px;
    /*width: 100%;*/
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}

section.account .account-favorite-medics .medic .box .action-buttons .appointment-btn {
    color: #001315;
    background: #FFFFFF;
    border: 1px solid #E5E7E8;
    display: flex;
    margin-bottom: 10px;
    height: 56px;
    padding: 15px 15px;
}

section.medic-list .box .action-buttons .appointment-btn .appointment-btn-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
}

section.medic-list .box .action-buttons .appointment-btn .appointment-btn-texts .appointment-btn-texts-schedule {
    flex: 1 1 100%;
}

section.account .account-favorite-medics .medic .box .action-buttons .call-now-btn {
    color: #FFFFFF;
    background: #00CFB4;
    border: 1px solid #00CFB4;
}

section.account .account-favorite-medics .medic .box .action-buttons .busy-btn {
    color: #F4AF23;
    background: #FEF5D9;
    border: 1px solid #FEF5D9;
    pointer-events: none;
}

section.account .account-favorite-medics .medic .box .action-buttons .offline-btn {
    color: #80898A;
    background: #F2F3F3;
    border: 1px solid #F2F3F3;
    pointer-events: none;
}

section.account .account-favorite-medics .medic .box .action-buttons .call-now-btn svg,
section.account .account-favorite-medics .medic .box .action-buttons .appointment-btn svg {
    /*margin-right: 13px;*/
}
