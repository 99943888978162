.contact-page,
.contact-page > .container-fluid,
.contact-page > .container-fluid > .row {
    height: 100%;
}
.contact-page header,
.contact-page main {
    height: fit-content;
}
.contact-page section.title {
    display: flex;
    justify-content: center;
}
.contact-page section.title h1 {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 110%;
    text-align: center;
    color: #001315;
    margin-top: 60px;
    margin-bottom: 30px;
}

.contact-page input.form-control:focus,
.contact-page textarea.form-control:focus {
    border: 2px solid #E5E7E8 !important;
    background: #FFFFFF;
    outline: none !important;
    box-shadow: none !important;
}
.contact-page button.btn-send {
    margin-top: 20px;
}
.contact-page .ringdoc-logo {
    background-image: url("../assets/images/ringdoc.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center center;
    width: 87px;
    height: 23px;
    display: inline-block;
}
.contact-page .contact-form {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 40px;
}
.contact-page .contact-form .subtitle {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #80898A;
    margin-top: 30px;
    margin-bottom: 4px;
}
.contact-page .contact-form .contact-info-text {
    font-family: 'Rota';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
}

@media (max-width: 992px){
    .wrapper-container .main-content {
        padding: 30px 25px;
    }
}