.react-confirm-alert-modal .minutes .custom-select {
    border-color: #F2f3f3!important;
    padding: 10px 10px 10px 30px!important;
    background-position: right 7px center;
    height: 46px !important;
    margin: 0!important;
    text-align: left!important;
}
.react-confirm-alert-overlay {
    background: rgba(0, 19, 21, 0.5) !important;
    z-index: 1056 !important;
}

.react-confirm-alert-overlay > .react-confirm-alert {
    font-family: Rota !important;
    background: #FFFFFF !important;
    box-shadow: 0 0 4px rgb(0 0 0 / 15%), 0 4px 8px rgb(0 0 0 / 15%) !important;
    color: #001315 !important;
    border-radius: 12px !important;
    border: 0 !important;
    width: 400px !important;
}

.react-confirm-alert-overlay > .react-confirm-alert > .react-confirm-alert-modal > .modal-header {
    padding: 18px 20px 15px 20px;
    border-bottom: 1px solid #E5E7E8;
}

.react-confirm-alert-overlay > .react-confirm-alert > .react-confirm-alert-modal > .modal-body {
    padding: 20px;
    font-style: normal;
    font-weight: 600;
}
.react-confirm-alert-overlay > .react-confirm-alert > .react-confirm-alert-modal > .modal-body p {
    font-family: 'Rota';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
    margin-bottom: 14px;
}

.react-confirm-alert-overlay > .react-confirm-alert > .react-confirm-alert-modal > .modal-header > .modal-title {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
    flex-grow: 2;
}

.react-confirm-alert-overlay > .react-confirm-alert > .react-confirm-alert-modal > .modal-header > .alert-close {
    padding: 0;
    margin: 0;
    background: none !important;
    border: none;
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: right;
    color: #FF6A39;
}

.react-confirm-alert-overlay > .react-confirm-alert > .react-confirm-alert-modal > .modal-header > .timer {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    flex: 0 0 50%;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: end;
}

.react-confirm-alert-overlay > .react-confirm-alert > .react-confirm-alert-modal > .modal-header > .timer.patient-timer {
    color: #FF6A39;
}

.react-confirm-alert-overlay > .react-confirm-alert > .react-confirm-alert-modal > .modal-header > .timer.doctor-timer {
    color: #00CFB4;
    flex: 0 0 25%;
}

.react-confirm-alert-overlay > .react-confirm-alert > .react-confirm-alert-modal > .modal-header > .timer p {
    margin-bottom: 0;
}

.react-confirm-alert-overlay > .react-confirm-alert > .react-confirm-alert-modal > .modal-body > .actions {
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-left: -10px;
    margin-right: -10px;
}

.react-confirm-alert-overlay > .react-confirm-alert > .react-confirm-alert-modal > .modal-body > .actions > .cancel-button {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #001315;
    background: #FFFFFF;
    border: 1px solid #E5E7E8;
    box-sizing: border-box;
    border-radius: 12px;
    padding: 16px 10px;
    display: inline-flex;
    justify-content: center;
    width: 100%;
    margin: 0 5px;
}

.react-confirm-alert-overlay > .react-confirm-alert > .react-confirm-alert-modal > .modal-body > .actions > .doctor-button {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF !important;
    text-decoration: none !important;
    background: #00CFB4;
    border: 0;
    box-sizing: border-box;
    border-radius: 12px;
    padding: 16px 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0 5px;
}

.react-confirm-alert-overlay > .react-confirm-alert > .react-confirm-alert-modal > .modal-body > .actions > .patient-button {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #FFFFFF !important;
    text-decoration: none !important;
    background: #FF6A39;
    border: 0;
    box-sizing: border-box;
    border-radius: 12px;
    padding: 16px 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0 5px;
}

.react-confirm-alert-overlay > .react-confirm-alert > .react-confirm-alert-modal > .modal-body > .actions > .patient-button > svg,
.react-confirm-alert-overlay > .react-confirm-alert > .react-confirm-alert-modal > .modal-body > .actions > .doctor-button > svg {
    margin-right: 10px;
}

.react-confirm-alert-overlay > .react-confirm-alert > .react-confirm-alert-modal > .modal-body > .alert-text {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
}

.react-confirm-alert-overlay > .react-confirm-alert > .react-confirm-alert-modal > .modal-body > .info-message.dark {
    background-color: #F2F3F3;
    border-radius: 12px;
}

.react-confirm-alert-overlay > .react-confirm-alert > .react-confirm-alert-modal > .modal-body > .info-message.dark.justify-between {
    justify-content: space-between;
}

.react-confirm-alert-overlay > .react-confirm-alert > .react-confirm-alert-modal > .modal-body > .info-message.dark.justify-between .subtitle {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #80898A;
}

.react-confirm-alert-overlay > .react-confirm-alert > .react-confirm-alert-modal > .modal-body > .info-message.dark.justify-between .price {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #001315;
}

.react-confirm-alert-overlay > .react-confirm-alert > .react-confirm-alert-modal > .modal-body > .message {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #E5E7E8;
}

.react-confirm-alert-overlay > .react-confirm-alert > .react-confirm-alert-modal > .modal-body > .subtitle {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #80898A;
    margin-top: 20px;
    margin-bottom: 10px;
}

.react-confirm-alert-overlay > .react-confirm-alert > .react-confirm-alert-modal > .modal-body > .minutes {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 60px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #E5E7E8;
}

.react-confirm-alert-overlay > .react-confirm-alert > .react-confirm-alert-modal > .modal-body > .minutes .form-select-label {
    max-width: 91px!important;
}

.react-confirm-alert-overlay > .react-confirm-alert > .react-confirm-alert-modal > .modal-body > .minutes .min {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
    margin-left: 10px;
}

.react-confirm-alert-overlay > .react-confirm-alert > .react-confirm-alert-modal > .modal-body > .medic-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.react-confirm-alert-overlay > .react-confirm-alert > .react-confirm-alert-modal > .modal-body > .medic-box > .avatar {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    width: 36px;
    height: 36px;
    border-radius: 100%;
    border: 1px solid rgba(0, 19, 21, 0.1);
    position: relative;
    margin-bottom: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.react-confirm-alert-overlay > .react-confirm-alert > .react-confirm-alert-modal > .modal-body > .medic-box > .fullName {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #001315;
    margin-bottom: 2px;
}

.react-confirm-alert-overlay > .react-confirm-alert > .react-confirm-alert-modal > .modal-body > .medic-box > .specialization {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #80898A;
}

.react-confirm-alert-overlay > .react-confirm-alert > .react-confirm-alert-modal > .modal-body > .medic-box-alt-wrapper {
    background: #FFFFFF;
    border-radius: 12px;
    border: 1px solid #E5E7E8;
    padding: 10px 15px;
}
.react-confirm-alert-overlay > .react-confirm-alert > .react-confirm-alert-modal > .modal-body > .medic-box-alt-wrapper .medic-box-alt {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #F2F3F3;
    padding-bottom: 12px;
    margin-bottom: 15px;
}
.react-confirm-alert-overlay > .react-confirm-alert > .react-confirm-alert-modal > .modal-body > .medic-box-alt-wrapper .medic-box-alt .info {
    display: inline-flex;
}
.react-confirm-alert-overlay > .react-confirm-alert > .react-confirm-alert-modal > .modal-body > .medic-box-alt-wrapper .medic-box-alt .profile-picture {
    display: flex;
    align-items: center;
    margin-right: 20px;
}
.react-confirm-alert-overlay > .react-confirm-alert > .react-confirm-alert-modal > .modal-body > .medic-box-alt-wrapper .medic-box-alt .profile-picture .avatar {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    width: 36px;
    height: 36px;
    border-radius: 100%;
    border: 1px solid rgba(0, 19, 21, 0.1);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.react-confirm-alert-overlay > .react-confirm-alert > .react-confirm-alert-modal > .modal-body > .medic-box-alt-wrapper .medic-box-alt .details {
    display: flex;
    flex-direction: column;
}
.react-confirm-alert-overlay > .react-confirm-alert > .react-confirm-alert-modal > .modal-body > .medic-box-alt-wrapper .medic-box-alt .details .name {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
}
.react-confirm-alert-overlay > .react-confirm-alert > .react-confirm-alert-modal > .modal-body > .medic-box-alt-wrapper .medic-box-alt .details .specialization {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #80898A;
    margin-bottom: 2px;
}
.react-confirm-alert-overlay > .react-confirm-alert > .react-confirm-alert-modal > .modal-body > .medic-box-alt-wrapper .medic-box-alt .action-buttons {
    display: flex;
    align-items: center;
    margin-left: auto;
}
.react-confirm-alert-overlay > .react-confirm-alert > .react-confirm-alert-modal > .modal-body > .medic-box-alt-wrapper .medic-box-alt .action-buttons .medic-action-btn {
    margin-left: 10px;
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}
.react-confirm-alert-overlay > .react-confirm-alert > .react-confirm-alert-modal > .modal-body > .medic-box-alt-wrapper .medic-box-alt .action-buttons > svg {
    margin-right: 2px;
}
.react-confirm-alert-overlay > .react-confirm-alert > .react-confirm-alert-modal > .modal-body > .medic-box-alt-wrapper .footer-box .subtitle {
    font-family: Rota, serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #001315;
    text-transform: uppercase;
    letter-spacing: 0.1em;
}
.react-confirm-alert-overlay > .react-confirm-alert > .react-confirm-alert-modal > .modal-body > .medic-box-alt-wrapper .footer-box .availability {
    font-family: Rota, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #80898A;
}
.react-confirm-alert-overlay > .react-confirm-alert > .react-confirm-alert-modal > .modal-body > .medic-box-alt-wrapper .footer-box .price {
    font-family: Rota, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #FF6A39;
}
