section.appointment-service-steps {
    max-width: 400px;
    margin: auto;
}

section.appointment-service-steps.full-width {
    max-width: none;
}

section.appointment-service-steps.full-width .calendar-and-interval-hours {
    max-width: none;
    margin-bottom: 30px;
}

section.appointment-service-steps.full-width .footer-btn,
section.appointment-service-steps.full-width .medic-box,
section.appointment-service-steps.full-width .title-section {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
}

section.appointment-service-steps .empty-container {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #80898A;
    margin-top: 60px;
    margin-bottom: 60px;
}

section.appointment-service-steps .title-section {
    margin-top: 40px;
    margin-bottom: 30px;
}

section.appointment-service-steps .title-section h1 {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 110%;
    text-align: center;
    color: #001315;
    margin: 0;
}

section.appointment-service-steps .text-section {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #001315;
    margin-top: -10px;
    margin-bottom: 30px;
}

section.appointment-service-steps .steps {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    text-transform: uppercase;
    color: #80898A;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
}

section.appointment-service-steps .back-to {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #001315;
    display: flex;
    align-items: center;
}

section.appointment-service-steps .back-to svg {
    margin-right: 10px;
    cursor: pointer;
}

section.appointment-service-steps .steps .icon-wrapper {
    cursor: pointer;
}

section.appointment-service-steps .steps .cancel-button {
    margin: 0;
    width: auto;
}

section.appointment-service-steps .medic-box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 12px 20px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

section.appointment-service-steps .medic-box .avatar {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    width: 36px;
    height: 36px;
    border-radius: 100%;
    border: 1px solid rgba(0, 19, 21, 0.1);
    position: relative;
    margin-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

section.appointment-service-steps .medic-box .name {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
}

section.appointment-service-steps .medic-box .rating {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #80898A;
    display: flex;
}

section.appointment-service-steps .medic-box .rating svg {
    margin-right: 5px;
}

section.appointment-service-steps .specialization-box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 12px 20px;
    margin-bottom: 10px;
}

section.appointment-service-steps .orange-button {
    margin-top: 10px;
}

section.appointment-service-steps .sticky-btn-bottom {
    position: fixed;
    bottom: 0px;
    width: 100%;
    left: 0;
    right: 0;
    background-color: #f5f5f5;
    border-top: 1px solid #E5E7E8;
    z-index: 5000;
    display: flex;
    justify-content: center;
}

section.appointment-service-steps .sticky-btn-bottom .orange-button {
    margin-top: 20px;
    margin-bottom: 50px;
    max-width: 400px;
}

section.appointment-service-steps .cancel-button {
    margin-top: 20px;
}

section.appointment-service-steps .white-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 !important;
    min-height: 46px;
}

section.appointment-service-steps .white-button svg {
    margin-right: 10px;
}

section.appointment-service-steps.waiting-screen .info-message {
    background: #FFFFFF;
    margin: 0;
}

section.appointment-service-steps .info-message {
    background: #E5E7E8;
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #80898A;
    margin-bottom: 10px;
    margin-top: 20px;
}

section.appointment-service-steps .calendar .subtitle {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #80898A;
    margin-top: 20px;
    margin-bottom: 10px;
}

section.appointment-service-steps .calendar .react-calendar {
    width: 100%;
    background: #FFFFFF;
    border-radius: 12px;
    border: 0;
}

section.appointment-service-steps .calendar .react-calendar__navigation {
    height: auto;
    margin-bottom: 0;
    padding-top: 20px;
}

section.appointment-service-steps .calendar .react-calendar__month-view {
    padding-top: 23px;
    padding-bottom: 14px;
}

section.appointment-service-steps .calendar .react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth,
section.appointment-service-steps .calendar .react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend.react-calendar__month-view__days__day--neighboringMonth {
    pointer-events: none !important;
    opacity: 0;
}

section.appointment-service-steps .calendar .react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend:disabled,
section.appointment-service-steps .calendar .react-calendar__tile.react-calendar__month-view__days__day:disabled {
    color: #BFC4C5;
}

section.appointment-service-steps .calendar .react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend {
    color: #001315;
}

section.appointment-service-steps .calendar .react-calendar__tile.react-calendar__month-view__days__day {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #001315;
    padding: 0;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 5px;
    background: none;
    flex-basis: auto !important;
}

section.appointment-service-steps .calendar .react-calendar__tile.react-calendar__month-view__days__day:hover {
    background: none;
}

section.appointment-service-steps .calendar .react-calendar__tile.react-calendar__month-view__days__day.react-calendar__tile--now {
    background: none;
    border: 2px solid #FF6A39;
    border-radius: 100%;
}

section.appointment-service-steps .calendar .react-calendar__tile.react-calendar__month-view__days__day.react-calendar__tile--active {
    color: #FFFFFF;
    background: #FF6A39;
    border-radius: 100%;
}

section.appointment-service-steps .calendar .react-calendar__navigation__arrow.react-calendar__navigation__prev2-button,
section.appointment-service-steps .calendar .react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
    display: none !important;
}

section.appointment-service-steps .calendar .react-calendar__navigation__arrow {
    background: none !important;
}

section.appointment-service-steps .calendar .react-calendar__navigation__label {
    background: none !important;
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #001315;
    text-transform: capitalize;
}

section.appointment-service-steps .calendar .react-calendar__viewContainer {
    padding: 0 20px;
}

section.appointment-service-steps .calendar .react-calendar__month-view__weekdays__weekday abbr {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #001315;
    text-transform: capitalize;
    text-decoration: none !important;
    cursor: auto;
}

section.appointment-service-steps .calendar .react-calendar__month-view__weekdays {
    border-bottom: 1px solid #F2F3F3;
    margin-bottom: 8px;
    padding-bottom: 12px;
}

section.appointment-service-steps .calendar .react-calendar__month-view__weekdays,
section.appointment-service-steps .calendar .react-calendar__month-view__days {
    justify-content: center;
}

section.appointment-service-steps .calendar .react-calendar__month-view__weekdays__weekday {
    padding: 0;
    flex-basis: auto !important;
    width: 40px;
    margin: 0 5px;
}

section.appointment-service-steps .calendar-and-interval-hours > .row > .col-12:last-child {
    display: flex;
    flex-direction: column;
}

section.appointment-service-steps .calendar-and-interval-hours .subtitle {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #80898A;
    margin-top: 20px;
    margin-bottom: 10px;
}

section.appointment-service-steps .hours-available-select {
    height: 100%;
    background: #FFFFFF;
    border-radius: 12px;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: start;
}

section.appointment-service-steps .select-hour label {
    background: none !important;
    outline: none !important;
    box-shadow: none !important;
    padding: 0 5px;
    border: 0;
    margin-bottom: 10px;
}

section.appointment-service-steps .select-hour .btn-check:checked + label,
section.appointment-service-steps .select-hour .btn-check:checked + label:focus {
    background: none !important;
    outline: none !important;
    box-shadow: none !important;
}

section.appointment-service-steps .select-hour label input {
    display: none;
}

section.appointment-service-steps .select-hour label .time {
    background: #FFFFFF;
    border-radius: 12px;
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #001315;
    padding: 12px 15px;
    border: 1px solid #E5E7E8;
}

section.appointment-service-steps .select-hour .btn-check:checked + label .time {
    background: #FF6A39;
    color: #FFFFFF;
    border: 1px solid #FF6A39;
}

section.appointment-service-steps .patient-box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 13px 20px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

section.appointment-service-steps .patient-box .details {
    display: flex;
    align-items: center;
    margin-right: auto;
}

section.appointment-service-steps .patient-box .avatar {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    width: 36px;
    height: 36px;
    border-radius: 100%;
    border: 1px solid rgba(0, 19, 21, 0.1);
    position: relative;
    margin-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

section.appointment-service-steps .patient-box .details .name {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
    margin-bottom: 2px;
}

section.appointment-service-steps .patient-box .details .age {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #80898A;
}

section.appointment-service-steps .patient-box button {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: right;
    color: #FF6A39;
    padding: 0;
    border: 0;
    background: none;
}

section.appointment-service-steps .upload .subtitle {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #80898A;
    margin-top: 20px;
    margin-bottom: 10px;
}

section.appointment-service-steps .upload .files-box {
    background: #FFFFFF;
    border-radius: 12px;
}

section.appointment-service-steps .upload .files-box label {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 20px;
    margin: 0;
    cursor: pointer;
}

section.appointment-service-steps .upload .files-box label svg {
    margin-right: 16px;
}

section.appointment-service-steps .upload .files-box input {
    display: none;
}

section.appointment-service-steps .new-document .subtitle {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #80898A;
    margin-top: 20px;
    margin-bottom: 10px;
}

section.appointment-service-steps .new-document .uploaded-box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 20px;
}

section.appointment-service-steps .new-document .uploaded-file {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
}

section.appointment-service-steps .new-document .uploaded-file .file-name {
    display: inline-flex;
    align-items: center;
    max-width: 295px;
    margin-right: auto;
}

section.appointment-service-steps .new-document .uploaded-file .file-name span {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

section.appointment-service-steps .new-document .uploaded-file .file-name svg {
    margin-right: 16px;
    min-width: 24px;
}

section.appointment-service-steps .new-document .uploaded-file button {
    border: 0;
    padding: 0;
    margin: 0;
    background: none;
}

section.appointment-service-steps .new-document button.orange-button {
    padding: 10px 20px;
    min-height: 46px;
}

section.appointment-service-steps .choose-documents {
    margin-bottom: 40px;
}

section.appointment-service-steps .choose-documents .subtitle {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #80898A;
    margin-top: 20px;
    margin-bottom: 10px;
}

section.appointment-service-steps .choose-documents .documents-box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 16px 20px 0 20px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
}

section.appointment-service-steps .choose-documents .documents-box .header {
    border-bottom: 1px solid #F2F3F3;
    padding-bottom: 14px;
}

section.appointment-service-steps .choose-documents .documents-box .header .title {
    display: inline-flex;
    margin-right: auto;
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
}

section.appointment-service-steps .choose-documents .documents-box .header .custom-checkbox.select-all.active-label-orange .custom-control-label {
    color: #001315;
}

section.appointment-service-steps .choose-documents .documents-box .header .custom-checkbox.select-all.active-label-orange .custom-control-input:checked ~ .custom-control-label {
    color: #FF6A39;
}

section.appointment-service-steps .appointment-details-box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 20px;
}

section.appointment-service-steps .appointment-details-box .list-group {
    border-radius: 0;
}

section.appointment-service-steps .appointment-details-box .list-group-item {
    margin-bottom: 12px;
    border: 0;
    padding: 0 0 8px 0;
    border-bottom: 1px solid #F2F3F3;
    display: flex;
    align-items: center;
}

section.appointment-service-steps .appointment-details-box .list-group-item .title {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #80898A;
    margin-right: auto;
}

section.appointment-service-steps .appointment-details-box .list-group-item .details {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: right;
    color: #001315;
}

section.appointment-service-steps .appointment-details-box .list-group-item:last-child {
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 0;
}

section.appointment-service-steps .voucher .subtitle {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #80898A;
    margin-top: 20px;
    margin-bottom: 10px;
}

section.appointment-service-steps .voucher .voucher-box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 13px 20px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

section.appointment-service-steps .voucher .voucher-box .details {
    display: flex;
    align-items: center;
    margin-right: auto;
}

section.appointment-service-steps .voucher .voucher-box .details svg {
    margin-right: 20px;
}

section.appointment-service-steps .voucher .voucher-box .details .title {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
    margin-bottom: 2px;
    padding-right: 10px;
}

section.appointment-service-steps .voucher .voucher-box .details .code {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #80898A;
}

section.appointment-service-steps .voucher .voucher-box button {
    padding: 0;
    border: 0;
    background: none;
}

section.appointment-service-steps .voucher .voucher-info {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 11px 20px;
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #00CFB4;
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

section.appointment-service-steps .voucher .voucher-info svg {
    margin-right: 20px;
}

section.appointment-service-steps .card-details-section-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

section.appointment-service-steps .card-details .subtitle {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #80898A;
    margin-top: 20px;
    margin-bottom: 10px;
}

section.appointment-service-steps .card-details .custom-checkbox {
    margin-top: 20px;
    margin-bottom: 10px;
}

section.appointment-service-steps .card-details .card-box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 13px 20px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

section.appointment-service-steps .card-details .card-box .details {
    display: flex;
    align-items: center;
    margin-right: auto;
}

section.appointment-service-steps .card-details .card-box .details img {
    margin-right: 20px;
}

section.appointment-service-steps .card-details .card-box .details .number {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
    margin-bottom: 2px;
}

section.appointment-service-steps .card-details .card-box .details .date {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #80898A;
}

section.appointment-service-steps .card-details .card-box button {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: right;
    color: #FF6A39;
    padding: 0;
    border: 0;
    background: none;
}

section.appointment-service-steps .payment-error-box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

section.appointment-service-steps .payment-error-box svg {
    margin-top: 10px;
    margin-bottom: 10px;
}

section.appointment-service-steps .payment-error-box .title {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: #001315;
    margin-bottom: 6px;
}

section.appointment-service-steps .payment-error-box .description {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #001315;
    margin-bottom: 10px;
}

section.appointment-service-steps .confirmed-box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 40px 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

section.appointment-service-steps .confirmed-box .title {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: #001315;
    margin-top: 25px;
}

section.appointment-service-steps .countdown-box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 10px 20px;
    margin-bottom: 10px;
}

section.appointment-service-steps .countdown {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

section.appointment-service-steps .countdown .will-start {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
    flex: 0 0 50%;
}

section.appointment-service-steps .countdown .timer {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: #FF6A39;
    flex: 0 0 50%;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: end;
}

section.appointment-service-steps .countdown .timer p {
    margin-bottom: 0;
}

section.appointment-service-steps .medic-call-box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 20px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

section.appointment-service-steps .medic-call-box .medic-avatar {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    width: 159px;
    height: 159px;
    border-radius: 100%;
    border: 1px solid rgba(0, 19, 21, 0.1);
    margin-bottom: 15px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.3em;
}

section.appointment-service-steps .medic-call-box .rating {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #80898A;
    display: flex;
    margin-bottom: 2px;
}

section.appointment-service-steps .medic-call-box .rating svg {
    margin-right: 5px;
}

section.appointment-service-steps .medic-call-box .name {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: #001315;
}

section.appointment-service-steps .medic-call-box .specialization {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #001315;
}

section.appointment-service-steps .medic-call-box .clinic {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #80898A;
}

section.appointment-service-steps .countdown-box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 20px 20px 15px 20px;
}

.modal-add-card .modal-body .custom-checkbox {
    margin-top: 20px;
    margin-bottom: 20px;
}

.modal-add-card .modal-body .info-message {
    padding: 20px;
    margin-bottom: 0;
    align-items: flex-start;
}

.modal-add-card .modal-body .info-message > div {
    display: flex;
    flex-direction: column;
}

.modal-add-card .modal-body .info-message img {
    max-width: 149px;
    margin-top: 10px;
}

.modal-cards .modal-body .card-box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 12px 20px;
    margin-bottom: 10px;
}

.modal-cards .modal-body .card-box:last-child {
    margin-bottom: 0;
}

.modal-apply-voucher .subtitle {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #80898A;
    margin-top: 0;
    margin-bottom: 10px;
}

.modal-apply-voucher .voucher-box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 20px;
    position: relative;
    margin-bottom: 10px;
}

.modal-apply-voucher .voucher-box.ineligible {
    background: rgba(255, 255, 255, 0.6);
}

.modal-apply-voucher .voucher-box:last-child {
    margin-bottom: 0;
}

.modal-apply-voucher .voucher-box .custom-icon {
    position: absolute;
    top: 18px;
    right: 20px;
}

.modal-apply-voucher .voucher-box.ineligible svg path {
    fill: #80898A !important;
}

.modal-apply-voucher .voucher-box .code {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #80898A;
    margin-bottom: 4px;
}

.modal-apply-voucher .voucher-box .title {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
    margin-bottom: 2px;
    max-width: 274px;
}

.modal-apply-voucher .voucher-box .expires-at {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #80898A;
    margin-bottom: 10px;
}

.modal-apply-voucher .voucher-box .description {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #80898A;
    margin-bottom: 15px;
}

.modal-apply-voucher .voucher-box.ineligible .code,
.modal-apply-voucher .voucher-box.ineligible .title,
.modal-apply-voucher .voucher-box.ineligible .expires-at,
.modal-apply-voucher .voucher-box.ineligible .description {
    color: #80898A;
}

.modal-apply-voucher .voucher-box button {
    padding: 10px 20px;
    min-height: 46px;
}

.modal-apply-voucher .voucher-box.ineligible button {
    background: #F2F3F3;
    border-color: #F2F3F3;
    color: #80898A;
}

.modal-voucher .modal-body .info-message {
    background: #E5E7E8;
    margin-bottom: 10px;
}

.modal-voucher .modal-body input {
    margin-bottom: 0;
}

.profile-picture-file-extention-tooltip {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    padding-left: 10px;
    padding-top: 5px;
    font-size: 14px;
    line-height: 17px;
    color: #80898A;

}

.services .service-item {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 16px 20px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.services .service-item .service-name {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
}

.services .service-item .service-description {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #80898A;
    margin-bottom: 14px;
}

.services .service-item .service-details {
    font-family: 'Rota';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;
    color: #FF6A39;
}

.cancel-button-control, .info-text {
    font-family: 'Rota';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #001315;
    margin-bottom: 10px;
}
