@media (max-width: 1024px) {
    .gate-page .banner {
        padding: 95px 0 35px 0;
    }

    /*section.account .menu-box > .nav.nav-tabs {*/
    /*    display: none !important;*/
    /*}*/
    /*section.account .menu-box .menu-account-mobile {*/
    /*    display: flex!important;*/
    /*}*/
    section.homescreen h1 {
        font-size: 30px !important;
        margin-bottom: 5px !important;
    }

    section.homescreen .online-doctors span {
        font-size: 20px !important;
        line-height: 26px !important;
    }

    section.homescreen .choose-medic-from-list a {
        padding: 10px 20px !important;
        min-height: 56px !important;
        min-width: 100% !important;
    }

    section.rating .title-section,
    section.appointment-steps .title-section,
    section.consultation-details .title-section,
    section.consultations .title-section,
    section.medical-folder .title-section,
    section.medic-list .title-section {
        margin-top: 20px !important;
        margin-bottom: 10px !important;
    }

    section.rating .title-section h1,
    section.appointment-steps .title-section h1,
    section.consultation-details .title-section h1,
    section.consultations .title-section h1,
    section.medical-folder .title-section h1,
    section.medic-list .title-section h1 {
        font-size: 30px !important;
        text-align: center !important;
    }

    section.medic-profile .medic-box {
        /*align-items: flex-start !important;*/
        flex-direction: column !important;
    }

    section.medic-profile.mobile .medic-box {
        align-items: center;
    }

    section.medic-profile .medic-box .profile-picture {
        margin-bottom: 10px !important;
    }

    section.medic-profile .medic-box .action-buttons {
        margin-left: 0 !important;
    }

    section.medic-profile .medic-box .action-buttons .medic-action-btn {
        margin-left: 0 !important;
        margin-right: 10px !important;
    }

    .button-sticky-bottom {
        padding-top: 10px !important;
        padding-bottom: 10px !important;
    }

    .button-sticky-bottom button {
        margin: 0 !important;
    }


    section.account-profile .title-section .back-to-account {
        position: relative !important;
    }

    /*section.account .menu-box,*/
    section.account .content-box {
        /*width: 100% !important;*/
    }

    .modal-add-exception .calendar .react-calendar__viewContainer {
        padding: 0 15px !important;
    }

    .modal-add-exception .calendar .react-calendar__month-view__weekdays__weekday {
        width: 30px !important;
        margin: 0 4px !important;
    }

    .modal-add-exception .calendar .react-calendar__tile.react-calendar__month-view__days__day {
        width: 30px !important;
        height: 30px !important;
        margin: 0 4px !important;
    }

    section.account .tab-content {
        padding: 20px !important;
    }

    section.account .mobile-account {
        position: relative;
    }

    section.account .mobile-account .back-to {
        margin-top: -10px;
        font-family: 'Rota';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #001315;
        border: 0;
        background: none;
        cursor: pointer;
        display: inline-flex;
        align-items: center;
    }

    section.account .mobile-account .back-to svg {
        margin-right: 10px;
    }

    section.account .mobile-account .menu-box {
        display: block;
    }

    /*section.account .content-box {*/
    /*    display: block;*/
    /*}*/
    section.account .mobile-account .content-box {
        display: flex;
        width: 100%;
    }

    section.account .content-box .tab-content {
        min-height: auto !important;
    }

    section.account .mobile-account .content-box .tab-content {
        width: 100%;
    }

    section.video-call .small-screens {
        margin-bottom: 15px;
    }

    section.video-call .header-top {
        flex-direction: column;
    }

    section.video-call .header-top .patient {
        margin-bottom: 10px;
    }

    section.video-call .video-container .medic-video {
        min-height: 270px !important;
    }

    section.consultations.patient .box.past .medic-info {
        display: block !important;
    }

    section.consultations.patient .box.past .medic-info .left-column {
        width: 100% !important;
    }

    section.consultations.patient .box.past .medic-info .right-column {
        width: 100% !important;
        padding-bottom: 20px !important;
    }

    section.consultations.patient .box.upcoming .details {
        display: block !important;
    }

    section.consultations.patient .box.upcoming .medic-info .left-column {
        width: 100% !important;
    }

    section.consultations.patient .box.upcoming .medic-info .right-column {
        width: 100% !important;
        padding-bottom: 10px;
    }

    section.consultation-details .medic-box {
        flex-direction: column !important;
        width: 100% !important;
    }

    section.consultation-details .medic-box .info {
        margin-bottom: 10px;
    }

    section.consultation-details .medic-box .action-buttons {
        margin-left: 0 !important;
        width: 100% !important;
    }

    section.consultation-details .medic-box .action-buttons .medic-action-btn,
    section.consultation-details .medic-box .action-buttons .resume-consultation-btn {
        min-width: auto !important;
        width: 100% !important;
    }
}
