section.auth,
section.login,
section.forgot-password,
section.activation-account,
section.register-steps,
section.register {
    max-width: 400px;
    margin: auto;
}

section.auth .title-section,
section.login .title-section,
section.activation-account .title-section,
section.forgot-password .title-section,
section.register-steps .title-section,
section.register .title-section {
    margin-top: 40px;
    margin-bottom: 40px;
    position: relative;
    z-index: 10;
}

section.auth .title-section .small-title-text,
section.login .title-section .small-title-text {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #001315;
    position: relative;
    z-index: 10;
}

section.auth .title-section h1,
section.login .title-section h1,
section.activation-account .title-section h1,
section.forgot-password .title-section h1,
section.register-steps .title-section h1,
section.register .title-section h1 {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 110%;
    text-align: center;
    color: #001315;
    margin: 0;
    position: relative;
    z-index: 10;
}

section.auth .logo {
    display: flex;
    justify-content: center;
    margin-top: 60px;
    margin-bottom: 80px;
    position: relative;
    z-index: 10;
}

section.register-steps .steps {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    text-transform: uppercase;
    color: #80898A;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
}

section.register-steps .subtitle {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #80898A;
    margin-top: 20px;
    margin-bottom: 10px;
    width: 100%;
}

section.register-steps .info-message {
    margin-bottom: 0;
    margin-top: 20px;
}

section.register-steps button.green-button {
    margin-top: 10px;
}

section.auth .buttons {
    display: flex;
    margin: 0 -5px;
    position: relative;
    z-index: 10;
}

section.auth .buttons button {
    margin: 0 5px;
}

section.auth .or,
section.login .or,
section.register .or {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #80898A;
    margin: 18px 0;
    position: relative;
    z-index: 10;
}

section.login form,
section.activation-account form,
section.forgot-password form,
section.register-steps form,
section.register form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

section.register form .custom-checkbox:last-of-type {
    margin-bottom: 20px;
}

section.register-steps .custom-checkbox,
section.register form .custom-checkbox {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
}

section.register form hr {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
}

section.register-steps .phone-number-with-prefix,
section.register form .phone-number-with-prefix {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
}

section.register-steps .phone-number-with-prefix select,
section.register form .phone-number-with-prefix select {
    width: 105px;
}

section.register-steps .phone-number-with-prefix .form-input,
section.register form .phone-number-with-prefix .form-input {
    width: calc(100% - 105px - 10px);
}

.social-login {
    display: flex;
    width: calc(100% + 10px);
    justify-content: space-between;
    margin-left: -5px;
    margin-right: -5px;
    position: relative;
    z-index: 10;
}

.social-login .login-button {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 12px 20px;
    width: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
    margin-right: 5px;
}

.forgot-pass-btn {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #001315;
    display: inline-flex;
    margin: 30px 0;
}

.facebook-login-icon {
    background-image: url("../../shared/assets/icons/auth/facebook-login-icon.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center center;
    display: inline-block;
    width: 32px;
    height: 32px;
}

.google-login-icon {
    background-image: url("../../shared/assets/icons/auth/google-login-icon.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center center;
    display: inline-block;
    width: 32px;
    height: 32px;
}

.password-eye {
    position: relative;
    width: 100%;
}

.password-eye .eye {
    position: absolute;
    top: 16px;
    right: 20px;
    cursor: pointer;
}

.forgot-pass-btn svg {
    margin-right: 3px;
}

section.login button svg,
section.activation-account button svg,
section.forgot-password button svg,
section.register button svg {
    position: absolute;
    right: 20px;
}

.phone-number-input {
    display: flex;
    width: 100%;
}

.phone-number-input .form-select-label {
    max-width: 93px;
    margin-right: 10px;
}

section.activation-account .resend-code-text {
    font-family: 'Rota';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #001315;
    margin-bottom: 20px;
    margin-top: 10px;
}

section.activation-account button.white-button {
    margin-bottom: 20px;
}

section.register-steps .profile-picture {
    width: 100%;
}

section.register-steps .change-picture {
    display: flex;
    align-items: center;
    width: 100%;
}

section.register-steps .change-picture .files-box {
    background: #FFFFFF;
    border-radius: 12px;
    width: calc(100% - 46px);
}

section.register-steps .change-picture .files-box label {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 20px;
    margin: 0;
    cursor: pointer;
}

section.register-steps .change-picture .files-box label.add {
    color: #001315;
}

section.register-steps .change-picture .files-box label.modify {
    color: #00CFB4;
}

section.register-steps .change-picture .files-box input {
    display: none;
}

section.register-steps .change-picture .avatar {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    width: 46px;
    height: 46px;
    border-radius: 100%;
    border: 1px solid rgba(0, 19, 21, 0.1);
    position: relative;
    margin-left: 10px;
}

section.register-steps .change-picture .no-avatar {
    background: #E5E7E8;
    border-radius: 100%;
    width: 46px;
    height: 46px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
}

section.register-steps button.white-button {
    color: #00CFB4;
    padding: 9px 20px;
    min-height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
}

section.register-steps button.add-specialization-btn {
    margin-bottom: 20px;
}

section.register-steps button.white-button svg {
    margin-right: 10px;
}

section.register-steps .specialization-box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 17px 20px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

section.register-steps .specialization-box .type {
    display: inline-block;
    flex-direction: column;
    margin-right: auto;
}

section.register-steps .specialization-box .type .primary {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
}

section.register-steps .specialization-box .type .secondary {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #80898A;
}

section.register-steps .specialization-box .status .in-progress {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #80898A;
    padding: 2px 9px;
    background: #F2F3F3;
    border-radius: 12px;
}

section.register-steps .specialization-box .status .approved {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #00CFB4;
    padding: 2px 9px;
    background: rgba(0, 207, 180, 0.1);
    border-radius: 12px;
}

section.register-steps .uploaded-box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 15px 20px;
    margin-bottom: 10px;
    margin-top: 10px;
}

section.register-steps .uploaded-box:last-child {
    margin-bottom: 0;
}

section.register-steps .uploaded-file {
    display: flex;
    align-items: center;
    position: relative;
}

section.register-steps .uploaded-file .circle.new-file {
    background: #FF6A39;
    border-radius: 10px;
    width: 10px;
    height: 10px;
    position: absolute;
    top: auto;
    bottom: auto;
    left: -13px;
}

section.register-steps .uploaded-file .file-name {
    display: inline-flex;
    align-items: center;
    max-width: 295px;
    margin-right: auto;
}

section.register-steps .uploaded-file .file-name span {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

section.register-steps .uploaded-file .file-name svg {
    margin-right: 16px;
    min-width: 24px;
}

section.register-steps .uploaded-file button {
    border: 0;
    padding: 0;
    margin: 0;
    background: none;
}

section.register-steps .upload-files {
    width: 100%;
    margin-top: 10px;
}

section.register-steps .upload-files .files-box label {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 11px 20px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

section.register-steps .upload-files .files-box:last-child label {
    margin: 0;
}

section.register-steps .upload-files .files-box label .info-file {
    display: inline-flex;
    flex-direction: column;
    margin-right: auto;
}

section.register-steps .upload-files .files-box label .info-file .title {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #00CFB4;
    margin-bottom: 2px;
}

section.register-steps .upload-files .files-box label .info-file .details {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #80898A;
}

section.register-steps .upload-files .files-box input {
    display: none;
}

section.register-steps button.complete-registration {
    margin-top: 20px;
}

.modal-add-specialization .searchbar {
    background: #E5E7E8;
    border-radius: 12px;
    padding: 17px 20px;
    width: 100%;
    display: flex;
    align-items: center;
}

.modal-add-specialization .searchbar svg {
    margin-right: 10px;
    min-width: 20px;
}

.modal-add-specialization .searchbar input {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #80898A;
    width: 100%;
    background: none;
    border: 0;
    padding: 0;
    outline: none;
    box-shadow: none;
}

.modal-add-specialization .scroll-list {
    margin-top: -20px;
    margin-bottom: -20px;
    max-height: 440px;
    overflow-y: auto;
    margin-right: -9px;
    padding-right: 9px;
}

.modal-add-specialization .scroll-list form {
    padding: 20px 0;
}

.modal-add-specialization .scroll-list .custom-control {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 16px 20px;
    margin-bottom: 10px;
}

.modal-add-specialization .scroll-list .custom-control:last-child {
    margin-bottom: 0;
}

.modal-gdpr .modal-body .custom-checkbox {
    margin-bottom: 10px;
}

.modal-gdpr .modal-body .custom-checkbox:last-child {
    margin-bottom: 0;
}

footer.auth-footer {
    background: #76B897;
    position: relative;
}

footer.auth-footer .navigation ul,
footer.auth-footer .copyright,
footer.auth-footer .version-app,
footer.auth-footer .navigation ul li a {
    color: #FFFFFF;
}

footer.auth-footer .auth-background-image {
    position: absolute;
    bottom: 204px;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 5;
}

@media (max-height: 1000px) {
    section.auth .buttons {
        margin-bottom: 360px;
    }

    footer.auth-footer .auth-background-image {
        max-width: 850px;
    }
}

@media (max-width: 300px) {
    section.login .doctor-create-account {
        padding-left: 10px;
        padding-right: 10px;
        text-align: left;
        vertical-align: middle;
    }

    section.login button svg {
        right: 10px;
    }
}
