section.video-call .header-top {
    display: flex;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 20px;
}

section.video-call .header-top .patient {
    display: flex;
    align-items: center;
    margin-right: auto;
}

section.video-call .header-top .patient .avatar-section {
    margin-right: 20px;
}

section.video-call .header-top .patient .avatar-section .user-image {
    margin: 0 !important;
}

section.video-call .header-top .patient .avatar-section .avatar-picture {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    width: 36px;
    height: 36px;
    border-radius: 100%;
    border: 1px solid rgba(0, 19, 21, 0.1);
    position: relative;
    margin-right: 20px;
    position: relative;
}

section.video-call .header-top .patient .avatar-section .user-text {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Rota';
    font-style: normal;
    font-weight: 700;
    font-size: 16px !important;
    line-height: 20px !important;
    text-align: center;
    color: #80898A !important;
    width: 36px;
    height: 36px;
    border-radius: 100%;
    border: 1px solid rgba(0, 19, 21, 0.1);
}

section.video-call .header-top .patient .fullName {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
}

section.video-call .header-top .patient .time-left {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #80898A;
    text-align: left;
    display: flex;
    align-items: center;
}

section.video-call .header-top .patient .time-left p {
    margin-bottom: 0;
    margin-left: 5px;
}

section.video-call .header-top .tab-menu .nav.nav-tabs {
    border: 0;
    display: flex;
    margin-left: -5px;
    margin-right: -5px;
}

section.video-call .header-top .tab-menu .nav.nav-tabs .nav-item {
    padding: 0 5px;
}

section.video-call .header-top .tab-menu .nav.nav-tabs .nav-item .nav-link {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #001315;
    background: #E5E7E8;
    border-radius: 10px;
    padding: 8px 20px;
    border: 0;
    cursor: pointer;
}

section.video-call .header-top .tab-menu .nav.nav-tabs .nav-item .nav-link.active {
    background: #FFFFFF;
}

section.video-call .video-tab {
    position: relative;
}
section.video-call .video-alerts .info-message.minutes-left.green {
    background: #00CFB4;
}

section.video-call .video-alerts .info-message {
    margin: auto;
    width: 335px;
    background: #E5E7E8;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15), 0px 2px 2px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
    margin-bottom: 10px;
}

section.video-call .video-alerts .info-message:last-child {
    margin-bottom: 0;
}

section.video-call .video-alerts .info-message.minutes-left + .info-message {
    bottom: 80px !important;;
}

section.video-call .video-alerts .info-message .text {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #80898A;
}

section.video-call .video-alerts .info-message.minutes-left {
    background: #FF6A39;
}

section.video-call .video-alerts .info-message.minutes-left svg path {
    fill: #FFFFFF !important;
}

section.video-call .video-alerts .info-message.minutes-left .text {
    color: #FFF;
}

section.video-call .video-tab-container .back-to-video-screen {
    display: none;
}

section.video-call .video-tab-container .video-screens {
    width: 100%;
}

section.video-call .video-tab-container .video-screens .video-alerts {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
}

section.video-call .profile-tab-container,
section.video-call .records-tab-container,
section.video-call .conclusions-tab-container {
    display: flex;
}

section.video-call .profile-tab-container .video-screens,
section.video-call .records-tab-container .video-screens,
section.video-call .conclusions-tab-container .video-screens {
    width: 50%;
    padding-right: 10px;
}

section.video-call .profile-tab-container .tab-content,
section.video-call .records-tab-container .tab-content,
section.video-call .conclusions-tab-container .tab-content {
    width: 50%;
    padding-left: 10px;
}

section.video-call .profile-tab-container .video-container,
section.video-call .records-tab-container .video-container,
section.video-call .conclusions-tab-container .video-container {
    display: flex;
    flex-wrap: wrap;
    margin-left: -5px;
    margin-right: -5px;
}

section.video-call .profile-tab-container .video-container .medic-video,
section.video-call .records-tab-container .video-container .medic-video,
section.video-call .conclusions-tab-container .video-container .medic-video {
    filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.15)) drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.15));
    background: #333;
    box-sizing: border-box;
    border-radius: 12px;
    height: 139px;
    min-height: auto;
    width: 50%;
    margin: 0 5px;
    position: relative;
    flex: 1;
}

section.video-call .profile-tab-container .video-container .video-alerts,
section.video-call .records-tab-container .video-container .video-alerts,
section.video-call .conclusions-tab-container .video-container .video-alerts {
    width: 100%;
    margin-top: 15px;
}

section.video-call .profile-tab-container .video-container .medic-video video,
section.video-call .profile-tab-container .video-container .patient-video video,
section.video-call .records-tab-container .video-container .medic-video video,
section.video-call .records-tab-container .video-container .patient-video video,
section.video-call .conclusions-tab-container .video-container .medic-video video,
section.video-call .conclusions-tab-container .video-container .patient-video video {
    height: 139px;
    width: 100%;
}

section.video-call .profile-tab-container .video-container .patient-video,
section.video-call .records-tab-container .video-container .patient-video,
section.video-call .conclusions-tab-container .video-container .patient-video {
    filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.15)) drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.15));
    background: #333;
    box-sizing: border-box;
    border-radius: 12px;
    height: 139px;
    min-height: auto;
    width: 50%;
    margin: 0 5px;
    top: 0;
    right: 0;
    position: relative;
    flex: 1;
}

section.video-call .tabs .profile-tab .video-alerts .info-message,
section.video-call .tabs .records-tab .video-alerts .info-message,
section.video-call .tabs .conclusions-tab .video-alerts .info-message {
    bottom: 0;
}

section.video-call .back-to-video-screen {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

section.video-call .back-to-video-screen .circle {
    background: #F2F3F3;
    border-radius: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 46px;
    height: 46px;
}

section.video-call .back-to-video-screen span {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #001315;
    margin-left: 20px;
}

section.video-call .small-screens .screens .screen-box {
    filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.15)) drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.15));
    background: #80898A;
    box-sizing: border-box;
    border-radius: 12px;
    min-height: 139px;
    width: 100%;
    margin: 0 5px;
}

section.video-call .small-screens .screens {
    display: flex;
    margin: 0 -5px;
}

section.video-call .small-screens .video-actions {
    margin-top: 10px;
}

section.video-call .small-screens .info-message {
    background: #E5E7E8;
    margin-top: 10px;
}

section.video-call .small-screens .info-message.minutes-left.green {
    background: #00CFB4;
}

section.video-call .small-screens .info-message.minutes-left.green svg path {
    fill: #FFFFFF !important;
}

section.video-call .small-screens .info-message.minutes-left.green .text {
    color: #FFF;
}

section.video-call .tab-content .subtitle {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #80898A;
    margin-top: 20px;
    margin-bottom: 10px;
}

section.video-call .tab-content .profile-tab .subtitle:first-child {
    margin-top: 0;
}

section.video-call .tab-content .profile-tab .details-box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 15px 20px;
}

section.video-call .tab-content .profile-tab .details-box .list-group {
    border-radius: 0;
}

section.video-call .tab-content .profile-tab .details-box .list-group-item {
    margin-bottom: 10px;
    border: 0;
    padding: 0 0 7px 0;
    border-bottom: 1px solid #F2F3F3;
    display: flex;
    align-items: center;
}

section.video-call .tab-content .profile-tab .details-box .list-group-item:last-child {
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 0;
}

section.video-call .tab-content .profile-tab .details-box .list-group-item .title {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #80898A;
    margin-right: auto;
}

section.video-call .tab-content .profile-tab .details-box .list-group-item .details {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: right;
    color: #001315;
}

section.video-call .tab-content .profile-tab .disease-box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 15px 20px;
}

section.video-call .tab-content .profile-tab .disease-box .list-group {
    border-radius: 0;
}

section.video-call .tab-content .profile-tab .disease-box .list-group-item {
    margin-bottom: 15px;
    border: 0;
    padding: 0 0 12px 0;
    border-bottom: 1px solid #F2F3F3;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

section.video-call .tab-content .profile-tab .disease-box .list-group-item .title {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #80898A;
    margin-right: auto;
    margin-bottom: 6px;
}

section.video-call .tab-content .profile-tab .disease-box .list-group-item .details {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
}

section.video-call .tab-content .profile-tab .disease-box .list-group-item:last-child {
    margin: 0;
    padding: 0;
    border: 0;
}

section.video-call .tab-content .profile-tab .familiar-history-box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 18px 20px;
    margin-bottom: 10px;
}

section.video-call .tab-content .profile-tab .familiar-history-box .title {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
    margin-bottom: 10px;
}

section.video-call .tab-content .profile-tab .familiar-history-box .description {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 8px;
}

section.video-call .tab-content .profile-tab .familiar-history-box button.read-all-btn {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #00CFB4;
    border: 0;
    background: none;
    margin: 0;
    padding: 0;
}

section.video-call .tab-content .profile-tab .familiar-history-box > div:last-child {
    margin-bottom: 0;
}

section.video-call .tab-content .folder-tab .folder-documents-box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

section.video-call .tab-content .folder-tab .folder-documents-box > .title {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
    margin-bottom: 17px;
    padding-bottom: 12px;
    border-bottom: 1px solid #F2F3F3;
}

section.video-call .tab-content .folder-tab .folder-documents-box .document {
    display: flex;
    flex-direction: column;
}

section.video-call .tab-content .folder-tab .folder-documents-box .document button {
    display: flex;
    align-items: center;
    position: relative;
    border: 0;
    padding: 0;
    margin: 13px 0;
    background: none;
    text-decoration: none !important;
}

section.video-call .tab-content .folder-tab .folder-documents-box .document:nth-child(2) button {
    margin-top: 0;
}

section.video-call .tab-content .folder-tab .folder-documents-box .document:last-child button {
    margin-bottom: 0;
}

section.video-call .tab-content .folder-tab .folder-documents-box .document button .info {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
}

section.video-call .tab-content .folder-tab .folder-documents-box .document button .info .name {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #001315 !important;
    margin-bottom: 2px;
}

section.video-call .tab-content .folder-tab .folder-documents-box .document button .info .data {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #80898A !important;
}

section.video-call .tab-content .folder-tab .folder-documents-box .document button .custom-icon.document {
    margin-right: 16px;
    display: inline-flex;
}

section.video-call .tab-content .folder-tab .folder-documents-box .document button .custom-icon.arrow {
    position: absolute;
    right: -8px;
    top: auto;
    bottom: auto;
}

section.video-call .tab-content .folder-tab .folder-documents-box .list {
    display: flex;
}

section.video-call .tab-content .folder-tab .folder-documents-box .list .avatar {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    display: inline-flex;
    width: 24px;
    height: 24px;
    min-width: 24px;
    border-radius: 100%;
    border: 1px solid rgba(0, 19, 21, 0.1);
    position: relative;
    margin-right: 16px;
}

section.video-call .tab-content .folder-tab .folder-documents-box .list .details {
    display: inline-flex;
    flex-direction: column;
}

section.video-call .tab-content .folder-tab .folder-documents-box .list .details .fullName {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 18px;
    color: #001315;
    margin-bottom: 4px;
}

section.video-call .tab-content .folder-tab .folder-documents-box .list .details .data {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #80898A;
}

section.video-call .tab-content .folder-tab .folder-documents-box .list .details .specialization {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #80898A;
}

section.video-call .tab-content .folder-tab .folder-documents-box .list .details .item .title {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #80898A;
    border-top: 1px solid #F2F3F3;
    margin-bottom: 5px;
    margin-top: 10px;
    padding-top: 15px;
}

section.video-call .tab-content .folder-tab .folder-documents-box .list .details .item .description {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
}

section.video-call .tab-content .folder-tab .folder-documents-box .list .details .item .document a {
    display: flex;
    align-items: center;
    position: relative;
    margin-top: 15px;
    text-decoration: none !important;
}

section.video-call .tab-content .folder-tab .folder-documents-box .list .details .item .document a .info {
    display: inline-flex;
    flex-direction: column;
}

section.video-call .tab-content .folder-tab .folder-documents-box .list .details .item .document a .info .name {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #001315 !important;
    margin-bottom: 2px;
}

section.video-call .tab-content .folder-tab .folder-documents-box .list .details .item .document a .info .data {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #80898A !important;
}

section.video-call .tab-content .folder-tab .folder-documents-box .list .details .item .document a .custom-icon.document {
    margin-right: 16px;
    display: inline-flex;
}

section.video-call .tab-content .folder-tab .folder-documents-box .list .details .item .document a .custom-icon.arrow {
    position: absolute;
    right: -8px;
    top: auto;
    bottom: auto;
}

section.video-call .tab-content .conclusions-tab .uploaded-box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 15px 20px;
    margin-bottom: 10px;
}

section.video-call .tab-content .conclusions-tab .uploaded-box:last-child {
    margin-bottom: 0;
}

section.video-call .tab-content .conclusions-tab .uploaded-file {
    display: flex;
    align-items: center;
    position: relative;
}

section.video-call .tab-content .conclusions-tab .uploaded-file .circle.new-file {
    background: #FF6A39;
    border-radius: 10px;
    width: 10px;
    height: 10px;
    position: absolute;
    top: auto;
    bottom: auto;
    left: -13px;
}

section.video-call .tab-content .conclusions-tab .uploaded-file .file-name {
    display: inline-flex;
    align-items: center;
    max-width: 295px;
    margin-right: auto;
}

section.video-call .tab-content .conclusions-tab .uploaded-file .file-name span {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

section.video-call .tab-content .conclusions-tab .uploaded-file .file-name svg {
    margin-right: 16px;
    min-width: 24px;
}

section.video-call .tab-content .conclusions-tab .uploaded-file button {
    border: 0;
    padding: 0;
    margin: 0;
    background: none;
}

section.video-call .tab-content .conclusions-tab .upload-files {
    width: 100%;
}

section.video-call .tab-content .conclusions-tab .upload-files .files-box label {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 11px 20px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

section.video-call .tab-content .conclusions-tab .upload-files .files-box:last-child label {
    margin: 0;
}

section.video-call .tab-content .conclusions-tab .upload-files .files-box label .info-file {
    display: inline-flex;
    flex-direction: column;
    margin-right: auto;
}

section.video-call .tab-content .conclusions-tab .upload-files .files-box label .info-file .title {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #00CFB4;
    margin-bottom: 2px;
}

section.video-call .tab-content .conclusions-tab .upload-files .files-box label .info-file .details {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #80898A;
}

section.video-call .tab-content .conclusions-tab .upload-files .files-box input {
    display: none;
}

section.video-call .tab-content .conclusions-tab .custom-checkbox {
    margin-top: 20px;
    margin-bottom: 20px;
}

.video-tab-container .remote-view-wrapper {
    filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.15)) drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.15));
    box-sizing: border-box;
    border-radius: 12px;
    height: 580px;
    max-width: 814px;
    background: #333;
    /*display: flex;*/
    justify-content: center;
    align-items: center;
}

.video-tab-container .remote-view-wrapper .mwp {
    width: 100%;
    height: 100%;
    display: flex;
    border-radius: 12px;
}

.remote-view-wrapper .remoteUserComponent {
    display: none !important;
}

.conclusions-tab-container .video-container,
.records-tab-container .video-container,
.profile-tab-container .video-container {
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
}

.conclusions-tab-container .video-container .remote-view-wrapper,
.records-tab-container .video-container .remote-view-wrapper,
.profile-tab-container .video-container .remote-view-wrapper {
    width: 168px;
    height: 137px;
    display: flex;
    border-radius: 12px;
    box-sizing: border-box;
    max-width: 814px;
    background: #000000;
    justify-content: center;
    align-items: center;
}
.conclusions-tab-container .video-container .remote-view-wrapper .mwp,
.records-tab-container .video-container .remote-view-wrapper .mwp,
.profile-tab-container .video-container .remote-view-wrapper .mwp {
    width: 100%;
    height: 100%;
    display: flex;
    border-radius: 12px;
}

.video-container .remote-view-wrapper .vid {
    display: flex;
    width: 100%;
    height: 100%;
    border-radius: 12px;
}

.video-container .remote-view-wrapper .vid div {
    border-radius: 12px;
}

.local-view-wrapper .avatar-picture {
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: #000000;
    width: 100%;
    height: 100%;
    border-radius: 12px;
}

.remote-view-wrapper .avatar-picture {
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: #000000;
    width: 100%;
    height: 100%;
    border-radius: 12px;
}

.video-tab-container .local-view-wrapper {
    position: absolute;
    top: 20px;
    right: 20px;
    border-radius: 12px;
    width: 168px;
    height: 137px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.conclusions-tab-container .local-view-wrapper,
.records-tab-container .local-view-wrapper,
.profile-tab-container .local-view-wrapper {
    top: 20px;
    right: 20px;
    border-radius: 12px;
    width: 168px;
    height: 137px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.local-view-wrapper .vid {
    display: flex;
    width: 100%;
    height: 100%;
}
.local-view-wrapper .vid div {
    border-radius: 12px;
}

.add-medication-btn {
    min-height: 56px;
    padding: 8px 20px;
    color: #00CFB4;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 10px;
    border: 1px solid #E5E7E8;
}
.add-medication-btn svg {
    margin-right: 10px;
}

.medication-white-box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 17px 20px;
    margin-bottom: 10px;
    border: 1px solid #E5E7E8;
}

.medication-subtitle {
    display: flex;
    justify-content: space-between;
}

.medication-subtitle svg {
    cursor: pointer;
}

.btn-add-prescription {
    min-height: 56px;
    padding: 8px 20px;
    color: #00CFB4;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid #E5E7E8;
}

.medication-section {
    display: flex;
    flex-wrap: wrap;
}

.medication-section .medication-subtitle {
    margin-right: 10px;
}

.medication-section .specializations-rates {
    flex-grow: 1;
    /*margin-right: 10px;*/
}
.medication-section .specializations-rates .specialization-item {
    display: flex;
    flex-direction: column;
    justify-content: start;
}

.medication-section .specializations-rates .specialization-item .header {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}

.medication-section .specializations-rates .specialization-item .header .medication-actions {
    display: flex;
    align-items: center;
}

.medication-section .specializations-rates .specialization-item .subtitle {
    width: 100%;
    text-transform: none;
    margin-top: 10px;
}

.medication-section .specializations-rates .specialization-item svg{
    margin-left: 16px;
}

.hidden {
    display: none;
}

section.consultation-details .white-box,
section.video-call .white-box,
section.final-consultation .white-box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 20px;
    margin-bottom: 10px;
    border: 1px solid #e5e7e8;
}

section.video-call .white-box .title,
section.final-consultation .white-box .title {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #80898A;
    margin-bottom: 10px;
    font-weight: 600;
}

section.consultation-details .white-box .add-recipe .btn-add-recipe,
section.video-call .white-box .add-recipe .btn-add-recipe,
section.final-consultation .white-box .add-recipe .btn-add-recipe {
    padding: 0px;
    margin: 0px;
    min-height: 30px;
    display: flex;
    justify-content: space-between;
    color: #00CFB4;
}

.custom-icon-back-medicine {
    margin-bottom: 20px;
    font-family: Rota;
    font-style: normal;
    font-weight: 800;
    cursor: pointer;
}

.recipe-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.recipe-head .recipe-actions {
    display: flex;
}

.recipe-head .recipe-actions svg{
    margin-left: 16px;
}

.recipe-head .recipe-actions .custom-icon-edit,
.recipe-head .recipe-actions .custom-icon-delete {
    cursor: pointer;
}

.recipe-name {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #00CFB4;
}

.add-recipe .subtitle {
    margin-bottom: 0px !important;
}

.greyText {
    color: #80898A;
}

.prescription-warning-text {
    color: #eb3300;
    margin-bottom: 12px;
}