section.account .account-reviews .review-box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 20px;
    margin-bottom: 10px;
    border: 1px solid #E5E7E8;
}
section.account .account-reviews .review-box:last-child {
    margin-bottom: 0;
}
section.account .account-reviews .review-box .header {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
}
section.account .account-reviews .review-box .header .name {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
    display: inline-flex;
    margin-right: auto;
}
section.account .account-reviews .review-box .header .rating {
    font-family: Rota, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #80898A;
    display: inline-flex;
}
section.account .account-reviews .review-box .header .rating svg {
    margin-right: 5px;
}
section.account .account-reviews .review-box .data {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #80898A;
    margin-bottom: 12px;
}
section.account .account-reviews .review-box .description {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
section.account .account-reviews .review-box .read-all {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #00CFB4;
    padding: 0;
    margin: 8px 0 0 0;
    border: 0;
    background: none;
}

section.account .account-reviews .medic-small-box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 12px 20px;
    width: 100%;
    display: flex;
    margin-bottom: 20px;
    align-items: center;
    border: 1px solid #E5E7E8;
}
section.account .account-reviews .medic-small-box .name {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
}
section.account .account-reviews .medic-small-box .rating {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #80898A;
    display: flex;
}
section.account .account-reviews .medic-small-box .rating svg {
    margin-right: 5px;
}

section.account .account-reviews .load-more-btn {
    margin-top: 0;
    padding: 8px 20px;
    min-height: 46px;
}
section.account .account-reviews .medic-small-box .avatar-section {
    margin-right: 20px;
}
section.account .account-reviews .medic-small-box .avatar-section .user-image {
    margin: 0!important;
}
section.account .account-reviews .medic-small-box .avatar-section .avatar-picture {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    width: 36px;
    height: 36px;
    border-radius: 100%;
    border: 1px solid rgba(0, 19, 21, 0.1);
    position: relative;
    margin-right: 10px;
    position: relative;
}
section.account .account-reviews .medic-small-box .avatar-section .user-text {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Rota';
    font-style: normal;
    font-weight: 700;
    font-size: 16px!important;
    line-height: 20px!important;
    text-align: center;
    color: #80898A!important;
    width: 36px;
    height: 36px;
    border-radius: 100%;
    border: 1px solid rgba(0, 19, 21, 0.1);
}
section.account .account-reviews .empty-container {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #80898A;
    margin-top: 60px;
}

.modal-view-review .scroll-list {
    margin-top: -20px;
    margin-bottom: -20px;
    max-height: 620px;
    overflow-y: auto;
    margin-right: -9px;
    padding-right: 9px;
    padding-top: 20px;
    padding-bottom: 20px;
}
.modal-view-review .modal-body .review {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 20px;
    margin-bottom: 10px;
}
.modal-view-review .modal-body .review .header {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
}
.modal-view-review .modal-body .review .header .name {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
    display: inline-flex;
    margin-right: auto;
}
.modal-view-review .modal-body .review .header .rating {
    display: flex;
    align-items: center;
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #80898A;
}
.modal-view-review .modal-body .review .header .rating svg {
    margin-right: 6px;
}
.modal-view-review .modal-body .review .date {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #80898A;
    margin-bottom: 14px;
}
.modal-view-review .modal-body .review .text p {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
}
.modal-view-review .modal-body .review .text p:last-child {
    margin-bottom: 0;
}