section.consultation-details .title-section {
    margin-top: 40px;
    margin-bottom: 30px;
}
section.consultation-details .small-container {
    max-width: 400px;
    margin: auto;
}
section.consultation-details .title-section h1 {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 110%;
    text-align: center;
    color: #001315;
    margin: 0;
}

section.consultation-details .medic-box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 10px 10px 10px 20px;
    display: flex;
    align-items: center;
}

section.consultation-details.cancelled {
    max-width: 400px;
    margin: 0 auto;
}

.shared-documents-box .box {
    /*margin-bottom: 30px;*/
}

.shared-documents-box .box:last-child {
    margin-bottom: 0px;
}

section.consultation-details.cancelled .col-lg-6.col-xl-6.col-md-6
{
    width:100%;
}
section.consultation-details .medic-box .info {
    display: inline-flex;
}
section.consultation-details .medic-box .profile-picture {
    display: flex;
    align-items: center;
    margin-right: 20px;
}
section.consultation-details .medic-box .profile-picture .avatar {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    width: 36px;
    height: 36px;
    border-radius: 100%;
    border: 1px solid rgba(0, 19, 21, 0.1);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
section.consultation-details .medic-box .details {
    display: flex;
    flex-direction: column;
}
section.consultation-details .medic-box .details .name {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
}
section.consultation-details .medic-box .details .specialization {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #80898A;
    margin-bottom: 2px;
}
section.consultation-details .medic-box .action-buttons {
    display: flex;
    align-items: center;
    margin-left: auto;
}
section.consultation-details .medic-box .action-buttons .medic-action-btn {
    margin-left: 10px;
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}
section.consultation-details .medic-box .action-buttons .cancel-btn {
    font-family: Rota, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    box-sizing: border-box;
    border-radius: 12px;
    padding: 11px 20px;
    color: #EB3300;
    background: #FFFFFF;
    border: 1px solid #E5E7E8;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}
section.consultation-details .medic-box .action-buttons .resume-consultation-btn,
section.consultation-details .medic-box .action-buttons .call-now-btn {
    font-family: Rota, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    box-sizing: border-box;
    border-radius: 12px;
    padding: 10px 20px;
    color: #FFFFFF!important;
    background: #00CFB4;
    border: 1px solid #00CFB4;
    text-decoration: none!important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 252px;
}
section.consultation-details .medic-box .action-buttons .resume-consultation-btn {
    min-width: 376px;
}
section.consultation-details .medic-box .action-buttons .resume-consultation-btn svg,
section.consultation-details .medic-box .action-buttons .call-now-btn svg {
    margin-right: 10px;
}
section.consultation-details .medic-box .action-buttons > svg {
    margin-right: 2px;
}

section.consultation-details .subtitle {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #80898A;
    margin-top: 20px;
    margin-bottom: 10px;
}
section.consultation-details .white-button {
    text-decoration: none!important;
    color: #FF6A39!important;
    padding: 9px 20px;
    line-height: 20px;
    min-height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
}
section.consultation-details .white-button svg {
    margin-right: 10px;
}
section.consultation-details .patient-box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 12px 20px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}
section.consultation-details .patient-box .avatar {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    width: 36px;
    height: 36px;
    border-radius: 100%;
    border: 1px solid rgba(0, 19, 21, 0.1);
    position: relative;
    margin-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
section.consultation-details .patient-box .fullName {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
}
section.consultation-details .patient-box .age {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #80898A;
}

section.consultation-details .details-box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 15px 20px;
}
section.consultation-details .details-box .list-group {
    border-radius: 0;
}
section.consultation-details .details-box .list-group-item {
    margin-bottom: 10px;
    border: 0;
    padding: 0 0 7px 0;
    border-bottom: 1px solid #F2F3F3;
    display: flex;
    align-items: center;
}
section.consultation-details .details-box .list-group-item .title {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #80898A;
    margin-right: auto;
}
section.consultation-details .details-box .list-group-item .details {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: right;
    color: #001315;
}
section.consultation-details .details-box .list-group-item:last-child {
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 0;
}
section.consultation-details .review-box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 20px;
}
section.consultation-details .review-box .header {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
}
section.consultation-details .review-box .header .name {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
    display: inline-flex;
    margin-right: auto;
}
section.consultation-details .review-box .header .rating {
    font-family: Rota, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #80898A;
    display: inline-flex;
}
section.consultation-details .review-box .header .rating svg {
    margin-right: 5px;
}
section.consultation-details .review-box .data {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #80898A;
    margin-bottom: 12px;
}
section.consultation-details .review-box .description {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
section.consultation-details .review-box .read-all {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #FF6A39;
    padding: 0;
    margin: 8px 0 0 0;
    border: 0;
    background: none;
}

section.consultation-details .medic-recommendation-box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 20px;
    display: flex;
}
section.consultation-details .shared-documents-box .box.accordion-item {
    border: 0;
    background: none;
}
section.consultation-details .shared-documents-box {
    background: #FFFFFF;
    border-radius: 12px;
    border: 0;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}
section.consultation-details .shared-documents-box .accordion-header .accordion-button .counter-badge {
    background: #F2F3F3;
    border-radius: 12px;
    color: #001315;
    font-family: 'Rota';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    margin-left: auto;
    padding: 5px 8px;
}
section.consultation-details .shared-documents-box .accordion-header .accordion-button::after {
    background-image: url("shared/assets/icons/accordion-item-closed.svg");
    height: 10px;
    margin-left: 15px;
}
section.consultation-details .shared-documents-box .accordion-header .title {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
}
section.consultation-details .shared-documents-box .accordion-header {
    border-bottom: 1px solid #F2F3F3;
}
section.consultation-details .shared-documents-box .accordion-button {
    padding: 15px 20px;
    border: 0!important;
    background: none!important;
    box-shadow: none!important;
}
section.consultation-details .shared-documents-box .accordion-body {
    padding: 0 20px 13px 20px;
}
section.consultation-details .document {
    display: flex;
    flex-direction: column;
}
section.consultation-details .document button {
    display: flex;
    align-items: center;
    position: relative;
    border: 0;
    padding: 0;
    margin: 13px 0;
    background: none;
    text-decoration: none!important;
}
section.consultation-details .document button .icon,
section.consultation-details .document button .arrow-icon {
    flex: 0 0 24px;
}
section.consultation-details .document:nth-child(2) button {
    margin-top: 0;
}
section.consultation-details .document:last-child button {
    margin-bottom: 0;
}
section.consultation-details .document button .info {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    padding-right: 20px;
    flex: 1 1 auto;
}
section.consultation-details .document button .info .name {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #001315!important;
    margin-bottom: 2px;
}
section.consultation-details .document button .info .data {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #80898A!important;
}
section.consultation-details .document button .custom-icon.document {
    margin-right: 16px;
    display: inline-flex;
}
section.consultation-details .document button .custom-icon.arrow {
    /*position: absolute;*/
    right: -8px;
    top: auto;
    bottom: auto;
}
section.consultation-details .shared-documents-box .list {
    display: flex;
}
section.consultation-details .shared-documents-box .list .avatar {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    display: inline-flex;
    width: 24px;
    height: 24px;
    min-width: 24px;
    border-radius: 100%;
    border: 1px solid rgba(0, 19, 21, 0.1);
    position: relative;
    margin-right: 16px;
}
section.consultation-details .shared-documents-box .list .details {
    display: inline-flex;
    flex-direction: column;
}
section.consultation-details .shared-documents-box .list .details .fullName {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 18px;
    color: #001315;
    margin-bottom: 4px;
}
section.consultation-details .shared-documents-box .list .details .data {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #80898A;
}
section.consultation-details .shared-documents-box .list .details .specialization {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #80898A;
}
section.consultation-details .medic-recommendation-box .details {
    width: 100%;
}
section.consultation-details .medic-recommendation-box .details .item .title,
section.consultation-details .shared-documents-box .list .details .item .title {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #80898A;
    border-top: 1px solid #F2F3F3;
    margin-bottom: 5px;
    margin-top: 10px;
    padding-top: 15px;
}
section.consultation-details .medic-recommendation-box .details .item:first-child .title {
    border-top: 0;
    margin-top: 0;
    padding-top: 0;
}
section.consultation-details .medic-recommendation-box .details .item .description,
section.consultation-details .shared-documents-box .list .details .item .description {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
}
section.consultation-details .medic-recommendation-box .details .item .document a,
section.consultation-details .shared-documents-box .list .details .item .document a {
    display: flex;
    align-items: center;
    position: relative;
    margin-top: 15px;
    text-decoration: none!important;
}
section.consultation-details .medic-recommendation-box .details .item .document a .info,
section.consultation-details .shared-documents-box .list .details .item .document a .info {
    display: inline-flex;
    flex-direction: column;
}
section.consultation-details .medic-recommendation-box .details .item .document a .info .name,
section.consultation-details .shared-documents-box .list .details .item .document a .info .name {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #001315!important;
    margin-bottom: 2px;
}
section.consultation-details .medic-recommendation-box .details .item .document a .info .data,
section.consultation-details .shared-documents-box .list .details .item .document a .info .data {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #80898A!important;
}
section.consultation-details .medic-recommendation-box .details .item .document a .custom-icon.document,
section.consultation-details .shared-documents-box .list .details .item .document a .custom-icon.document {
    margin-right: 16px;
    display: inline-flex;
}
section.consultation-details .medic-recommendation-box .details .item .document a .custom-icon.arrow,
section.consultation-details .list .details .item .document a .custom-icon.arrow {
    position: absolute;
    right: -8px;
    top: auto;
    bottom: auto;
}

span.gross_price {
    padding-right:10px;
    color: #80898A;
    text-decoration: line-through;
}

section.consultation-details .medic-box .action-buttons .call-now-btn.disabled {
    pointer-events: none;
    background: #F2F3F3!important;
    border-color: #F2F3F3!important;
    color: #80898A!important;
}

section.consultation-details .medic-box .action-buttons .call-now-btn.disabled svg path {
    fill: #80898A!important;
}

.modal-medical-documents .empty-container {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #80898A;
    margin-top: 30px;
    margin-bottom: 30px;
}
