section.medic-profile .top-buttons {
    margin-top: 40px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
}

section.medic-profile.mobile .top-buttons {
    margin-top: 10px;
    flex-wrap: wrap;
    align-content: flex-end;
    justify-content: flex-end;
}

section.medic-profile .top-buttons button {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 13px 25px;
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 19px;
    color: #001315;
    border: 0;
    display: inline-flex;
    align-items: center;
    margin-right: 10px;
    min-height: 50px;
}

section.medic-profile.mobile .top-buttons button{
    margin-right: 0;
    margin-left: 10px;
}

section.medic-profile .top-buttons button svg {
    margin-right: 10px;
}

section.medic-profile .top-buttons button .text {
    padding-top: 3px;
}

section.medic-profile .top-buttons button.share .text {
    padding-top: 4px;
}

section.medic-profile.mobile .top-buttons button svg {
    margin-right: 0;
}

section.medic-profile .top-buttons button.favorite.active {
    color: #FF6A39;
}

section.medic-profile .medic-box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 20px;
    display: flex;
    align-items: center;
    min-height: 148px;
    flex-direction: row;
}

section.medic-profile.mobile .medic-box {
}


section.medic-profile .medic-box .profile-picture {
    align-items: center;
    margin-right: 20px;
    width: 108px;
    height: 108px;
}

section.medic-profile .medic-box .profile-picture .avatar {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    width: 108px;
    height: 108px;
    border-radius: 100%;
    border: 1px solid rgba(0, 19, 21, 0.1);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

section.medic-profile .medic-box .profile-picture .avatar .status {
    border: 3px solid #FFFFFF;
    width: 22px;
    height: 22px;
    border-radius: 100%;
    position: absolute;
    top: 5px;
    left: 5px;
}

section.medic-profile .medic-box .profile-picture .avatar .status.online {
    background: #00CFB4;
}

section.medic-profile .medic-box .profile-picture .avatar .status.offline {
    background: #BFC4C5;
}

section.medic-profile .medic-box .profile-picture .avatar .status.busy {
    background: #F6BE00;
}

section.medic-profile .medic-box .details {
    display: flex;
    flex-direction: column;
}

section.medic-profile.mobile .medic-box .details {
    align-items: center;
}

section.medic-profile.mobile .medic-box .details {
    align-items: center;
}

section.medic-profile .medic-box .details .rating {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #80898A;
    display: flex;
    align-items: center;
    margin-bottom: 2px;
}

section.medic-profile .medic-box .details .rating svg {
    margin-right: 5px;
}

section.medic-profile .medic-box .details .name {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    color: #001315;
    margin-bottom: 2px;
}

section.medic-profile .medic-box .details .specialization {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
    margin-bottom: 2px;
}

section.medic-profile.mobile .medic-box .details .specialization {
    text-align: center
}

section.medic-profile .medic-box .details .clinic {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #80898A;
}

section.medic-profile .medic-box .action-buttons {
    display: flex;
    align-items: stretch;
    margin-left: auto;
}

section.medic-profile.mobile .medic-box .action-buttons {
    margin-top: 10px;
    width: 100%
}

section.medic-profile .medic-box .action-buttons .medic-action-btn {
    font-family: Rota, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    box-sizing: border-box;
    border-radius: 12px;
    padding: 15px 15px;
    margin-left: 10px;
    /*width: 100%;*/
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}

section.medic-profile .medic-box .action-buttons .appointment-btn {
    color: #001315;
    background: #FFFFFF;
    border: 1px solid #E5E7E8;
    min-height: 56px;
    min-width: 148px;
}

section.medic-profile.mobile .medic-box .action-buttons .appointment-btn {
    width: 100%
}

section.medic-profile .medic-box .action-buttons .call-now-btn {
    color: #FFFFFF;
    background: #00CFB4;
    border: 1px solid #00CFB4;
    min-height: 56px;
    min-width: 148px;
}

section.medic-profile .medic-box .action-buttons .busy-btn {
    color: #F4AF23;
    background: #FEF5D9;
    border: 1px solid #FEF5D9;
    pointer-events: none;
}

section.medic-profile .medic-box .action-buttons .offline-btn {
    color: #80898A;
    background: #F2F3F3;
    border: 1px solid #F2F3F3;
    pointer-events: none;
}

section.medic-profile .medic-box .action-buttons .call-now-btn svg,
section.medic-profile .medic-box .action-buttons .appointment-btn svg {
    margin-right: 13px;
}

section.medic-profile .subtitle {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #80898A;
    margin-top: 20px;
    margin-bottom: 10px;
}

section.medic-profile .about .box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 20px;
    margin-bottom: 10px;
}

section.medic-profile .about .box .text {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
    margin-bottom: 4px;
}

section.medic-profile .about .box .read-all {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #FF6A39;
    padding: 0;
    margin: 0;
    border: 0;
    background: none;
}

section.medic-profile .about .view-cv {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 11px 15px 11px 18px;
    display: flex;
    align-items: center;
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 19px;
    color: #FF6A39;
    text-decoration: none !important;
}

section.medic-profile .about .view-cv svg.document {
    margin-right: 10px;
}

section.medic-profile .about .view-cv svg.arrow {
    margin-left: auto;
}

section.medic-profile .schedule .box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 18px 20px;
}

section.medic-profile .schedule .box .list-group {
    border-radius: 0;
}

section.medic-profile .schedule .box .list-group-item {
    margin-bottom: 10px;
    border: 0;
    padding: 0 0 8px 0;
    border-bottom: 1px solid #F2F3F3;
    display: flex;
    align-items: center;
}

section.medic-profile .schedule .box .list-group-item .day {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #80898A;
    margin-right: auto;
}

section.medic-profile .schedule .box .list-group-item .interval-hours {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: right;
    color: #001315;
}

section.medic-profile .schedule .box .list-group-item:last-child {
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 0;
}

section.medic-profile .reviews .box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 20px;
    margin-bottom: 10px;
}

section.medic-profile .reviews .box.no-reviews {
    text-align: center;
    padding-top: 32px;
}

section.medic-profile .reviews .box.no-reviews .no-reviews-text {
    font-family: 'Rota';
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #80898A;
}

section.medic-profile .reviews .box .review {
    border-bottom: 1px solid #F2F3F3;
    margin-bottom: 18px;
    padding-bottom: 16px;
}

section.medic-profile .reviews .box .review .header {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
}

section.medic-profile .reviews .box .review .header .name {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
    display: inline-flex;
    margin-right: auto;
}

section.medic-profile .reviews .box .review .header .rating {
    display: flex;
    align-items: center;
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #80898A;
}

section.medic-profile .reviews .box .review .header .rating svg {
    margin-right: 6px;
}

section.medic-profile .reviews .box .review .date {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #80898A;
    margin-bottom: 14px;
}

section.medic-profile .reviews .box .review .text {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
    word-break: break-word;
}

section.medic-profile .reviews .box .review .read-all {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #FF6A39;
    padding: 0;
    border: 0;
    background: none;
    margin-top: 10px;
}

section.medic-profile .reviews .box .review:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border: 0;
}

section.medic-profile .reviews .view-all-reviews {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 11px 15px 11px 20px;
    display: flex;
    width: 100%;
    align-items: center;
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 19px;
    color: #FF6A39;
    border: 0;
}

section.medic-profile .reviews .view-all-reviews svg {
    margin-left: auto;
}

section.medic-profile .medic-small-box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 20px;
    margin-top: 40px;
    display: flex;
    align-items: center;
}

section.medic-profile .medic-small-box .avatar {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    width: 36px;
    height: 36px;
    border-radius: 100%;
    border: 1px solid rgba(0, 19, 21, 0.1);
    position: relative;
    margin-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

section.medic-profile .medic-small-box .name {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
}

section.medic-profile .medic-small-box .rating {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #80898A;
    display: flex;
}

section.medic-profile .medic-small-box .rating svg {
    margin-right: 5px;
}

section.medic-profile .curriculum-vitae .cv-preview img {
    border-radius: 12px;
    filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.15)) drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.15));
}

.modal-all-reviews .medic-small-box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 12px 20px;
    width: 100%;
    display: flex;
    align-items: center;
}

.modal-all-reviews .medic-small-box .avatar {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    width: 36px;
    height: 36px;
    border-radius: 100%;
    border: 1px solid rgba(0, 19, 21, 0.1);
    position: relative;
    margin-right: 20px;
}

.modal-all-reviews .medic-small-box .name {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
}

.modal-all-reviews .medic-small-box .rating {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #80898A;
    display: flex;
}

.modal-all-reviews .medic-small-box .rating svg {
    margin-right: 5px;
}

.modal-all-reviews .scroll-list {
    margin-top: -20px;
    margin-bottom: -20px;
    max-height: 620px;
    overflow-y: auto;
    margin-right: -9px;
    padding-right: 9px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.modal-all-reviews .modal-body .review {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 20px;
    margin-bottom: 10px;
}

.modal-all-reviews .modal-body .review .header {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
}

.modal-all-reviews .modal-body .review .header .name {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
    display: inline-flex;
    margin-right: auto;
}

.modal-all-reviews .modal-body .review .header .rating {
    display: flex;
    align-items: center;
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #80898A;
}

.modal-all-reviews .modal-body .review .header .rating svg {
    margin-right: 6px;
}

.modal-all-reviews .modal-body .review .date {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #80898A;
    margin-bottom: 14px;
}

.modal-all-reviews .modal-body .review .text {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
}

.modal-all-reviews .modal-body .review .read-all {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #FF6A39;
    padding: 0;
    border: 0;
    background: none;
    margin-top: 10px;
}

.modal-all-reviews .modal-body .review:last-child {
    margin-bottom: 0;
}

.modal-all-reviews .white-button {
    min-height: 46px;
    padding: 10px 20px;
}

.modal-view-profile-picture .modal-dialog {
    max-width: 600px;
    justify-content: center;
}

.modal-view-profile-picture .modal-content {
    background: none !important;
    box-shadow: none !important;
    width: auto;
}

.modal-view-profile-picture .modal-header {
    border: 0;
    justify-content: center;
    position: relative;
}

.modal-view-profile-picture .modal-header .modal-title {
    color: #FFFFFF;
}

.modal-view-profile-picture .modal-header .btn-close {
    position: absolute;
    left: 0;
    width: 24px !important;
    height: 24px !important;
    background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFF'%3E%3Cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3E%3C/svg%3E") center/1em auto no-repeat !important;
}

.modal-view-profile-picture .modal-header .btn-close:after {
    display: none !important;
}

.modal-view-profile-picture .modal-body {
    padding: 0;
    border-radius: 12px;
    text-align: center;
}

.modal-view-profile-picture .modal-body img {
    border-radius: 12px;
}

.modal-backdrop.modal-view-profile-picture.show {
    opacity: 1;
}


section.medic-profile .image-placeholder {
    font-size: 2.5em;
    font-weight: 600;
}