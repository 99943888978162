section.consultations {
    max-width: 400px;
    margin: auto;
}

section.consultations.patient .title-section {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
}

section.consultations .title-section {
    margin-top: 40px;
    margin-bottom: 30px;
}

section.consultations .title-section h1 {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 110%;
    text-align: center;
    color: #001315;
    margin: 0;
}

section.consultations.patient .nav.nav-tabs {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
}

section.consultations .nav.nav-tabs {
    border: 0;
    display: flex;
    margin-left: -5px;
    margin-right: -5px;
    margin-bottom: 15px;
}

section.consultations .nav.nav-tabs .nav-item {
    flex: 1 1 auto;
    width: 50%;
    padding: 0 5px;
}

section.consultations .nav.nav-tabs .nav-item .nav-link {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #001315;
    background: #E5E7E8;
    border-radius: 10px;
    padding: 8px 20px;
    border: 0;
    cursor: pointer;
}

section.consultations .nav.nav-tabs .nav-item .nav-link.active {
    background: #FFFFFF;
}

section.consultations .subtitle {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #80898A;
    margin-top: 20px;
    margin-bottom: 10px;
}

section.consultations.patient .box.upcoming {
    margin-bottom: 10px;
    display: flex;
}

section.consultations.patient .box.upcoming .data {
    background: #FFFFFF;
    border-radius: 12px;
    display: inline-flex;
    width: 66px;
    height: 100%;
    margin-right: 10px;
    align-items: center;
    justify-content: center;
    font-family: 'Rota';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #001315;
}

section.consultations.patient .box.upcoming .details {
    background: #FFFFFF;
    border-radius: 12px;
    /*display: inline-flex;*/
    display: block;
    width: calc(100% - 66px - 10px);
    align-items: center;
}

section.consultations.patient .box.upcoming .details.need-to-pay {
    flex-direction: column;
}

section.consultations.patient .box.upcoming .medic-info .left-column.need-to-pay {
    width: 100%;
}

section.consultations.patient .box.upcoming .medic-info .right-column.need-to-pay {
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
}

section.consultations.patient .box.upcoming .medic-info .right-column.need-to-pay .info-message {
    margin-top: 0;
    width: 100%;
    padding: 11px 10px 11px 20px;
}

section.consultations.patient .box.upcoming .medic-info .right-column.need-to-pay .info-message .text {
    width: 274px;
}

section.consultations.patient .box.upcoming .medic-info {
    position: relative;
    display: flex;
    align-items: center;
    text-decoration: none !important;
    width: 100%;
}

section.consultations.patient .box.upcoming .medic-info .left-column {
    display: inline-flex;
    align-items: center;
    /*width: 421px;*/
    width: 100% !important;
    padding: 20px;
    border-right: 1px solid #F2F3F3;
}

section.consultations.patient .box.upcoming .medic-info .right-column {
    display: inline-flex;
    align-items: center;
    padding-left: 5px;
    padding-right: 5px;
    /*width: calc(100% - 421px);*/
    width: 100% !important;
    padding-bottom: 10px;

}

section.consultations.patient .box.past {
    background: #FFFFFF;
    border-radius: 12px;
    margin-bottom: 10px;
}

section.consultations.patient .box.past .medic-info {
    position: relative;
    /*display: flex;*/
    align-items: center;
    text-decoration: none !important;
    display: block;
}

section.consultations.patient .box.past .medic-info .left-column {
    display: inline-flex;
    align-items: center;
    /*width: 445px;*/
    width: 100%;
    padding: 30px 20px;
    border-right: 1px solid #F2F3F3;
}

section.consultations.patient .box.past .medic-info .right-column {
    display: inline-flex;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    /*width: calc(100% - 445px);*/
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
}

section.consultations.patient .box.past .medic-info svg.arrow-right {
    display: inline-flex;
    margin-left: auto;
    margin-right: -8px;
}

section.consultations .box .medic-info .avatar {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    width: 36px;
    height: 36px;
    border-radius: 100%;
    border: 1px solid rgba(0, 19, 21, 0.1);
    position: relative;
    margin-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

section.consultations .box .medic-info .fullName {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
}

section.consultations .box .medic-info .specialization {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #80898A;
}

section.consultations.patient .box.upcoming .medic-info svg.arrow-right {
    display: inline-flex;
    margin-left: auto;
    margin-right: -8px;
}

section.consultations.patient .box.past .list-group {
    width: 100%;
}

section.consultations.patient .box.past .list-group-item {
    margin-bottom: 12px;
    border: 0;
    padding: 0;
    display: flex;
    align-items: center;
}

section.consultations.patient .box.past .list-group-item:last-child {
    margin-bottom: 0;
}

section.consultations.patient .box.upcoming .list-group-item {
    margin-bottom: 8px;
    border: 0;
    padding: 0;
    display: flex;
    align-items: center;
}

section.consultations.patient .box.past .list-group-item .title {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #80898A;
    margin-right: auto;
    display: inline-flex;
}

section.consultations.patient .box.past .list-group-item .details {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: right;
    color: #001315;
    display: inline-flex;
}

section.consultations .list-group-item:last-child {
    margin-bottom: 0;
}

section.consultations .box .info-message {
    color: #FF6A39;
    background: rgba(255, 106, 57, 0.1);
    margin-top: 15px;
}

section.consultations.patient .box.upcoming .need-to-pay .actions {
    width: auto;
    margin-left: auto;
}

section.consultations.patient .box.upcoming .actions {
    display: flex;
    width: 100%;
    align-items: center;
}

section.consultations .actions.pay {
    flex-direction: row;
    justify-content: center;
    margin-left: 0;
    margin-right: 0;
}

section.consultations .actions .cancel-btn {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #EB3300;
    background: #FFFFFF;
    border: 1px solid #E5E7E8;
    box-sizing: border-box;
    border-radius: 12px;
    padding: 16px 5px;
    display: inline-flex;
    justify-content: center;
    width: 118px;
    margin: 0 5px;
}

section.consultations .actions .cancel-btn:disabled {
    background-color: #ced4da;
    border: 2px solid #a2aeb9;
}

section.consultations .actions.pay .cancel-btn {
    width: auto;
    padding: 11px 10px;
    margin: 0;
    min-width: 101px;
    margin-left: 10px;
    border: 1px solid #FFFFFF;
}

section.consultations .actions .medic-action-btn {
    font-family: Rota, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    box-sizing: border-box;
    border-radius: 12px;
    padding: 15px 5px;
    margin: 0 5px;
    width: calc(100% - 138px);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}

section.consultations .actions .appointment-btn {
    color: #001315;
    background: #FFFFFF;
    border: 1px solid #E5E7E8;
}

section.consultations .actions .call-now-btn,
section.consultations .actions .resume-consultation-btn {
    color: #FFFFFF;
    background: #00CFB4;
    border: 1px solid #00CFB4;
    text-decoration: none !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

section.consultations .actions .busy-btn {
    color: #F4AF23;
    background: #FEF5D9;
    border: 1px solid #FEF5D9;
    pointer-events: none;
}

section.consultations .actions .offline-btn {
    color: #80898A;
    background: #F2F3F3;
    border: 1px solid #F2F3F3;
    pointer-events: none;
}

section.consultations .actions .resume-consultation-btn {
    width: 100%;
}

section.consultations .actions .call-now-btn svg,
section.consultations .actions .offline-btn svg,
section.consultations .actions .resume-consultation-btn svg,
section.consultations .actions .appointment-btn svg {
    margin-right: 13px;
}

section.consultations .actions.pay .orange-button {
    text-decoration: none !important;
    color: #FFFFFF !important;
    padding: 11px 10px;
    line-height: 20px;
    min-height: 46px;
    min-width: 184px;
    width: auto;
    margin-left: 10px;
}

section.consultations .empty-container {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #80898A;
    margin-top: 60px;
}
