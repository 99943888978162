section.mlm-auth {
    margin: auto;
    width: 100%;
}
section.mlm-auth .title-section {
    margin-top: 30px;
    margin-bottom: 15px;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
}
section.mlm-auth .title-section p {
    font-family: 'Rota';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #001315;
}
section.mlm-auth .searchbar {
    background: #E5E7E8;
    border-radius: 12px;
    padding: 17px 20px;
    display: flex;
    align-items: center;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
}
section.mlm-auth .searchbar svg {
    margin-right: 10px;
    min-width: 20px;
}
section.mlm-auth .searchbar input {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #80898A;
    width: 100%;
    background: none;
    border: 0;
    padding: 0;
    outline: none;
    box-shadow: none;
}
section.mlm-auth hr {
    margin: auto;
    margin-top: 20px!important;
    margin-bottom: 20px!important;
    width: 100%;
    border-bottom: 1px solid #E5E7E8;
}
section.mlm-auth .content {
    margin: auto;
    max-width: 400px;
    text-align: center;
}
section.mlm-auth .content .cancel-button {
    margin-top: 20px;
}
section.mlm-auth .mlm-search-icon {
    display: inline-flex;
    margin-top: 130px;
}
section.mlm-auth .profile-box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 12px 20px;
    margin-bottom: 10px;
}
section.mlm-auth .profile-box .info {
    width: calc(100% - 36px - 20px);
}

.searchbar-tooltip {
    padding: 17px 0px;
    display: flex;
    align-items: center;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
}

.searchbar-tooltip .input-tooltip{
    color: #80898a;
    font-family: Rota;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 17px;
    margin-bottom: 10px;
    margin-top: -5px;
    padding-left: 10px;
}