.container:has(.medic-list) {
    max-width: 1231px !important;
}

.largest-width {
    display: flex;
}

section.medic-list .medic-list-filters.sticky-filter {
    position: sticky;
    top: 121px;
    z-index: 100;
    background: #F5F5F5;
    padding-top: 9px;
}

section.medic-list .medic-list-filters.sticky-filter-with-voucher {
    position: sticky;
    top: 162px;
    z-index: 100;
    background: #F5F5F5;
    padding-top: 9px;
}


section.medic-list .medic-list-filters {
    min-width: 245px;
    align-self: flex-start;
}

section.medic-list .medic-list-filters-mobile {
    min-width: 245px;
    align-self: flex-start;
}

section.medic-list .sticky-filter .medic-list-filters {
    margin-bottom: 0px;
}

section.medic-list .medic-list-doctors {
    align-self: flex-start;
}

section.medic-list .title-filters-section {
    display: flex;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 20px;
    flex: 1 1 100%;
}

section.medic-list .title-section {
    display: inline-flex;
    margin-right: auto;
}

section.medic-list .title-section h1 {
    font-family: Rota, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 110%;
    text-align: center;
    color: #001315;
    margin: 0;
}

section.medic-list .filters-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 !important;
}

section.medic-list .filters-section-mobile {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 !important;
}

section.medic-list .medic-list-doctors .searchbar,
section.medic-list .filters-section .searchbar {
    background: #FFFFFF;
    box-sizing: border-box;
    border-radius: 12px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
    flex: 0 1 100%;
    margin-top: 10px;
    margin-bottom: 10px;
}

section.medic-list .filters-section .searchbar.active {
    padding: 10px 5px 10px 20px;
    display: flex;
}

section.medic-list .medic-list-doctors .searchbar.active {
    padding: 5px 5px 5px 20px;
    display: flex;
}

section.medic-list .medic-list-doctors .searchbar.active span,
section.medic-list .filters-section .searchbar.active span {
    display: none;
}

section.medic-list .medic-list-doctors .searchbar input,
section.medic-list .medic-list-doctors .searchbar button,
section.medic-list .filters-section .searchbar input,
section.medic-list .filters-section .searchbar button {
    display: none;
}

section.medic-list .medic-list-doctors .searchbar.active input,
section.medic-list .filters-section .searchbar.active input {
    font-family: Rota, serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
    border: 0;
    margin: 0;
    padding: 0;
    outline: none;
    box-shadow: none;
    min-width: 199px;
    display: inline-block;
}

section.medic-list .medic-list-doctors .searchbar.active button,
section.medic-list .filters-section .searchbar.active button {
    font-family: Rota, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #FF6A39;
    border: 0;
    outline: none;
    box-shadow: none;
    background: none;
    padding: 7px 10px;
    display: inline-block;
    justify-items: flex-end;
}

section.medic-list .medic-list-doctors .searchbar svg,
section.medic-list .filters-section .searchbar svg {
    margin-right: 10px;
}

section.medic-list .medic-list-doctors .searchbar span,
section.medic-list .filters-section .searchbar span {
    font-family: Rota, serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    color: #001315;
}

section.medic-list .filters-section .specialization-selected {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    color: #FF6A39;
    box-sizing: border-box;
    border-radius: 12px;
    border-bottom: 1px solid #F2F3F3;
    padding-top: 16px;
    padding-bottom: 15px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    flex: 0 1 100%;
}

section.medic-list .filters-section .specialization-selected .specialization-selected-name {
    flex: 1;
}

section.medic-list .filters-section .specialization-selected svg {
    align-self: flex-end;
}

section.medic-list .filters-section .specialization {
    font-family: Rota, serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    color: #001315;
    background: #FFFFFF;
    border: 2px solid #FFF;
    box-sizing: border-box;
    border-radius: 12px;
    padding: 10px 40px;
    display: flex;
    align-items: center;
    margin: 0 5px;
    cursor: pointer;
}

section.medic-list .filters-section-mobile .specialization {
    font-family: Rota, serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    color: #001315;
    background: #FFFFFF;
    border: 2px solid #FFF;
    box-sizing: border-box;
    border-radius: 12px;
    padding: 10px 40px;
    display: flex;
    align-items: center;
    margin: 0 5px;
    cursor: pointer;
}

section.medic-list .filters-section .specialization svg {
    margin-right: 10px;
}

section.medic-list .filters-section-mobile .specialization svg {
    margin-right: 10px;
}

section.medic-list .specialization-description {
    font-family: 'Rota';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
    margin-bottom: 13px;
}

section.medic-list .tabs-section {
    border-top: 1px solid #E5E7E8;
    border-bottom: 1px solid #E5E7E8;
    margin-bottom: 20px;
}

section.medic-list .nav.nav-tabs {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    border: 0;
    display: flex;
    margin-bottom: 10px;
    margin-top: 10px;
}

section.medic-list .nav.nav-tabs .nav-item {
    flex: 1 1 auto;
    width: 50%;
    padding: 0 5px;
}

section.medic-list .nav.nav-tabs .nav-item .nav-link.active {
    background: #FFFFFF;
    color: #FF6A39;
}

section.medic-list .nav.nav-tabs .nav-item .nav-link {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #001315;
    background: #E5E7E8;
    border-radius: 10px;
    padding: 8px 20px;
    border: 0;
    cursor: pointer;
}

section.medic-list .info-message {
    margin-bottom: 10px;
}

section.medic-list .vouchers {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 11px 20px;
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #00CFB4;
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
}

section.medic-list .vouchers svg {
    min-width: 24px;
    margin-right: 20px;
}

.modal-control-alert .icon {
    display: flex;
    justify-content: center;
}

.modal-control-alert .title {
    font-family: 'Rota';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: #001315;
    margin-top: 12px;
    margin-bottom: 20px;
}

.modal-control-alert p {
    font-family: 'Rota';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
}

section.medic-list .col-12 {
    margin-bottom: 20px;
}

section.medic-list .box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 0px;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

section.medic-list .box .medic-card-header {
    height: 72px;
    padding: 18px;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    display: flex;
    justify-content: flex-end;
}

section.medic-list .box .medic-card-header.online {
    background: #B3E6DF;
}

section.medic-list .box .medic-card-header.busy {
    background: #FBE6BD;
}

section.medic-list .box .medic-card-header.offline {
    background: #E5E7E8;
}

section.medic-list .box .medic-card-header.find-medic-card-header {
    background: #FFDBD0;
    justify-content: center;
    margin-bottom: 57px;
}

section.medic-list .box .medic-card-header .share-btn {
    background: rgba(255, 255, 255, 0.8);
    width: 36px;
    height: 36px;
    text-align: center;
    vertical-align: middle;
    padding-top: 4px;
    cursor: pointer;
    border-radius: 50%;
    margin-left: 10px;
}

section.medic-list .box .medic-card-header .favorite {
    background: rgba(255, 255, 255, 0.8);
    width: 36px;
    height: 36px;
    text-align: center;
    vertical-align: middle;
    padding-top: 4px;
    cursor: pointer;
    border-radius: 50%;
    margin-left: 10px;
}

section.medic-list .box .link-box {
    padding-bottom: 10px;
    flex: 1;
    flex-wrap: wrap;
    justify-content: flex-start;
}

section.medic-list .box .rating {
    font-family: Rota, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #80898A;
    margin-bottom: 15px;
    display: flex;
    flex: 1 1 100%
}

section.medic-list .box .rating svg {
    margin-right: 5px;
}

section.medic-list .box .share-btn .share-icon {
    background-image: url("../../shared/assets/icons/share-btn-icon.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center center;
    display: inline-block;
    width: 24px;
    height: 24px;
}

section.medic-list .box .medic-info {
    display: flex;
    margin-bottom: 10px;
    padding: 0px 20px 0px 20px;
    flex-grow: 1;
}

section.medic-list .box .medic-info .left-column {
    display: flex;
    flex-direction: column;
    margin-right: auto;
    min-height: 72px;
    width: 100%;
}

section.medic-list .box .medic-info .name {
    font-family: Rota, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    color: #001315;
}

section.medic-list .box .medic-info .specialization {
    font-family: Rota, serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #001315;
    margin-bottom: 2px;
}

section.medic-list .box .medic-info .clinic {
    font-family: Rota, serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #80898A;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

section.medic-list .box .medic-info .clinic .info-clinic {
    font-family: Rota, serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #80898A;
    justify-content: flex-start;
    flex-grow: 1;
}

section.medic-list .box .medic-info .clinic .avatar {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    width: 100px;
    height: 100px;
    border-radius: 100%;
    border: 1px solid rgba(0, 19, 21, 0.1);
    position: relative;
    margin-right: 10px;
    justify-content: flex-end;
    flex-shrink: 0;
}

section.medic-list .box .medic-info .profile-picture {
    display: flex;
    align-items: center;
    margin-left: 20px;
}

section.medic-list .box .medic-info .profile-picture .avatar {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    width: 66px;
    height: 66px;
    border-radius: 100%;
    border: 1px solid rgba(0, 19, 21, 0.1);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

section.medic-list .box .medic-info .profile-picture .avatar .status {
    border: 3px solid #FFFFFF;
    width: 21px;
    height: 21px;
    border-radius: 100%;
    position: absolute;
    top: -3px;
    left: -3px;
}

section.medic-list .box .medic-info .profile-picture svg {
    position: relative;
    right: -8px;
}

section.medic-list .box .rating {
    display: flex;
    justify-content: space-between;
    flex: 1 1 100%;
    height: 50px
}

section.medic-list .box .rating .profile-picture {
    align-items: center;
    margin-left: 20px;
    margin-top: -50px;
    height: 100px;
}

section.medic-list .box .rating .profile-picture .avatar {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    width: 100px;
    height: 100px;
    border-radius: 100%;
    border: 3px solid #FFFFFF;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

section.medic-list .box .rating .profile-picture .avatar .status {
    border: 3px solid #FFFFFF;
    width: 21px;
    height: 21px;
    border-radius: 100%;
    position: absolute;
    top: 72px;
    left: 72px;
}

section.medic-list .box .rating .profile-picture .avatar .image-placeholder {
    font-size: 2.5em;
    font-weight: 600;
}

section.medic-list .box .rating .profile-picture svg {
    position: relative;
    right: -8px;
}

section.medic-list .box .rating .rating-value {
    height: 25px;
    margin-top: 20px;
    margin-right: 20px;
    vertical-align: center;
    display: flex;
    padding: 5px 10px 6px;
    border: 1px solid #E5E7E8;
    border-radius: 12px;
}

section.medic-list .box .rating .rating-value .custom-icon {
    display: block !important;
}

section.medic-list .box .rating .rating-value span {
    display: block;
    font-size: 16px;
    color: #001315;
}

section.medic-list .box .footer-box .border-top {
    padding-top: 0px;
}

section.medic-list .box .footer-box .subtitle {
    font-family: Rota, serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #001315;
    margin-bottom: 2px;
}

section.medic-list .box .footer-box .first-available-time {
    padding-top: 12px;
    margin-bottom: 8px;
}

section.medic-list .box .footer-box .prices {
    padding-top: 12px;
}


section.medic-list .box .footer-box .subtitle-value {
    font-family: Rota, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #80898A;
    margin-bottom: 2px;
}

section.medic-list .box .footer-box .appointment-btn-texts .subtitle {
    font-family: Rota, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #80898A;
    margin-bottom: 2px;
}


section.medic-list .box .footer-box .availability {
    font-family: Rota, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #80898A;
}

section.medic-list .box .medic-info .profile-picture .avatar .status.online {
    background: #00CFB4;
}

section.medic-list .box .medic-info .profile-picture .avatar .status.offline {
    background: #BFC4C5;
}

section.medic-list .box .medic-info .profile-picture .avatar .status.busy {
    background: #F6BE00;
}

section.medic-list .box .rating .profile-picture .avatar .status.online {
    background: #00CFB4;
}

section.medic-list .box .rating .profile-picture .avatar .status.offline {
    background: #BFC4C5;
}

section.medic-list .box .rating .profile-picture .avatar .status.busy {
    background: #F6BE00;
}

section.medic-list .box .description {
    font-family: Rota, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #80898A;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin: 0px 20px 20px 20px;
    padding-top: 12px;
    border-top: 1px solid #F2F3F3 !important;
    flex: 1 1 100%;
}

section.medic-list .box .footer-box {
    padding: 12px 20px 20px;
}

section.medic-list .box .price {
    font-family: Rota, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #FF6A39;
    margin-bottom: 10px;
}

section.medic-list .box .action-buttons {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 -5px;
    position: relative;
}

section.medic-list .box .action-buttons .medic-action-btn {
    font-family: Rota, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    box-sizing: border-box;
    border-radius: 12px;
    padding: 20px 15px;
    margin: 0 5px;
    display: flex;
    text-decoration: none;
    flex: 1 1 100%;
}

section.medic-list .box .action-buttons .appointment-btn {
    color: #001315;
    background: #FFFFFF;
    border: 1px solid #E5E7E8;
    display: flex;
    margin-bottom: 10px;
    min-height: 56px;
    padding: 15px;
    justify-content: center;
}

section.medic-list .box .action-buttons .appointment-btn .appointment-btn-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    margin-right: 13px;
}

section.medic-list .box .action-buttons .appointment-btn .appointment-btn-texts {
    padding-top: 2px;
}

section.medic-list .box .action-buttons .appointment-btn .appointment-btn-texts .appointment-btn-texts-schedule {
    flex: 1 1 100%;
}

section.medic-list .box .action-buttons .call-now-btn {
    color: #FFFFFF;
    background: #00CFB4;
    border: 1px solid #00CFB4;
    justify-content: center;
    padding: 15px;
}

section.medic-list .box .action-buttons .call-now-btn .call-now-btn-text {
    padding-top: 2px;
}

section.medic-list .w216 {
    width: 216px;
}

section.medic-list .how-does-it-works {
    font-family: Rota, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    box-sizing: border-box;
    border-radius: 12px;
    padding: 15px 15px;
    display: flex;
    text-decoration: none;
    background: #FF6A39;
    border-color: #FF6A39;
    width: 218px;
    cursor: pointer;
}

section.medic-list .how-does-it-works.wbtn {
    background: #FFFFFF;
    /*border: 1px solid #FF6A39 !important;*/
}

section.medic-list .how-does-it-works svg {
    margin-right: 13px;
}

section.medic-list .how-does-it-works .call-now-btn-text {
    padding-top: 2px;
    color: #FFFFFF;
}
section.medic-list .how-does-it-works.wbtn .call-now-btn-text {
    color: #FF6A39;
}

section.medic-list .box .action-buttons .busy-btn {
    color: #F4AF23;
    background: #FEF5D9;
    border: 1px solid #FEF5D9;
    pointer-events: none;
}

section.medic-list .box .action-buttons .offline-btn {
    color: #80898A;
    background: #F2F3F3;
    border: 1px solid #F2F3F3;
    pointer-events: none;
    padding: 16px;
}

section.medic-list .box .action-buttons .call-now-btn svg {
    margin-right: 13px;
}

.modal-specialization .searchbar {
    background: #E5E7E8;
    border-radius: 12px;
    padding: 17px 20px;
    width: 100%;
    display: flex;
    align-items: center;
}

.modal-specialization .searchbar svg {
    margin-right: 10px;
    min-width: 20px;
}

.modal-specialization .searchbar input {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #80898A;
    width: 100%;
    background: none;
    border: 0;
    padding: 0;
    outline: none;
    box-shadow: none;
}

section.medic-list .filters-section .scroll-list {
    flex: 0 1 100%;
    display: flex;
    min-width: 240px;
}

section.medic-list .filters-section .scroll-list form {
    padding-left: 20px;
    padding-right: 20px;
    background: #FFFFFF;
    border-radius: 12px;
}

section.medic-list .filters-section-mobile .scroll-list {
    flex: 0 1 100%;
    display: flex;
    min-width: 240px;
}

section.medic-list .filters-section-mobile .scroll-list form {
    padding-left: 20px;
    padding-right: 20px;
    background: #FFFFFF;
    border-radius: 12px;
}

section.medic-list .scroll-list .custom-control {
    padding-top: 16px;
    padding-bottom: 15px;
    display: flex;
    border-bottom: 1px solid #F2F3F3;
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    color: #001315;
    cursor: pointer;
}

section.medic-list .scroll-list .custom-control:last-child {
    border-bottom: none;
}

section.medic-list .scroll-list .custom-control .name {
    flex: 1;
}

section.medic-list .scroll-list .custom-control:last-child {
    align-self: flex-end;
}

.border-top {
    padding-top: 12px;
    border-top: 1px solid #F2F3F3 !important;
}

.border-bottom {
    border-bottom: 1px solid #F2F3F3 !important;
}

section.medic-list .box .medic-card-header.find-medic-card-header .avatar {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    width: 176px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("shared/assets/images/doctors.png");
}

section.medic-list .box .find-medic-card-info {
    padding: 0px 100px 20px;
}

section.medic-list .box .find-medic-card-info-text {
    font-family: 'Rota';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: #001315;
}

section.medic-list .box .find-medic-card-info-text-details {
    font-family: 'Rota';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #001315;
}

section.medic-list .box .footer-box.find-medic-card-footer .action-buttons {
    padding: 0px 80px 0px;
}

section.medic-list .box .footer-box.find-medic-card-footer .action-buttons .call-now-btn {
    background: #FF6A39;
    border-color: #FF6A39;
}

section.medic-list .box .footer-box.find-medic-card-footer .find-medic-card-footer-text {
    padding: 12px 80px 0px;
}

section.medic-list .medic-list-doctors .searchbar-speciality {
    background: #FFFFFF;
    border-radius: 12px;
    flex: 1 1 100%;
    margin-bottom: 20px;
    border: 1px solid #FFFFFF;
}

section.medic-list .filters-section .searchbar-speciality {
    background: #FFFFFF;
    border-radius: 12px;
    flex: 1 1 100%;
    /*margin-bottom: 20px;*/
    /*border: 1px solid #FFFFFF;*/
}

section.medic-list .colpr10 {
    padding-right: 10px; ;
}

section.medic-list .medic-list-doctors .searchbar-speciality.searchbar-speciality-mobile,
section.medic-list .filters-section .searchbar-speciality.searchbar-speciality-mobile {
    background: #FFFFFF;
    border-radius: 12px;
    flex: 1 1 100%;
    margin-bottom: 20px;
}

section.medic-list .filters-section .search-speciality {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    color: #001315;
    box-sizing: border-box;
    border-radius: 12px;
    padding: 16px 20px 15px 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
    flex: 0 1 100%;
}

section.medic-list .filters-section .search-speciality .specialization-item {
    display: flex;
    flex: 1 1 100%;
}

section.medic-list .filters-section .search-speciality .specialization-item .name {
    padding-top: 2px;
    padding-left: 14px;
    flex: 1 1;
}

section.medic-list .filters-section .search-speciality .specialization-selected-name {
    flex: 1;
}

section.medic-list .medic-list-info .title {
    font-family: 'Rota';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: #001315;
    margin-top: 40px;
    margin-bottom: 20px;
    display: flex;
}

section.medic-list .medic-list-info .title .title-text {
    flex: 1;
    padding-top: 10px;
}

section.medic-list .medic-list-info .details {
    font-family: 'Rota';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    max-width: 800px;
    margin: auto;
    text-align: center;
    padding: 0 20px 20px;
}

section.medic-list .medic-list-doctors .details {
    font-family: 'Rota';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    max-width: 800px;
    margin: auto;
    text-align: justify;
}


section.medic-list button.call-now-btn:disabled {
    background-color: #ced4da !important;
    border: 2px solid #a2aeb9 !important;
}

/******************************/
section.medic-list .specializations-carousel .box {
    border-radius: 20px;
    padding: 10px 10px 10px 10px;
    margin-bottom: 20px;
}

section.medic-list .specializations-carousel .box .description {
    font-family: Rota, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 110%;
    color: #001315;
    margin: 10px 0;
    border-top: none !important;
}

section.medic-list .specializations-carousel .box .description2 {
    font-family: Rota, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 110%;
    color: #001315;
    margin-bottom: 10px;
}

section.medic-list .specializations-carousel .box .circle {
    border-radius: 100%;
    width: 50px;
    height: 50px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-family: Rota, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 110%;
    text-align: center;
    color: #FFFFFF;
}

section.medic-list .specializations-carousel .box .circle.one {
    background: #FF6A39;
}

section.medic-list .specializations-carousel .box .circle.two {
    background: #EDE939;
}

section.medic-list .specializations-carousel .box .circle.three {
    background: #00CFB4;
}

section.medic-list .specializations-carousel .box .circle.two span {
    color: #001315;
    height: 28px;
}

section.medic-list .specializations-carousel .box .circle.one span,
section.medic-list .specializations-carousel .box .circle.three span {
    color: #ffffff;
    height: 28px;
}

section.medic-list .specializations-carousel .box.box1 {
    background: #FFF0EB;
    border: 1px solid rgba(0, 19, 21, 0.05);
}

section.medic-list .specializations-carousel .box.box2 {
    background: #FDFDEB;
    border: 1px solid rgba(0, 19, 21, 0.05);
}

section.medic-list .specializations-carousel .box.box3 {
    background: #E5FAF7;
    border: 1px solid rgba(0, 19, 21, 0.05);
}

section.medic-list .specializations-carousel .box .app-store-icon {
    background-image: url("../../shared/assets/images/app-store-download-icon-black.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    width: 100%;
    max-width: 150px;
    height: 43px;
    display: inline-flex;
    cursor: pointer;
}

section.medic-list .specializations-carousel .box .google-play-icon {
    background-image: url("../../shared/assets/images/google-play-download-icon-black.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    width: 100%;
    max-width: 150px;
    height: 43px;
    display: inline-flex;
    cursor: pointer;
}

/******************************/

@media (max-width: 767px) {

    section.medic-list .medic-list-info .title {
        font-family: 'Rota';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
        text-align: center;
        color: #001315;
        margin-top: 10px;
        margin-bottom: 0px;
        display: flex;
    }

    .title-mt-0 {
        margin-top: 0px !important;
    }

    section.medic-list .how-it-works-wrapper-play-video-center {
        margin-bottom: 10px;
        font-family: 'Rota';
        font-style: normal;
        font-weight: 700;
    }

    section.medic-list .box .find-medic-card-info {
        padding: 0px 20px 20px;
    }

    section.medic-list .box .find-medic-card-info-text {
        font-size: 20px;
        line-height: 25px;
        text-align: left;
        color: #001315;
    }

    section.medic-list .box .find-medic-card-info-text-details {
        font-size: 18px;
        line-height: 22px;
        text-align: left;
    }

    section.medic-list .box .footer-box.find-medic-card-footer .action-buttons {
        padding: 0px 0px 0px;
    }

    section.medic-list .box .footer-box.find-medic-card-footer .find-medic-card-footer-text {
        padding: 12px 0px 0px;
    }

    section.medic-list .title-filters-section {
        flex-direction: column
    }

    section.medic-list .title-section {
        padding-left: 7px;
    }

    section.medic-list .filters-section {
        flex-direction: column;
        width: 100%;
    }

    section.medic-list .filters-section .searchbar {
        width: 100%
    }

    section.medic-list .filters-section .searchbar.active input {
        width: 100%;
        min-width: auto;
    }

    section.medic-list .filters-section .searchbar {
        margin-bottom: 10px;
    }

    section.medic-list .specializations-carousel .box {
        height: 100%;
        padding: 20px;
        padding-bottom: 0px;
    }

    section.medic-list .specializations-carousel .box .row {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    section.medic-list .specializations-carousel .box .row .col-12.justify-content-center {
        flex-grow: 1;
        flex-shrink: 0;
        justify-content: flex-start !important;
        margin-bottom: 0px;
    }

    .title-text-mt-0 {
        padding-top: 0px !important;
    }
}

section.medic-list .medic-list-info .details {
    font-family: 'Rota';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    max-width: 800px;
    margin: auto;
    text-align: justify;
    padding: 0 20px 20px;
}

section.medic-list button.call-now-btn:disabled {
    background-color: #ced4da !important;
    border: 2px solid #a2aeb9 !important;
}

section.medic-list .bussy-button {
    display: flex;
    justify-content: center;
}

.how-does-it-works-modal .modal-dialog {
    max-width: 800px;
}

.how-does-it-works-modal .modal-header{
    background-color: #FFFFFF;
    justify-content: center;
}

.how-does-it-works-modal .modal-header h4.modal-title {
    text-align: center;
}

.how-does-it-works-modal .modal-header .modal-title {
    font-family: Rota, serif;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 30px;
    color: #001315;
}

.how-does-it-works-modal .modal-body {
    display: flex;
    justify-content: space-between;
    background-color: #FFFFFF;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}

.how-does-it-works-modal .colv {
    display: flex;
    flex-direction: column;
}

.how-does-it-works-modal .colh {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

/*.how-does-it-works-modal .modal-body .youtube-btn {*/
/*    width: 100%;*/
/*    text-align: center;*/
/*    font-family: Rota, sans-serif;*/
/*    font-style: normal;*/
/*    font-weight: bold;*/
/*    font-size: 32px;*/
/*    line-height: 110%;*/
/*    cursor: pointer;*/
/*    margin-bottom: 30px;*/

/*}*/

.how-does-it-works-modal .box {
    border-radius: 20px;
    padding: 10px 10px 10px 10px;
    margin-bottom: 20px;
    flex: 0 0 32.5%;
}

.how-does-it-works-modal .box .description {
    font-family: Rota, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 110%;
    color: #001315;
    margin: 10px 0;
}

.how-does-it-works-modal .box .description2 {
    font-family: Rota, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 110%;
    color: #001315;
    margin-bottom: 10px;
}

.how-does-it-works-modal .box .circle {
    border-radius: 100%;
    width: 50px;
    height: 50px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-family: Rota, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 110%;
    text-align: center;
    color: #FFFFFF;
}

.how-does-it-works-modal .box .circle.one {
    background: #FF6A39;
}

.how-does-it-works-modal .box .circle.two {
    background: #EDE939;
}

.how-does-it-works-modal .box .circle.three {
    background: #00CFB4;
}

.how-does-it-works-modal .box .circle.two span {
    color: #001315;
    height: 28px;
}

.how-does-it-works-modal .box .circle.one span,
.how-does-it-works-modal .box .circle.three span {
    color: #ffffff;
    height: 28px;
}

.how-does-it-works-modal .box.box1 {
    background: #FFF0EB;
    border: 1px solid rgba(0, 19, 21, 0.05);
}

.how-does-it-works-modal .box.box2 {
    background: #FDFDEB;
    border: 1px solid rgba(0, 19, 21, 0.05);
}

.how-does-it-works-modal .box.box3 {
    background: #E5FAF7;
    border: 1px solid rgba(0, 19, 21, 0.05);
}

.how-does-it-works-modal .box .app-store-icon {
    background-image: url("../../shared/assets/images/app-store-download-icon-black.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    width: 100%;
    max-width: 150px;
    height: 43px;
    display: inline-flex;
    cursor: pointer;
}

.how-does-it-works-modal .box .google-play-icon {
    background-image: url("../../shared/assets/images/google-play-download-icon-black.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    width: 100%;
    max-width: 150px;
    height: 43px;
    display: inline-flex;
    cursor: pointer;
}

.how-does-it-works-modal .box-img {
    margin-top: auto;
    margin-bottom: 0px;
}

.how-does-it-works-modal .box .row {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 0;
    justify-content: flex-start !important;
    margin-bottom: 10px;
}

section.medic-list .specializations-carousel {
    padding-bottom: 100px;
}

section.medic-list .specializations-carousel .prev-slider {
    position: absolute;
    /*top: calc(50% - 18px);*/
    /*height: 35px;*/
    /*width: 35px;*/
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 0 5px 0 #0009;
    z-index: 10;
    cursor: pointer;
    font-size: 10px;
    opacity: 0.6;
    transition: opacity 0.25s;
    /*left: 5px;*/
    right: initial;
    align-items: center;
    justify-content: center;
    display: flex;
    bottom: -80px;
    top: auto;
    left: 30%;
    height: 56px;
    width: 56px;
}

section.medic-list .specializations-carousel .next-slider {
    position: absolute;
    /*top: calc(50% - 18px);*/
    /*height: 35px;*/
    /*width: 35px;*/
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 0 5px 0 #0009;
    z-index: 10;
    cursor: pointer;
    font-size: 10px;
    opacity: 0.6;
    -webkit-transition: opacity 0.25s;
    transition: opacity 0.25s;
    left: initial;
    /*right: 5px;*/
    align-items: center;
    justify-content: center;
    display: flex;
    bottom: -80px;
    top: auto;
    right: 30%;
    height: 56px;
    width: 56px;
}

section.medic-list .specializations-carousel .next-slider {
}

section.medic-list .specializations-carousel .prev-slider {
}

/*section.medic-list .specializations-carousel .box .circle {*/
/*    border-radius: 100%;*/
/*    width: 50px;*/
/*    height: 50px;*/
/*    display: inline-flex;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*    font-family: Rota, sans-serif;*/
/*    font-style: normal;*/
/*    font-weight: 700;*/
/*    font-size: 30px;*/
/*    line-height: 110%;*/
/*    text-align: center;*/
/*    color: #FFFFFF;*/
/*}*/

/*section.medic-list .specializations-carousel .box .circle.one {*/
/*    background: #FF6A39;*/
/*}*/

/*section.medic-list .specializations-carousel .box .circle.two {*/
/*    background: #EDE939;*/
/*}*/

/*section.medic-list .specializations-carousel .box .circle.three {*/
/*    background: #00CFB4;*/
/*}*/

/*section.medic-list .specializations-carousel .box {*/
/*    border-radius: 20px;*/
/*    padding: 10px 10px 10px 10px;*/
/*    margin-bottom: 20px;*/
/*}*/

/*section.medic-list .specializations-carousel .box .circle.two span {*/
/*    color: #001315;*/
/*    height: 28px;*/
/*}*/

/*section.medic-list .specializations-carousel .box .circle.one span,*/
/*section.medic-list .specializations-carousel .box .circle.three span {*/
/*    color: #ffffff;*/
/*    height: 28px;*/
/*}*/

/*section.medic-list .specializations-carousel .box .description {*/
/*    font-family: Rota, sans-serif;*/
/*    font-style: normal;*/
/*    font-weight: 700;*/
/*    font-size: 30px;*/
/*    line-height: 110%;*/
/*    color: #001315;*/
/*    margin: 10px 0;*/
/*    border-top: none !important;*/
/*}*/

/*section.medic-list .specializations-carousel .description2 {*/
/*    font-family: Rota, sans-serif;*/
/*    font-style: normal;*/
/*    font-weight: 500;*/
/*    font-size: 30px;*/
/*    line-height: 110%;*/
/*    color: #001315;*/
/*    margin-bottom: 10px;*/
/*}*/

section.medic-list .box.box1 {
    background: #FFF0EB;
    border: 1px solid rgba(0, 19, 21, 0.05);
}

section.medic-list .box.box2 {
    background: #FDFDEB;
    border: 1px solid rgba(0, 19, 21, 0.05);
}

section.medic-list .box.box3 {
    background: #E5FAF7;
    border: 1px solid rgba(0, 19, 21, 0.05);
}

section.medic-list .how-it-works-title-mobile {
    font-family: Rota, sans-serif;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    color: #001315;
    text-align: center;
    padding-top: 10px;
}

section.medic-list .sort-container {
    display: flex;
    background: #FFFFFF;
    border-radius: 12px;
    padding: 8px 10px 8px 20px;
}

section.medic-list .sort-container .svg-container {
    display: flex;
    align-items: center;
}
section.medic-list .sort-container .svg-container svg {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
}

section.medic-list .sort-container select {
    height: 40px !important;
    margin-bottom: 0px;
}

section.medic-list .sort-container .react-select-container {
    width: 100%;
    cursor: pointer;
}

section.medic-list .sort-container .react-select__control, section.medic-list .sort-container react-select__dropdown-indicator {
    cursor: pointer !important;
}

section.medic-list .sort-radio {
    padding: 8px 20px;
}

section.medic-list .sort-radio span {
    padding-left: 10px;
    padding-bottom: 2px;
}