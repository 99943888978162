.about-us-page.gradient-about-us {
    background-image: url("../assets/images/gradient-about-us.png");
    background-size: contain;
    background-position: center top;
    background-repeat: no-repeat;
    width: 100%;
}
.about-us-page section.title {
    display: flex;
    justify-content: center;
}
.about-us-page section.title h1 {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 110%;
    text-align: center;
    color: #001315;
    margin-top: 60px;
    margin-bottom: 60px;
}
.about-us-page section.about-us > p {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: #001315;
    margin-bottom: 30px;
    padding: 0 40px;
}
.about-us-page section.about-us .about-us-image {
    display: flex;
    justify-content: center;
    margin-top: 60px;
}
.about-us-page section.about-us .about-us-image img {
    max-width: 85%;
}

.about-us-page section.about-us .box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 50px 40px;
    margin-bottom: 20px;
}
.about-us-page section.about-us .box .text-box {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
}
.about-us-page section.about-us .box h3 {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 110%;
    color: #001315;
    margin-bottom: 10px;
}
.about-us-page section.about-us .box p {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
    text-align: left;
    margin: 0;
}
.about-us-page section.about-us .box.viziune {
    background-color: #FF6A39;
    width: 171px;
    height: 171px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    padding: 0;
    margin: 0;
    margin-left: auto;
}
.about-us-page section.about-us .box.misiuni {
    background-color: #FFC107;
    width: 171px;
    height: 171px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    padding: 0;
    margin: 0;
    margin-left: auto;
}
.about-us-page section.about-us .box.valori {
    background-color: #00CFB4;
    width: 171px;
    height: 171px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    padding: 0;
    margin: 0;
    margin-left: auto;
}

.about-us-page section.download-app {
    background-color: #FFFFFF;
    border-radius: 12px;
    padding: 6px;
    margin-bottom: 30px;
    margin-top: 60px;
    display: flex;
}
.about-us-page section.download-app .column {
    display: inline-flex;
    flex-direction: column;
    width: 50%;
}
.about-us-page section.download-app .column h3 {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 110%;
    color: #001315;
    margin-top: 34px;
    margin-left: 34px;
    margin-bottom: 60px;
}
.about-us-page section.download-app .column .apps-button {
    margin-bottom: 38px;
    margin-left: 34px;
}
.about-us-page section.download-app .app-store-icon {
    background-image: url("../assets/images/app-store-icon-black.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    width: 143px;
    height: 49px;
    display: inline-flex;
    margin-right: 14px;
    cursor: pointer;
}
.about-us-page section.download-app .google-play-icon {
    background-image: url("../assets/images/google-play-icon-black.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    width: 159px;
    height: 49px;
    display: inline-flex;
    cursor: pointer;
}
.about-us-page section.download-app.patient .column:last-child {
    background: #FF6A39;
    border-radius: 8px;
    align-items: center;
    justify-content: flex-end;
}
.about-us-page section.download-app .column img {
    max-width: 316px;
}

@media (max-width: 1024px){
    .about-us-page section.title h1 {
        font-size: 36px;
        margin-top: 40px;
        margin-bottom: 40px;
        max-width: none;
    }
    .about-us-page section.about-us p {
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 25px;
    }
    .about-us-page section.about-us .box h3 {
        font-size: 26px;
        text-align: center;
    }
    .about-us-page section.about-us .box p {
        font-size: 16px;
        line-height: 20px;
        text-align: center;
    }
    .about-us-page section.about-us .about-us-image img {
        max-width: 100%;
    }
    .about-us-page section.about-us > .row > .colp10 > .box {
        min-height: auto;
        padding: 30px 20px;
        margin-bottom: 20px;
    }
    .about-us-page section.about-us .box.valori,
    .about-us-page section.about-us .box.misiuni,
    .about-us-page section.about-us .box.viziune {
        margin: auto;
        margin-top: 20px;
        width: 120px;
        height: 120px;
    }
    .about-us-page section.benefits h2 {
        font-size: 36px;
        margin-bottom: 25px;
    }
    .about-us-page section.benefits .box {
        padding: 20px;
    }
    .about-us-page section.benefits .box p {
        font-size: 22px;
    }
    .about-us-page section.faq h3 {
        font-size: 36px;
        margin-bottom: 25px;
    }
    .about-us-page section.faq .card-header .title {
        font-size: 20px;
        line-height: 26px;
    }
    .about-us-page section.faq .card-body p {
        font-size: 16px;
        line-height: 20px;
    }
    .about-us-page section.download-app {
        padding: 15px 15px 0 15px;
    }
    .about-us-page section.download-app .column h3 {
        font-size: 26px;
        margin-top: 10px;
        margin-left: 0;
        margin-bottom: 30px;
    }
    .about-us-page section.download-app .column .apps-button {
        margin-bottom: 30px;
        margin-left: 0;
    }
    .about-us-page section.download-app {
        flex-direction: column;
    }
    .about-us-page section.download-app .column {
        width: 100%;
    }
    .about-us-page section.download-app.patient .column:last-child {
        margin-left: -15px;
        margin-right: -15px;
        width: calc(100% + 30px);
        padding-top: 50px;
    }
}