.skeleton-content {
    background-color: #e5e7e8;
    position: relative;
    overflow: hidden;
}

.skeleton-content::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0;
    height: 100%;
    box-shadow: 0 0 80px 20px #fff;
    animation: skeletonSlide 0.6s infinite ease-in-out;
}

@keyframes skeletonSlide {
    from {
        left: -50px;
    }
    to {
        left: calc(100% + 50px);
    }
}

.skeleton-content.circle {
    border-radius: 50%;
}

.skeleton-content.rectangle {
    border-radius: 12px;
    margin-bottom: 2px;
}
