section.account .account-availability-schedule .add-exceptions-btn {
    min-height: 46px;
    padding: 8px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #E5E7E8;
}
section.account .account-availability-schedule .add-exceptions-btn svg {
    margin-right: 10px;
}
section.account .account-availability-schedule .day-box {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #E5E7E8;
}
section.account .account-availability-schedule .day-box .day-title {
    display: flex;
    align-items: center;
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #80898A;
}
section.account .account-availability-schedule .day-box .day-title .custom-control-input + .custom-control-label::before {
    display: none;
}
section.account .account-availability-schedule .day-box .day-title .custom-control-input:checked + .custom-control-label::before {
    display: block;
}
section.account .account-availability-schedule .day-box .day-title .custom-control-input + label {
    font-family: 'Rota';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #80898A;
    height: 24px;
}
section.account .account-availability-schedule .day-box .day-title .custom-control-input:checked + label {
    margin-left: 35px;
}
section.account .account-availability-schedule .day-box .add-interval-btn {
    min-height: 46px;
    padding: 8px 20px;
    color: #00CFB4;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    border: 1px solid #E5E7E8;
}
section.account .account-availability-schedule .day-box .add-interval-btn svg {
    margin-right: 10px;
}
section.account .account-availability-schedule .day-box .interval-box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 8px 20px;
    display: flex;
    align-items: center;
    height: 56px;
    margin-top: 10px;
    border: 1px solid #E5E7E8;
}
section.account .account-availability-schedule .day-box .interval-box .info {
    display: flex;
    flex-direction: column;
}
section.account .account-availability-schedule .day-box .interval-box .exception {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    color: #EB3300;
    margin-bottom: 5px;
}
section.account .account-availability-schedule .day-box .interval-box .interval {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 18px;
    color: #001315;
}
section.account .account-availability-schedule .day-box .interval-box .actions {
    display: inline-flex;
    margin-left: auto;
}
section.account .account-availability-schedule .day-box .interval-box .actions button {
    border: 0;
    background: none;
    padding: 0;
    margin-left: 24px;
}

.modal-add-interval .modal-content,
.modal-add-exception .modal-content {
    background-color: #FFF;
}
.modal-add-interval .modal-dialog,
.modal-add-exception .modal-dialog {
    max-width: 440px;
}
.modal-add-interval .subtitle,
.modal-add-exception .subtitle {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #80898A;
    margin-top: 20px;
    margin-bottom: 10px;
}
.modal-add-exception .calendar .subtitle {
    margin-top: 0;
}
.modal-add-exception .calendar .react-calendar {
    width: 100%;
    background: #F2F3F3;
    border-radius: 12px;
    border: 0;
}
.modal-add-exception .calendar .react-calendar__navigation {
    height: auto;
    margin-bottom: 0;
    padding-top: 20px;
}
.modal-add-exception .calendar .react-calendar__month-view {
    padding-top: 23px;
    padding-bottom: 14px;
}
.modal-add-exception .calendar .react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth,
.modal-add-exception .calendar .react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend.react-calendar__month-view__days__day--neighboringMonth {
    pointer-events: none!important;
    font-size: 0;
}
.modal-add-exception .calendar .react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend:disabled,
.modal-add-exception .calendar .react-calendar__tile.react-calendar__month-view__days__day:disabled {
    color: #BFC4C5;
}
.modal-add-exception .calendar .react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend {
    color: #001315;
}
.modal-add-exception .calendar .react-calendar__tile.react-calendar__month-view__days__day {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #001315;
    padding: 0;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 5px;
    background: none;
    flex-basis: auto!important;
}
.modal-add-exception .calendar .react-calendar__tile.react-calendar__month-view__days__day:hover {
    background: none;
}
.modal-add-exception .calendar .react-calendar__tile.react-calendar__month-view__days__day.react-calendar__tile--now {
    background: none;
    border: 2px solid #00CFB4;
    border-radius: 100%;
}
.modal-add-exception .calendar .react-calendar__tile.react-calendar__month-view__days__day.react-calendar__tile--active {
    color: #FFFFFF;
    background: #00CFB4;
    border-radius: 100%;
}
.modal-add-exception .calendar .react-calendar__navigation__arrow.react-calendar__navigation__prev2-button,
.modal-add-exception .calendar .react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
    display: none!important;
}
.modal-add-exception .calendar .react-calendar__navigation__arrow {
    background: none!important;
}
.modal-add-exception .calendar .react-calendar__navigation__label {
    background: none!important;
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #001315;
    text-transform: capitalize;
}
.modal-add-exception .calendar .react-calendar__viewContainer {
    padding: 0 20px;
}
.modal-add-exception .calendar .react-calendar__month-view__weekdays__weekday abbr {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #001315;
    text-transform: capitalize;
    text-decoration: none!important;
    cursor: auto;
}
.modal-add-exception .calendar .react-calendar__month-view__weekdays {
    border-bottom: 1px solid #F2F3F3;
    margin-bottom: 8px;
    padding-bottom: 12px;
}
.modal-add-exception .calendar .react-calendar__month-view__weekdays,
.modal-add-exception .calendar .react-calendar__month-view__days {
    justify-content: center;
}
.modal-add-exception .calendar .react-calendar__month-view__weekdays__weekday {
    padding: 0;
    flex-basis: auto!important;
    width: 40px;
    margin: 0 5px;
}
.modal-add-exception .free-day .switch-box {
    margin-top: 10px;
}
.modal-add-exception .free-day .switch-box .list-group-item {
    background: #F2F3F3;
    border-radius: 12px;
    border: 0;
    padding: 12px 20px;
}
.modal-add-interval .interval-hour,
.modal-add-exception .interval-hour {
    display: flex;
    align-items: center;
}
.modal-add-interval .interval-hour select.custom-select,
.modal-add-exception .interval-hour select.custom-select {
    padding: 10px 10px;
    text-align: center;
    margin-bottom: 0;
    background-image: none!important;
}
.modal-add-interval .interval-hour span,
.modal-add-exception .interval-hour span {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #80898A;
    padding: 0 4px;
}
.modal-add-interval .day {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #001315;
}