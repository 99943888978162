.link-redirect {
    background-color: #F2F3F3;
}
.link-redirect section {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.link-redirect section img {
    margin-bottom: 55px;
}
.link-redirect section p {
    font-family: 'Rota';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 110%;
    text-align: center;
    color: #001315;
    margin-bottom: 10px;
}
.link-redirect section .apps-button a {
    display: inline-flex;
}
.link-redirect section .apps-button .app-store-icon {
    background-image: url("../assets/images/app-store-icon-black.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    width: 143px;
    height: 49px;
    display: inline-flex;
    margin-left: 7px;
    margin-right: 7px;
    cursor: pointer;
}
.link-redirect section .apps-button .google-play-icon {
    background-image: url("../assets/images/google-play-icon-black.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    width: 159px;
    height: 49px;
    display: inline-flex;
    margin-left: 7px;
    margin-right: 7px;
    cursor: pointer;
}

@media (max-width: 400px) {
    .link-redirect section .apps-button {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .link-redirect section .apps-button a:first-child {
        margin-bottom: 10px;
    }
}