section.appointments .small-container {
    max-width: 400px;
    margin: auto;
}
section.appointments .title-section {
    margin-top: 40px;
    margin-bottom: 40px;
    position: relative;
    z-index: 10;
}
section.appointments .title-section h1 {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 110%;
    text-align: center;
    color: #001315;
    margin: 0;
    position: relative;
    z-index: 10;
}

section.appointments .nav.nav-tabs {
    border: 0;
    display: flex;
    margin-left: -5px;
    margin-right: -5px;
    margin-bottom: 15px;
}
section.appointments .nav.nav-tabs .nav-item {
    flex: 1 1 auto;
    padding: 0 5px;
}
section.appointments .nav.nav-tabs .nav-item .nav-link {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #001315;
    background: #E5E7E8;
    border-radius: 10px;
    padding: 8px 20px;
    border: 0;
    cursor: pointer;
}
section.appointments .nav.nav-tabs .nav-item .nav-link.active {
    background: #FFFFFF;
}

section.appointments .subtitle {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #80898A;
    margin-top: 20px;
    margin-bottom: 10px;
    position: relative;
}
section.appointments .subtitle .options {
    position: absolute;
    top: -3px;
    right: 0;
    cursor: pointer;
}

section.appointments .schedule-box {
    display: flex;
    margin-bottom: 10px;
}
section.appointments .schedule-box .hour-box {
    background: #FFFFFF;
    border-radius: 12px;
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #001315;
    margin-right: 10px;
    width: 66px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
section.appointments .schedule-box .patient-box {
    display: flex;
    flex-direction: column;
    text-decoration: none!important;
    background: #FFFFFF;
    border-radius: 12px;
    padding: 14px 20px;
    width: calc(100% - 66px);
}

section.appointments .schedule-box .patient-box .avatar-section {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}
section.appointments .schedule-box .patient-box .avatar-section .user-image {
    margin: 0!important;
}
section.appointments .schedule-box .patient-box .avatar-section .avatar-picture {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    width: 36px;
    height: 36px;
    border-radius: 100%;
    border: 1px solid rgba(0, 19, 21, 0.1);
    position: relative;
}
section.appointments .schedule-box .patient-box .avatar-section .user-text {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Rota';
    font-style: normal;
    font-weight: 700;
    font-size: 16px!important;
    line-height: 20px!important;
    text-align: center;
    color: #80898A!important;
    width: 36px;
    height: 36px;
    border-radius: 100%;
    border: 1px solid rgba(0, 19, 21, 0.1);
}
section.appointments .schedule-box .patient-box .avatar-section svg {
    margin-left: auto;
    margin-right: -8px;
}
section.appointments .schedule-box .patient-box .fullName {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
}
section.appointments .schedule-box .patient-box .year-files {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #80898A;
    display: flex;
    align-items: center;
}
section.appointments .schedule-box .patient-box .requested-specialization {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #001315;
}
section.appointments .schedule-box .patient-box .year-files svg {
    margin-right: 4px;
    margin-left: 4px;
}
section.appointments .start-consultation-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF!important;
    text-decoration: none!important;
}
section.appointments .start-consultation-btn.not-ready {
    padding: 0;
    pointer-events: none;
    background: #E5E7E8!important;
    border-color: #E5E7E8!important;
    color: #80898A!important;
}
section.appointments .start-consultation-btn .timer p {
    margin-bottom: 0;
    margin-left: 5px;
    min-width: 42.5px;
}
section.appointments .start-consultation-btn svg {
    margin-right: 10px;
}
section.appointments .empty-container {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #80898A;
    margin-top: 60px;
}

.modal-options-appointments .modal-header {
    align-items: flex-start;
}
.modal-options-appointments .modal-header .modal-title .data {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #80898A;
}

.modal-options-appointments .files .subtitle {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #80898A;
    margin-top: 20px;
    margin-bottom: 10px;
}
.modal-options-appointments .files .files-box {
    background: #FFFFFF;
    border-radius: 12px;
}
.modal-options-appointments .files .files-box label {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 20px;
    margin: 0;
    cursor: pointer;
}
.modal-options-appointments .files .files-box label svg {
    margin-right: 10px;
}
.modal-options-appointments .files .files-box input {
    display: none;
}
.modal-options-appointments .files .uploaded-box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 15px 20px;
    margin-bottom: 10px;
}
.modal-options-appointments .files .uploaded-box:last-child {
    margin-bottom: 0;
}
.modal-options-appointments .files .uploaded-file {
    display: flex;
    align-items: center;
    position: relative;
}
.modal-options-appointments .files .uploaded-file .circle.new-file {
    background: #FF6A39;
    border-radius: 10px;
    width: 10px;
    height: 10px;
    position: absolute;
    top: auto;
    bottom: auto;
    left: -13px;
}
.modal-options-appointments .files .uploaded-file .file-name {
    display: inline-flex;
    align-items: center;
    max-width: 295px;
    margin-right: auto;
}
.modal-options-appointments .files .uploaded-file .file-name span {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.modal-options-appointments .files .uploaded-file .file-name svg {
    margin-right: 16px;
    min-width: 24px;
}
.modal-options-appointments .files .uploaded-file button {
    border: 0;
    padding: 0;
    margin: 0;
    background: none;
}
.modal-options-appointments .subtitle {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #80898A;
    margin-top: 20px;
    margin-bottom: 10px;
}
.modal-options-appointments .subtitle:first-child {
    margin-top: 0;
}

.modal-options-appointments .cancel-all-appointments-btn {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #EB3300;
    width: 100%;
    display: flex;
    align-items: center;
    border: 0;
    padding: 0;
    background: none;
}
.modal-options-appointments .cancel-all-appointments-btn svg:first-child {
    margin-right: 16px;
}
.modal-options-appointments .cancel-all-appointments-btn svg:last-child {
    margin-left: auto;
    margin-right: -8px;
}
