.page-not-found-404 header {
    padding-top: 60px;
    display: flex;
    justify-content: center;
}
.page-not-found-404 .animation img {
    position: relative;
    top: -30px;
}
.page-not-found-404 .animation {
    font-family: 'Rota';
    font-style: normal;
    font-weight: 100;
    font-size: 160px;
    line-height: 100px;
    text-align: center;
    color: #001315;
}
.page-not-found-404 h1 {
    font-family: 'Rota';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: #001315;
    margin-top: 10px;
    margin-bottom: 20px;
}
.page-not-found-404 button.white-button {
    margin-top: 10px;
}
.page-not-found-404 section {
    max-width: 400px;
    margin: auto;
}