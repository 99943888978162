section.mlm .title-section {
    margin-top: 40px;
    margin-bottom: 40px;
}

section.mlm .title-section h1 {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 110%;
    text-align: center;
    color: #001315;
    margin: 0;
}

section.mlm .small-container {
    max-width: 400px;
    margin: auto;
}

section.mlm .nav.nav-tabs {
    border: 0;
    display: flex;
    margin-left: -5px;
    margin-right: -5px;
    margin-bottom: 30px;
}

section.mlm .nav.nav-tabs .nav-item {
    flex: 1 1 auto;
    padding: 0 5px;
}

section.mlm .nav.nav-tabs .nav-item .nav-link {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #001315;
    background: #E5E7E8;
    border-radius: 10px;
    padding: 8px 20px;
    border: 0;
    cursor: pointer;
}

section.mlm .nav.nav-tabs .nav-item:first-child .nav-link.active {
    background: #FFFFFF;
    color: #FF6A39;
}

section.mlm .nav.nav-tabs .nav-item:last-child .nav-link.active {
    background: #FFFFFF;
    color: #00CFB4;
}

section.mlm .box-invite {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 20px;
}

section.mlm .box-invite h2 {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    margin-bottom: 10px;
}

section.mlm #mlm-recommend-tabs-menu-tabpane-patient .box-invite h2 {
    color: #FF6A39;
}

section.mlm #mlm-recommend-tabs-menu-tabpane-medic .box-invite h2 {
    color: #00CFB4;
}

section.mlm .box-invite p {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #001315;
    margin-bottom: 10px;
}

section.mlm .subtitle {
    font-family: Rota;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #80898A;
    margin-bottom: 3px;
}

section.mlm .box-invitation {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 18px 20px;
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #001315;
    margin-top: 10px;
}

section.mlm .empty-section {
    background: #FFFFFF;
    border-radius: 12px;
    font-family: 'Rota';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #80898A;
    padding: 18px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

.doctor-app section.mlm #mlm-recommend-tabs-menu-tabpane-patient input.form-control:focus + label {
    color: #FF6A39 !important;
}

section.mlm form .phone-number-with-prefix .PhoneInput {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
}

section.mlm form .phone-number-with-prefix select.PhoneInputCountrySelect {
    width: 92px
}

section.mlm form .phone-number-with-prefix {
    position: relative;
}

section.mlm form .phone-number-with-prefix input {
    width: calc(100% - 105px - 10px);
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
    background: #E5E7E8;
    border: 2px solid #E5E7E8;
    border-radius: 12px;
    padding: 19px 20px;
    margin-bottom: 10px;
    height: 56px !important;
    transition: all 0.2s ease-out;
}

section.mlm #mlm-recommend-tabs-menu-tabpane-patient form .phone-number-with-prefix .PhoneInput--focus + label {
    color: #FF6A39;
}

section.mlm #mlm-recommend-tabs-menu-tabpane-doctor form .phone-number-with-prefix .PhoneInput--focus + label {
    color: #00CFB4;
}

section.mlm form .phone-number-with-prefix .PhoneInput.has-value input {
    padding: 28px 18px 10px 18px;
}

section.mlm form .phone-number-with-prefix .PhoneInput--focus input {
    padding: 28px 18px 10px 18px;
    border: 2px solid #E5E7E8 !important;
    background: #FFFFFF;
    outline: none !important;
    box-shadow: none !important;
}

section.mlm form .phone-number-with-prefix .PhoneInput + label {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #80898A;
    width: auto;
    padding-left: 20px;
    position: absolute;
    top: 18px;
    transition: all 0.2s ease-out;
    left: 102px;
}

section.mlm form .phone-number-with-prefix .PhoneInput.PhoneInput--focus + label {
    font-size: 14px;
    line-height: 17px;
    top: 10px;
}

section.mlm form .phone-number-with-prefix .PhoneInput.has-value + label {
    font-size: 14px;
    line-height: 17px;
    color: #80898A;
    top: 10px;
}

section.mlm form .phone-number-with-prefix .PhoneInputCountrySelectArrow {
    display: none !important;
}

section.mlm form .phone-number-with-prefix .PhoneInputCountry {
    margin-right: 10px;
}

section.mlm form .phone-number-with-prefix .PhoneInputCountryIcon {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
    background-color: #E5E7E8;
    background-image: url('shared/assets/icons/select-arrow-custom-icon.svg');
    background-repeat: no-repeat;
    background-position: right 7px center;
    background-size: 24px 24px;
    border-radius: 12px;
    padding: 16px 44px 16px 14px;
    margin-bottom: 10px;
    height: 56px !important;
    width: 100%;
    border: 2px solid #E5E7E8 !important;
    outline: none !important;
    box-shadow: none !important;
    appearance: none;
}

section.mlm button.white-button {
    color: #00CFB4;
    border: 1px solid #00CFB4;
}

.font-orange {
    color: #FF6A39;
}

.font-green {
    color: #00CFB4;
}

section.mlm button.white-button.white-button-orange {
    color: #FF6A39;
    border: 1px solid #FF6A39;
}

section.mlm button.white-button.white-button-green {
    color: #00CFB4;
    border: 1px solid #00CFB4;
}

section.mlm .referral-invitation {
    display: flex;
    align-items: center;
    position: relative;
    margin: 15px 20px 0px 20px;
    text-decoration: none !important;
}

section.mlm .referral-invitation .info {
    display: inline-flex;
    flex-direction: column;
}

section.mlm .referral-invitation .info .name {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #001315 !important;
    margin-bottom: 2px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 250px;
}

section.mlm .referral-invitation .info .data {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #80898A !important;
}

section.mlm .referral-invitation .custom-icon {
    margin-right: 16px;
    display: inline-flex;
}

section.mlm .text {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
    margin-bottom: 4px;
}

section.mlm .no-arrow button.accordion-button::after {
    visibility: hidden;
}
