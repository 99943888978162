@media (max-width: 1024px) {
    section.dashboard .title-section {
        margin-top: 20px !important;
        margin-bottom: 0 !important;
    }

    section.dashboard .title-section h1 {
        font-size: 30px !important;
        text-align: center !important;
    }

    section.wallet .title-section .small-title-text,
    section.dashboard .title-section .small-title-text {
        text-align: left !important;
    }

    section.dashboard .income-received-box {
        margin-top: 10px !important;
    }

    section.static-pages .title-section,
    section.account-profile .title-section,
    section.account .title-section,
    section.rates .title-section,
    section.availability-schedule .title-section,
    section.notifications .title-section,
    section.wallet .title-section,
    section.consultation-details .title-section,
    section.appointments .title-section {
        margin-top: 20px !important;
        margin-bottom: 10px !important;
    }

    section.static-pages .title-section h1,
    section.account-profile .title-section h1,
    section.account .title-section h1,
    section.rates .title-section h2,
    section.availability-schedule .title-section h2,
    section.notifications .title-section h1,
    section.wallet .title-section h1,
    section.consultation-details .title-section h1,
    section.appointments .title-section h1 {
        font-size: 30px !important;
        text-align: center !important;
    }

    section.account-profile .title-section .back-to-account {
        position: relative !important;
    }

    /*section.account .menu-box,*/
    section.account .content-box {
        /*width: 100% !important;*/
    }

    .modal-add-exception .calendar .react-calendar__viewContainer {
        padding: 0 15px !important;
    }

    .modal-add-exception .calendar .react-calendar__month-view__weekdays__weekday {
        width: 30px !important;
        margin: 0 4px !important;
    }

    .modal-add-exception .calendar .react-calendar__tile.react-calendar__month-view__days__day {
        width: 30px !important;
        height: 30px !important;
        margin: 0 4px !important;
    }

    section.account .tab-content {
        padding: 20px !important;
    }

    section.account .mobile-account {
        position: relative;
    }

    section.account .mobile-account .back-to {
        margin-top: -10px;
        font-family: 'Rota';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #001315;
        border: 0;
        background: none;
        cursor: pointer;
        display: inline-flex;
        align-items: center;
    }

    section.account .mobile-account .back-to svg {
        margin-right: 10px;
    }

    section.account .mobile-account .menu-box {
        display: block;
    }

    /*section.account .content-box {*/
    /*    display: block;*/
    /*}*/
    section.account .mobile-account .content-box {
        display: flex;
        width: 100%;
    }

    section.account .content-box .tab-content {
        min-height: auto !important;
    }

    section.account .mobile-account .content-box .tab-content {
        width: 100%;
    }

    section.video-call .small-screens {
        margin-bottom: 15px;
    }

    section.video-call .header-top {
        flex-direction: column;
    }

    section.video-call .header-top .patient {
        margin-bottom: 10px;
    }

    section.video-call .video-container .medic-video {
        min-height: 270px !important;
    }
}
