section.account .account-settings .language-app .box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 17px 20px;
    margin-bottom: 10px;
    border: 1px solid #E5E7E8;
}
section.account .account-settings .terms-communications .box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 17px 20px;
    margin-bottom: 10px;
    border: 1px solid #E5E7E8;
}
section.account .account-settings .language-app .box:last-child,
section.account .account-settings .terms-communications .box:last-child {
    margin-bottom: 0;
}