section.account .account-assistance .contact .call-us-box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 20px;
}
section.account .account-assistance .contact .call-us-box .title {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
    margin-bottom: 2px;
}
section.account .account-assistance .contact .call-us-box .schedule {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #80898A;
    margin-bottom: 15px;
}
section.account .account-assistance .contact .call-us-box a.orange-button {
    text-decoration: none!important;
    display: flex;
    align-items: center;
    min-height: auto;
    padding: 11px 12px 11px 18px;
    color: #FFFFFF!important;
    height: 46px;
}
section.account .account-assistance .contact .call-us-box a.orange-button svg {
    margin-left: auto;
}
section.account .account-assistance .contact button.white-button {
    display: flex;
    align-items: center;
    margin-top: 10px;
    padding: 14px 12px 14px 20px;
    border: 1px solid #E5E7E8;
    font-weight: 700;
}
section.account .account-assistance .contact button.white-button svg {
    margin-left: auto;
}

section.account .account-assistance .information a.white-button {
    display: flex;
    align-items: center;
    text-decoration: none!important;
    min-height: auto;
    padding: 11px 12px 11px 18px;
    color: #001315!important;
    height: 46px;
    margin-bottom: 10px;
    border: 1px solid #E5E7E8;
    font-weight: 700;
}
section.account .account-assistance .information a.white-button svg {
    margin-left: auto;
}
section.account .account-assistance .information .list-group {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 0 20px;
    border: 1px solid #E5E7E8;
}
section.account .account-assistance .information .list-group-item {
    margin-top: 18px;
    border: 0;
    padding: 0 0 13px 0;
    border-radius: 0;
    border-bottom: 1px solid #F2F3F3;
}
section.account .account-assistance .information .list-group-item:last-child {
    border-bottom: 0;
    margin-bottom: 0;
    padding-top: 0;
}
section.account .account-assistance .information .list-group-item a {
    display: flex;
    align-items: center;
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #001315!important;
    text-decoration: none!important;
}
section.account .account-assistance .information .list-group-item a svg {
    margin-left: auto;
    margin-right: -8px;
}

section.account .account-assistance .contact .border-box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 20px;
    position: relative;
    margin-bottom: 10px;
    border: 1px solid #E5E7E8;
}

section.account .account-assistance .contact .border-box .subtitle {
    margin-top: 0;
}

.modal-contact .info-message {
    margin-bottom: 10px;
}
.modal-contact .modal-body .form-textarea textarea {
    margin-bottom: 0;
}