section.static-pages .title-section {
    margin-top: 40px;
    margin-bottom: 40px;
    position: relative;
}
section.static-pages .title-section .back-to-account {
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #001315;
    text-decoration: none!important;
    display: inline-flex;
    align-items: center;
}
section.static-pages .title-section .back-to-account svg {
    margin-right: 10px;
}
section.static-pages .title-section .small-title-text {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #001315;
}
section.static-pages .title-section h1 {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 110%;
    text-align: center;
    color: #001315;
    margin: 0;
}