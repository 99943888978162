section.account-profile .small-container {
    max-width: 400px;
    margin: auto;
}

section.account-profile .title-section {
    margin-top: 40px;
    margin-bottom: 40px;
    position: relative;
}

section.account-profile .title-section .back-to-account {
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    font-family: 'Rota';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #001315;
    text-decoration: none !important;
    display: inline-flex;
    align-items: center;
}

section.account-profile .title-section .back-to-account svg {
    margin-right: 10px;
}

section.account-profile .title-section .small-title-text {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #001315;
    margin-top: 8px;
}

section.account-profile .title-section h1 {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 110%;
    text-align: center;
    color: #001315;
    margin: 0;
}

section.account-profile .medic-small-box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 12px 20px;
    width: 100%;
    display: flex;
    margin-bottom: 20px;
    align-items: center;
}

section.account-profile .medic-small-box .avatar-section {
    margin-right: 20px;
}

section.account-profile .medic-small-box .avatar-section .user-image {
    margin: 0 !important;
}

section.account-profile .medic-small-box .avatar-section .avatar-picture {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    width: 36px;
    height: 36px;
    border-radius: 100%;
    border: 1px solid rgba(0, 19, 21, 0.1);
    position: relative;
    position: relative;
}

section.account-profile .medic-small-box .avatar-section .user-text {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Rota';
    font-style: normal;
    font-weight: 700;
    font-size: 16px !important;
    line-height: 20px !important;
    text-align: center;
    color: #80898A !important;
    width: 36px;
    height: 36px;
    border-radius: 100%;
    border: 1px solid rgba(0, 19, 21, 0.1);
}

section.account-profile .medic-small-box .name {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
}

section.account-profile .subtitle {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #80898A;
    margin-top: 20px;
    margin-bottom: 10px;
}

section.account-profile .nav.nav-tabs {
    border: 0;
    display: flex;
    margin-left: -5px;
    margin-right: -5px;
    margin-bottom: 30px;
}

section.account-profile .nav.nav-tabs .nav-item {
    flex: 1 1 auto;
    padding: 0 5px;
}

section.account-profile .nav.nav-tabs .nav-item .nav-link {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #001315;
    background: #E5E7E8;
    border-radius: 10px;
    padding: 8px 20px;
    border: 0;
    cursor: pointer;
}

section.account-profile .nav.nav-tabs .nav-item .nav-link.active {
    background: #FFFFFF;
}

section.account-profile .profile-picture {
    width: 100%;
}

section.account-profile .change-picture {
    display: flex;
    align-items: center;
    width: 100%;
}

section.account-profile .change-picture .files-box {
    background: #FFFFFF;
    border-radius: 12px;
    width: calc(100% - 46px);
}

section.account-profile .change-picture .files-box label {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 20px;
    margin: 0;
    cursor: pointer;
}

section.account-profile .change-picture .files-box label.add {
    color: #001315;
}

section.account-profile .change-picture .files-box label.modify {
    color: #00CFB4;
}

section.account-profile .change-picture .files-box input {
    display: none;
}

section.register-steps .change-picture .avatar-section,
section.account-profile .change-picture .avatar-section {
    margin-left: 10px;
}

section.register-steps .change-picture .avatar-section .user-image,
section.account-profile .change-picture .avatar-section .user-image {
    margin: 0 !important;
}

section.register-steps .change-picture .avatar-section .avatar-picture,
section.account-profile .change-picture .avatar-section .avatar-picture {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    width: 46px;
    height: 46px;
    border-radius: 100%;
    border: 1px solid rgba(0, 19, 21, 0.1);
    position: relative;
    margin-right: 20px;
    position: relative;
}

section.register-steps .change-picture .avatar-section .user-text,
section.account-profile .change-picture .avatar-section .user-text {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Rota';
    font-style: normal;
    font-weight: 700;
    font-size: 16px !important;
    line-height: 20px !important;
    text-align: center;
    color: #80898A !important;
    width: 46px;
    height: 46px;
    border-radius: 100%;
    border: 1px solid rgba(0, 19, 21, 0.1);
}

section.account-profile button.add-specialization-btn {
    color: #00CFB4;
    padding: 9px 20px;
    min-height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

section.account-profile button.add-specialization-btn svg {
    margin-right: 10px;
}

section.account-profile .specialization-box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 17px 20px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

section.account-profile .specialization-box .type {
    display: inline-block;
    flex-direction: column;
    margin-right: auto;
}

section.account-profile .specialization-box .type .primary {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
}

section.account-profile .specialization-box .type .secondary {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #80898A;
}

section.account-profile .specialization-box .status .in-progress {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #80898A;
    padding: 2px 9px;
    background: #F2F3F3;
    border-radius: 12px;
}

section.account-profile .specialization-box .status .approved {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #00CFB4;
    padding: 2px 9px;
    background: rgba(0, 207, 180, 0.1);
    border-radius: 12px;
}

section.register-steps .address-google-places-autocomplete,
section.account-profile .address-google-places-autocomplete {
    position: relative;
    z-index: 100;
}

section.register-steps .address-google-places-autocomplete .css-1wy0on6,
section.account-profile .address-google-places-autocomplete .css-1wy0on6 {
    display: none !important;
}

section.register-steps .address-google-places-autocomplete .css-yk16xz-control,
section.register-steps .address-google-places-autocomplete .css-1pahdxg-control,
section.account-profile .address-google-places-autocomplete .css-yk16xz-control,
section.account-profile .address-google-places-autocomplete .css-1pahdxg-control {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
    background: #E5E7E8;
    border: 2px solid #E5E7E8 !important;
    border-radius: 12px;
    padding: 19px 20px;
    margin-bottom: 10px;
    height: 56px !important;
    transition: all 0.2s ease-out;
    outline: none !important;
    box-shadow: none !important;
}

section.register-steps .address-google-places-autocomplete .css-1uccc91-singleValue,
section.register-steps .address-google-places-autocomplete .css-1g6gooi,
section.account-profile .address-google-places-autocomplete .css-1uccc91-singleValue,
section.account-profile .address-google-places-autocomplete .css-1g6gooi {
    margin: 0 !important;
}

section.register-steps .address-google-places-autocomplete .css-1wa3eu0-placeholder,
section.register-steps .address-google-places-autocomplete .css-1hwfws3,
section.account-profile .address-google-places-autocomplete .css-1wa3eu0-placeholder,
section.account-profile .address-google-places-autocomplete .css-1hwfws3 {
    padding: 0 !important;
    margin: 0 !important;
}

section.register-steps .address-google-places-autocomplete input,
section.account-profile .address-google-places-autocomplete input {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
    width: auto;
}

section.register-steps .address-google-places-autocomplete label,
section.account-profile .address-google-places-autocomplete label {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    width: 100%;
    padding-left: 20px;
    position: absolute;
    font-size: 14px;
    line-height: 17px;
    color: #80898A;
    top: 10px;
    transition: all 0.2s ease-out;
}

.modal-new-specialization .uploaded-box,
section.account-profile .uploaded-box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 15px 20px;
    margin-bottom: 10px;
}

.modal-new-specialization .uploaded-box:last-child,
section.account-profile .uploaded-box:last-child {
    margin-bottom: 0;
}

.modal-new-specialization .uploaded-file,
section.account-profile .uploaded-file {
    display: flex;
    align-items: center;
    position: relative;
}

.modal-new-specialization .uploaded-file .circle.new-file,
section.account-profile .uploaded-file .circle.new-file {
    background: #FF6A39;
    border-radius: 10px;
    width: 10px;
    height: 10px;
    position: absolute;
    top: auto;
    bottom: auto;
    left: -13px;
}

.modal-new-specialization .uploaded-file .file-name,
section.account-profile .uploaded-file .file-name {
    display: inline-flex;
    align-items: center;
    max-width: 295px;
    margin-right: auto;
}

.modal-new-specialization .uploaded-file .file-name span,
section.account-profile .uploaded-file .file-name span {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.modal-new-specialization .uploaded-file .file-name svg,
section.account-profile .uploaded-file .file-name svg {
    margin-right: 16px;
    min-width: 24px;
}

.modal-new-specialization .uploaded-file button,
section.account-profile .uploaded-file button {
    border: 0;
    padding: 0;
    margin: 0;
    background: none;
}

.modal-new-specialization .upload-files,
section.account-profile .upload-files {
    width: 100%;
    margin-bottom: 10px;
}

.modal-new-specialization .upload-files .files-box label,
section.account-profile .upload-files .files-box label {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 11px 20px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.modal-new-specialization .upload-files .files-box:last-child label,
section.account-profile .upload-files .files-box:last-child label {
    margin: 0;
}

.modal-new-specialization .upload-files .files-box label .info-file,
section.account-profile .upload-files .files-box label .info-file {
    display: inline-flex;
    flex-direction: column;
    margin-right: auto;
}

.modal-new-specialization .upload-files .files-box label .info-file .title,
section.account-profile .upload-files .files-box label .info-file .title {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #00CFB4;
    margin-bottom: 2px;
}

.modal-new-specialization .upload-files .files-box label .info-file .details,
section.account-profile .upload-files .files-box label .info-file .details {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #80898A;
}

.modal-new-specialization .upload-files .files-box input,
section.account-profile .upload-files .files-box input {
    display: none;
}

.modal-new-specialization .choose-specialization-type-select {
    background: #E5E7E8;
    border-radius: 12px;
    font-family: 'Rota';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #80898A;
    padding: 17px 16px 17px 20px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.modal-new-specialization .choose-specialization-type-select svg {
    margin-left: auto;
}

.modal-add-specialization .confirm-selection-btn {
    margin-bottom: 10px;
}

.modal-new-specialization .custom-checkbox {
    margin-top: 20px;
}

section.account-profile .phone-number-with-prefix {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
}

section.account-profile .phone-number-with-prefix select {
    width: 105px;
}

section.account-profile .phone-number-with-prefix .form-input {
    width: calc(100% - 105px - 10px);
}
