section.appointments.doctor .box.past {
    background: #FFFFFF;
    border-radius: 12px;
    margin-bottom: 10px;
}

section.appointments.doctor .box.past .medic-info {
    position: relative;
    display: flex;
    align-items: center;
    text-decoration: none !important;
    cursor: pointer;
}

section.appointments.doctor .box.past .medic-info .left-column {
    display: inline-flex;
    align-items: center;
    align-self: stretch;
    width: 445px;
    padding: 20px;
    border-right: 1px solid #F2F3F3;
}

section.appointments.doctor .box.past .medic-info .right-column {
    display: inline-flex;
    align-items: center;
    padding: 10px 20px;
    width: calc(100% - 445px);
}

@media (max-width: 767px) {
    section.appointments.doctor .box.past .medic-info {
        flex-direction: column;
    }

    section.appointments.doctor .box.past .medic-info .left-column {
        display: inline-flex;
        align-items: center;
        align-self: stretch;
        width: 100%;
        padding: 20px;
        border-right: none;
        border-bottom: 1px solid #F2F3F3;
    }

    section.appointments.doctor .box.past .medic-info .right-column {
        display: inline-flex;
        align-items: center;
        padding: 10px 20px;
        width: 100%;
    }
}

section.appointments.doctor .box.past .medic-info svg.arrow-right {
    display: inline-flex;
    margin-left: auto;
    margin-right: -8px;
}

section.appointments .box .medic-info .avatar {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    width: 36px;
    height: 36px;
    border-radius: 100%;
    border: 1px solid rgba(0, 19, 21, 0.1);
    position: relative;
    margin-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

section.appointments .box .medic-info .fullName {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
}

section.appointments .box .medic-info .type,
section.appointments .box .medic-info .specialization {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #80898A;
}

section.appointments .box .medic-info .specialization .year-files {
    display: flex;
    align-items: center;
}

section.appointments .box .medic-info .specialization .year-files svg {
    margin-right: 4px;
    margin-left: 4px;
}

section.appointments.doctor .box.past .list-group {
    width: 100%;
}

section.appointments.doctor .box.past .list-group-item {
    margin-bottom: 12px;
    border: 0;
    padding: 0;
    display: flex;
    align-items: center;
}

section.appointments.doctor .box.past .list-group-item:last-child {
    margin-bottom: 0;
}

section.appointments.doctor .box.past .list-group-item .title {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #80898A;
    margin-right: auto;
    display: inline-flex;
}

section.appointments.doctor .box.past .list-group-item .details {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: right;
    color: #001315;
    display: inline-flex;
}

section.appointments .list-group-item:last-child {
    margin-bottom: 0;
}

section.appointments .box .info-message {
    color: #FF6A39;
    background: rgba(255, 106, 57, 0.1);
    margin-top: 15px;
}

section.appointments.doctor .box.upcoming .need-to-pay .actions {
    width: auto;
    margin-left: auto;
}

section.appointments.doctor .box.upcoming {
    margin-bottom: 10px;
    display: flex;
}

section.appointments.doctor .box.upcoming .data {
    background: #FFFFFF;
    border-radius: 12px;
    display: inline-flex;
    width: 66px;
    height: 100%;
    margin-right: 10px;
    align-items: center;
    justify-content: center;
    font-family: 'Rota';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #001315;
}

section.appointments.doctor .box.upcoming .details {
    background: #FFFFFF;
    border-radius: 12px;
    display: inline-flex;
    width: calc(100% - 66px - 10px);
    align-items: center;
}

section.appointments.doctor .box.upcoming .details.need-to-pay {
    flex-direction: column;
}

section.appointments.doctor .box.upcoming .medic-info .left-column.need-to-pay {
    width: 100%;
}

section.appointments.doctor .box.upcoming .medic-info .right-column.need-to-pay {
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
}

section.appointments.doctor .box.upcoming .medic-info .right-column.need-to-pay .info-message {
    margin-top: 0;
    width: 100%;
    padding: 11px 10px 11px 20px;
}

section.appointments.doctor .box.upcoming .medic-info .right-column.need-to-pay .info-message .text {
    width: 274px;
}

section.appointments.doctor .box.upcoming .medic-info {
    position: relative;
    display: flex;
    align-items: center;
    text-decoration: none !important;
    width: 100%;
}

section.appointments.doctor .box.upcoming .medic-info .left-column {
    display: inline-flex;
    align-items: center;
    width: 421px;
    padding: 12px 20px;
    border-right: 1px solid #F2F3F3;
}

section.appointments.doctor .box.upcoming .medic-info .right-column {
    padding-left: 10px;
    padding-right: 10px;
    width: calc(100% - 421px);
}

section.appointments.doctor .box.upcoming .medic-info svg.arrow-right {
    display: inline-flex;
    margin-left: auto;
    margin-right: -8px;
}
