/*section.account .account-personal-data .form-select-label select.custom-select:focus,*/
/*section.account .account-personal-data .form-select-label select.custom-select,*/
/*section.account .account-personal-data input.form-control:focus,*/
/*section.account .account-personal-data input.form-control {*/
/*    border: 2px solid #BFC4C5!important;*/
/*}*/
section.account .account-personal-data button.orange-button {
    margin-top: 10px;
}

section.account .account-personal-data .change-picture {
    display: flex;
    align-items: center;
}

section.account .account-personal-data .change-picture .files-box {
    background: #FFFFFF;
    border-radius: 12px;
    width: calc(100% - 46px);
    border: 1px solid #E5E7E8;
}

section.account .account-personal-data .change-picture .files-box label {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #FF6A39;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 20px;
    margin: 0;
    cursor: pointer;
}

section.account .account-personal-data .change-picture .files-box input {
    display: none;
}

section.account .account-personal-data .change-picture .avatar {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    width: 46px;
    height: 46px;
    border-radius: 100%;
    border: 1px solid rgba(0, 19, 21, 0.1);
    position: relative;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

section.account .account-personal-data .address-google-places-autocomplete {
    position: relative;
}

section.account .account-personal-data .address-google-places-autocomplete .css-1wy0on6 {
    display: none !important;
}

section.account .account-personal-data .address-google-places-autocomplete .css-36g5w0-control,
section.account .account-personal-data .address-google-places-autocomplete .css-9l12cq-control {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
    background: #E5E7E8;
    border: 2px solid #E5E7E8 !important;
    border-radius: 12px;
    padding: 19px 20px;
    margin-bottom: 10px;
    height: 56px !important;
    transition: all 0.2s ease-out;
    outline: none !important;
    box-shadow: none !important;
}

section.account .account-personal-data .address-google-places-autocomplete .css-44k718-singleValue,
section.account .account-personal-data .address-google-places-autocomplete .css-70phce {
    margin: 0 !important;
}

section.account .account-personal-data .address-google-places-autocomplete .css-tfl430-placeholder,
section.account .account-personal-data .address-google-places-autocomplete .css-1hwfws3 {
    padding: 0 !important;
    margin: 0 !important;
}

section.account .account-personal-data .address-google-places-autocomplete input {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
    width: auto;
}

section.account .account-personal-data .address-google-places-autocomplete label {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    width: 100%;
    padding-left: 20px;
    position: absolute;
    font-size: 14px;
    line-height: 17px;
    color: #80898A;
    top: 10px;
    transition: all 0.2s ease-out;
}

section.account .account-personal-data .phone-number-with-prefix {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
}

section.account .account-personal-data .phone-number-with-prefix select {
    width: 105px;
}

section.account .account-personal-data .phone-number-with-prefix .form-input {
    width: calc(100% - 105px - 10px);
}

section.account .account-personal-data .profile-picture-file-extention-tooltip {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    padding-left: 10px;
    padding-top: 5px;
    font-size: 14px;
    line-height: 17px;
    color: #80898A;

}

section.account .account-personal-data .input-tooltip {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    padding-left: 10px;
    margin-top: -5px;
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 17px;
    color: #80898A;
}

.form-input-invitation-code {
    margin-top: 20px !important;
}

.form-input-invitation-code .form-control {
    margin-bottom: 0px !important;
}
