header {
  &.menu {
    &.menu-patient {
      .landing-menu {
        .menu {
          ul {
            li {
              &.auth {
                a {
                  background: #FF6A39;
                  border-radius: 12px;
                  font-weight: 600;
                  text-align: center;
                  padding: 8px 14px;
                }
              }
            }
          }
        }
      }
    }

    &.menu-medic {
      .view.landing-menu {
        @media screen and (max-width: 1024px) {
          padding: 15px !important;
        }

        .menu {
          @media screen and (max-width: 1024px) {
            display: none;
          }

          ul li {
            &.auth a {
              background: #00CFB4;
              border-radius: 12px;
              font-weight: 600;
              text-align: center;
              color: #FFFFFF;
              padding: 8px 14px;
            }

            &.active {
              color: #00CFB4;
            }
          }
        }
      }

      .view.is-logged-menu .menu nav a.active {
        span {
          color: #00CFB4;
        }

        svg path {
          fill: #00CFB4 !important;
        }
      }
    }

    .view {
      @media (max-width: 1024px) {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 0 !important;

        &.doctor-menu-wrapper{
          padding: 18px 0 !important;
        }
      }

      &.landing-menu {
        display: flex;
        align-items: center;
        padding: 15px;

        .logo {
          display: inline-flex;
          margin-right: auto;
        }

        .menu {
          display: inline-flex;
          margin-left: auto;

          ul {
            list-style: none;
            margin: 0;
            padding: 0;
            display: flex;
            align-items: center;

            li {
              display: inline-flex;
              margin: 0 15px;
              font-family: "Rota";
              font-style: normal;
              font-weight: bold;
              font-size: 16px;
              line-height: 20px;
              color: #80898A;

              @media (max-width: 1024px) {
                &:last-child {
                  margin-right: 0;
                }
                &:first-child {
                  margin-left: 0;
                }
              }

              &.active {
                color: #001315;
              }

              &.login {
                display: inline-flex;
                margin-right: 20px;
              }

              a {
                font-family: Rota;
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 20px;
                text-decoration: none;
              }
            }
          }
        }
      }

      &.login-register-menu {
        display: flex;
        align-items: center;
        padding: 12px 0;

        @media (max-width: 1024px) {
          align-items: baseline;
          margin: 0 -12px;
          max-width: calc(100% + 24px);
          width: calc(100% + 24px);
        }

        .logo-wrapper {
          display: flex;
          @media (max-width: 1024px) {
            width: 100%;
            justify-content: space-between;
            align-items: center;
            padding: 12px;
          }

          .logo {
            display: inline-flex;
            margin-right: auto;
            @media (max-width: 1024px) {
              margin-left: 0;
            }
          }

          .register-mob-btn {
            font-family: 'Rota';
            font-style: normal;
            font-size: 16px;
            line-height: 20px;
            background: #FF6A39;
            border-radius: 12px;
            font-weight: 600;
            text-align: center;
            color: #FFFFFF;
            align-items: center;
            padding: 8px 12px 6px;
            margin-right: 16px;
            display: none;

            @media (max-width: 1024px) {
              display: block;
            }
          }
        }

        .menu {
          display: inline-flex;
          margin-left: auto;

          @media (max-width: 1024px) {
            margin-left: 0;
            display: flex;
            //flex-direction: column;
            align-items: baseline;
            padding: 16px 12px;
            border-top: 1px solid #E5E7E8;
            width: 100%;

            &.doctor-menu {
              display: none;
            }
          }

          ul {
            list-style: none;
            margin: 0;
            padding: 0;
            display: flex;
            align-items: center;
            @media (max-width: 1024px) {
              width: 100%;
              overflow: auto;
              -ms-overflow-style: none; /* Internet Explorer 10+ */
              scrollbar-width: none; /* Firefox */

              &::-webkit-scrollbar {
                display: none; /* Safari and Chrome */
              }
            }

            li {
              &.register {
                display: inline-flex;
                border-radius: 12px;
                padding: 8px 18px;

                a {
                  font-family: Rota;
                  font-style: normal;
                  font-weight: 600;
                  font-size: 16px;
                  line-height: 20px;
                  text-align: center;
                  color: #FFFFFF;
                  text-decoration: none !important;
                }
              }

              a {
                @media (max-width: 1024px) {
                  white-space: nowrap;
                }
              }
            }
          }

          .language-selector-wrapper {

            &.mobile-only {
              border-left: none;
              border-right: 1px solid #E5E7E8;
              margin-left: 0;
              padding-left: 0;
              margin-right: 15px;
              padding-right: 5px;

              @media (min-width: 1025px) {
                display: none !important;
              }

              span {
                padding: 6px 8px 2px;
              }

              .language-options {
                left: 0;
                right: unset;
              }

              svg {
                transform: rotate(90deg);
              }
            }

            @media (max-width: 1024px) {
              &:not(.mobile-only) {
                display: none;
              }
            }
          }
        }

        .bm-menu {
          .language-selector-wrapper {
            margin-right: 5px;
            margin-left: 0;
            border: none;

            span {
              padding: 0;
              width: 100%;

              & > svg {
                margin: 0 0 4px auto;
              }
            }

            &:hover {
              span {
                background: none;
              }
            }
          }
        }
      }

      &.is-logged-menu {
        padding: 0;

        .menu {
          padding-top: 30px;
          padding-bottom: 12px;
          @media (max-width: 1024px) {
            display: flex;
            flex-direction: column;
            align-items: baseline;
            padding: 0;
            margin: 0 -12px;

            &.doctor-menu {
              display: none;
            }
          }


          .logo-wrapper {
            display: flex;
            @media (max-width: 1024px) {
              width: 100%;
              justify-content: space-between;
              align-items: center;
              padding: 0 12px;
            }

            .logo {
              display: flex;
              justify-content: center;
              @media (max-width: 1024px) {
                margin: 0;
              }
            }

            .top-navbar-mobile {
              display: none;
              @media (max-width: 1024px) {
                display: flex;

                .language-selector-wrapper {
                  margin-left: 0;
                  padding-left: 0;
                  border-left: none;

                  span {
                    padding: 7px 12px 5px;

                    svg {
                      margin: 0 0 3px 5px;
                      transform: rotate(90deg);
                    }
                  }
                }

                .small-notifications {
                  display: flex;
                  align-items: center;

                  svg path {
                    fill: #80898a !important
                  }

                  &.active {
                    svg path {
                      fill: #FF6A39 !important;
                    }
                  }

                  .count {
                    background: #FF6A39;
                    border-radius: 10px;
                    width: 20px;
                    height: 20px;
                    position: relative;
                    margin-left: 5px;

                    .number {
                      font-family: Rota;
                      font-style: normal;
                      font-weight: bold;
                      font-size: 12px;
                      line-height: 22px;
                      text-align: center;
                      text-transform: uppercase;
                      color: #FFFFFF;
                      position: absolute;
                      top: 0;
                      bottom: 0;
                      left: 0;
                      right: 0;
                      margin: auto;
                    }

                    &.plus {
                      width: 26px;
                    }
                  }
                }
              }
            }
          }

          nav {
            display: flex;

            @media (max-width: 1024px) {
              align-items: center;
              justify-content: space-evenly;
              border-top: 1px solid #E5E7E8;
              width: 100%;
              margin-top: 12px;
              padding: 6px 12px;
            }

            a {
              display: inline-flex;
              align-items: center;
              text-decoration: none;
              margin-right: 30px;
              @media (max-width: 1024px) {
                flex-direction: column;
                align-items: center;
                justify-content: center;
                margin: 0 auto;
              }

              svg {
                margin-right: 5px;

                path {
                  fill: #80898a !important;
                }
              }

              &.notifications {
                margin-left: auto;
                margin-right: 0;

                @media (max-width: 1024px) {
                  display: none;
                }

                svg {
                  margin-right: 0;
                }

                span {
                  display: none;
                }

                .count {
                  background: #FF6A39;
                  border-radius: 10px;
                  width: 20px;
                  height: 20px;
                  position: relative;
                  margin-left: 5px;

                  .number {
                    font-family: Rota;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 12px;
                    line-height: 22px;
                    text-align: center;
                    text-transform: uppercase;
                    color: #FFFFFF;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    margin: auto;
                  }

                  &.plus {
                    width: 26px;
                  }
                }
              }

              &.active {
                span {
                  color: #FF6A39;
                }

                svg {
                  path {
                    fill: #FF6A39 !important;
                  }
                }
              }

              span {
                font-family: Rota;
                font-style: normal;
                font-weight: bold;
                font-size: 16px;
                line-height: 20px;
                color: #80898A;
                position: relative;
                bottom: -2px;
                @media (max-width: 1024px) {
                  text-align: center;
                }

                &.menu-text {
                  display: block;
                  @media (max-width: 1024px) {
                    display: none;
                  }
                }

                &.mobile-menu-text {
                  display: none;
                  @media (max-width: 1024px) {
                    display: block;
                  }
                }
              }
            }

            .language-selector-wrapper {
              @media (max-width: 1024px) {
                display: none;
              }
            }
          }


        }
      }
    }
  }

  .view.is-logged-menu {
    .menu {
      display: flex;
      align-items: center;
      padding: 16px 0 !important;
      @media (max-width: 1024px) {
        padding: 12px 0 0 !important;
      }

      .logo.cursor-pointer {
        display: flex;
        align-items: center;
        margin-bottom: 0;
      }

      nav {
        margin-left: auto;
      }
    }

    &.doctor-menu-wrapper{
      .menu{
        display: flex;
        flex-direction: column;
        padding-top: 30px !important;
        padding-bottom: 12px !important;
        @media (max-width: 1024px) {
          padding: 0 !important;
          margin: 0;
        }

        .logo.cursor-pointer{
          margin-bottom: 25px;
          @media (max-width: 1024px) {
            margin-bottom: 0;
          }
        }
        nav{
          @media (max-width: 1024px) {
            display: none;
          }
        }
      }
    }
  }

  &.patient-menu-header {
    padding-right: 12px;
    padding-left: 12px;
    display: block !important;
    @media (max-width: 1024px) {
      position: sticky;
      top: 0;
    }

    &.menu {
      .landing-menu {

        .bm-burger-button {
          position: relative;
          right: unset;
          top: unset;
        }

        .bm-cross-button {
          right: 12px !important;
          top: -40px !important;
        }

        .bm-overlay {
          top: 59px !important;
        }

        .bm-menu-wrap {
          top: 59px !important;
        }

        .menu {
          ul {
            li {
              &.active {
                color: #FF6A39;
              }
            }
          }
        }
      }
    }
  }
}

.language-selector-wrapper {
  margin-right: 10px;
  margin-left: 5px;
  padding-left: 10px;
  border-left: 1px solid #E5E7E8;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;

  & > span {
    padding: 12px 12px 10px;
    font-family: 'Rota';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #80898A;
    display: flex;
    align-items: center;

    svg {
      margin: 0 0 4px 5px;
      @media (min-width: 1025px) {
        transform: rotate(90deg);
      }
    }
  }

  &.active, &:hover {
    & > span {
      background: #F2F3F3;
      border-radius: 12px;
    }
  }

  .language-options {
    background: #FFFFFF;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.15), 0 2px 2px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
    position: absolute;
    top: 100%;
    right: 15px;

    .language-option {
      font-family: 'Rota';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #80898A;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      padding: 14px 20px 12px;

      svg {
        margin-left: 30px;
      }
    }
  }
}

.nav-top-menu {
  background-color: #F2F3F3;
  padding: 5px 0;

  @media (max-width: 1024px) {
    display: none;
  }

  ul {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 0;

    li {
      list-style: none;

      &:not(:last-child) {
        margin-right: 30px;
      }

      a {
        font-family: 'Rota';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        color: #80898A;
        text-decoration: none;
      }

      &.register {
        background: #FF6A39;
        border-radius: 12px;
        font-weight: 600;
        text-align: center;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        padding: 8px 14px 6px;
      }
    }
  }

}

.nav-bar-vouchers-wrapper {
  cursor: pointer;
  margin: 0 -12px;
  padding: 10px 0;
  border-top: 1px solid #E5E7E8;
  font-family: 'Rota';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #00CFB4;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-top: -6px;
    margin-right: 10px;
  }
}
