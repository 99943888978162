section.account .account-my-vouchers .voucher-box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 20px;
    position: relative;
    margin-bottom: 10px;
    border: 1px solid #E5E7E8;
}
section.account .account-my-vouchers .voucher-box:last-child {
    margin-bottom: 0;
}
section.account .account-my-vouchers .voucher-box .custom-icon {
    position: absolute;
    top: 18px;
    right: 20px;
}
section.account .account-my-vouchers .voucher-box .code {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #80898A;
    margin-bottom: 4px;
}
section.account .account-my-vouchers .voucher-box .title {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
    margin-bottom: 2px;
    max-width: 274px;
}
section.account .account-my-vouchers .voucher-box .expires-at {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #80898A;
    /*margin-bottom: 10px;*/
}
section.account .account-my-vouchers .voucher-box .discount {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #80898A;
    margin-bottom: 10px;
}
section.account .account-my-vouchers .voucher-box .description {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #80898A;
}