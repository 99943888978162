section.rates {
    position: relative;
}
section.rates .small-container {
    max-width: 400px;
    margin: auto;
}
section.rates .back-to-home {
    position: absolute;
    left: 0;
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #001315;
    text-decoration: none!important;
    display: inline-flex;
    align-items: center;
}
section.rates .back-to-home svg {
    margin-right: 10px;
}
section.rates .title-section {
    margin-top: 40px;
    margin-bottom: 40px;
    position: relative;
    z-index: 10;
}
section.rates .title-section h2 {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 110%;
    text-align: center;
    color: #001315;
    margin: 0;
    position: relative;
    z-index: 10;
}

section.rates .subtitle {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #80898A;
    margin-top: 20px;
    margin-bottom: 10px;
}
section.rates .green-button {
    margin-top: 10px;
}