section.rating {
    max-width: 400px;
    margin: auto;
}
section.rating .title-section {
    margin-top: 40px;
    margin-bottom: 30px;
}
section.rating .title-section h1 {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 110%;
    text-align: center;
    color: #001315;
    margin: 0;
}

section.rating .medic-box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
}
section.rating .medic-box .avatar {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    width: 100px;
    height: 100px;
    border-radius: 100%;
    border: 1px solid rgba(0, 19, 21, 0.1);
    margin-bottom: 20px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
section.rating .medic-box .with {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #80898A;
    margin-bottom: 4px;
}
section.rating .medic-box .fullName {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 24px;
    text-align: center;
    color: #001315;
}
section.rating .rating-box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 20px;
    margin-bottom: 10px;
}
section.rating .rating-box .btn-group {
    display: flex;
    align-items: center;
    margin-left: -10px;
    margin-right: -10px;
}
section.rating .rating-box .btn-group label {
    margin: 0 5px;
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #001315;
    background: #E5E7E8;
    border: 0;
    outline: none!important;
    box-shadow: none!important;
    border-radius: 4px;
    padding: 17px 18px;
    opacity: 1;
}
section.rating .rating-box .btn-group input:first-child + label {
    border-radius: 27px 0 0 27px!important;
}
section.rating .rating-box .btn-group label:last-child {
    border-radius: 0 27px 27px 0!important;
}

/* rating 1 */
section.rating .rating-box .btn-group[custom-attribute="1"] input:first-child + label {
    background: #EB3300;
}
section.rating .rating-box .btn-group[custom-attribute="1"] input:nth-child(3) + label,
section.rating .rating-box .btn-group[custom-attribute="1"] input:nth-child(5) + label,
section.rating .rating-box .btn-group[custom-attribute="1"] input:nth-child(7) + label,
section.rating .rating-box .btn-group[custom-attribute="1"] input:nth-child(9) + label {
    color: rgba(0, 19, 21, 0.2);
}

/* rating 2 */
section.rating .rating-box .btn-group[custom-attribute="2"] input:first-child + label {
    background: #FF6A39;
    color: rgba(0, 19, 21, 0.2);
}
section.rating .rating-box .btn-group[custom-attribute="2"] input:nth-child(3) + label {
    background: #FF6A39;
}
section.rating .rating-box .btn-group[custom-attribute="2"] input:nth-child(5) + label,
section.rating .rating-box .btn-group[custom-attribute="2"] input:nth-child(7) + label,
section.rating .rating-box .btn-group[custom-attribute="2"] input:nth-child(9) + label {
    color: rgba(0, 19, 21, 0.2);
}

/* rating 3 */
section.rating .rating-box .btn-group[custom-attribute="3"] input:first-child + label,
section.rating .rating-box .btn-group[custom-attribute="3"] input:nth-child(3) + label {
    background: #EDE939;
    color: rgba(0, 19, 21, 0.2);
}
section.rating .rating-box .btn-group[custom-attribute="3"] input:nth-child(5) + label {
    background: #EDE939;
}
section.rating .rating-box .btn-group[custom-attribute="3"] input:nth-child(7) + label,
section.rating .rating-box .btn-group[custom-attribute="3"] input:nth-child(9) + label {
    color: rgba(0, 19, 21, 0.2);
}

/* rating 4 */
section.rating .rating-box .btn-group[custom-attribute="4"] input:first-child + label,
section.rating .rating-box .btn-group[custom-attribute="4"] input:nth-child(3) + label,
section.rating .rating-box .btn-group[custom-attribute="4"] input:nth-child(5) + label {
    background: #9FE78D;
    color: rgba(0, 19, 21, 0.2);
}
section.rating .rating-box .btn-group[custom-attribute="4"] input:nth-child(7) + label {
    background: #9FE78D;
}
section.rating .rating-box .btn-group[custom-attribute="4"] input:nth-child(9) + label {
    color: rgba(0, 19, 21, 0.2);
}

/* rating 5 */
section.rating .rating-box .btn-group[custom-attribute="5"] input:first-child + label,
section.rating .rating-box .btn-group[custom-attribute="5"] input:nth-child(3) + label,
section.rating .rating-box .btn-group[custom-attribute="5"] input:nth-child(5) + label,
section.rating .rating-box .btn-group[custom-attribute="5"] input:nth-child(7) + label {
    background: #00CFB4;
    color: rgba(0, 19, 21, 0.2);
}
section.rating .rating-box .btn-group[custom-attribute="5"] input:nth-child(9) + label {
    background: #00CFB4;
}

section.rating .rating-box .rating-class {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #001315;
    margin-top: 10px;
}
section.rating .actions {
    display: flex;
    align-items: center;
    margin-top: 20px;
}
section.rating .actions .white-button {
    text-decoration: none!important;
    color: #001315!important;
    display: inline-flex;
    max-width: 121px;
    margin-right: 10px;
    justify-content: center;
}
