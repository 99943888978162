section.medical-folder {
    max-width: 400px;
    margin: auto;
}

section.medical-folder .small-container,
section.medical-folder.small-container {
    max-width: 400px;
    margin: auto;
}

section.medical-folder .title-section {
    margin-top: 40px;
    margin-bottom: 40px;
    position: relative;
}

section.medical-folder .title-section .icon-wrapper {
    position: absolute;
    left: 0;
    cursor: pointer;
}

section.medical-folder .title-section h1 {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 110%;
    text-align: center;
    color: #001315;
    margin: 0;
}

section.medical-folder .subtitle {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #80898A;
    margin-top: 20px;
    margin-bottom: 10px;
}

section.medical-folder .info-message.gray {
    background: #E5E7E8;
}

section.medical-folder .header-profile {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

section.medical-folder .header-profile .subtitle {
    display: inline-flex;
    margin-right: auto;
    margin-top: 0;
    margin-bottom: 0;
}

section.medical-folder .header-profile button {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: right;
    color: #FF6A39;
    border: 0;
    padding: 0;
    margin: 0;
    background: none;
}

section.medical-folder a.white-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 9px 20px;
    min-height: 46px;
    margin-bottom: 20px;
    color: #001315 !important;
    text-decoration: none !important;
}

section.medical-folder a.white-button svg {
    margin-right: 10px;
}

section.medical-folder .patient-box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 13px 20px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

section.medical-folder .patient-box .details {
    display: flex;
    align-items: center;
    margin-right: auto;
}

section.medical-folder .patient-box .avatar {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    width: 36px;
    height: 36px;
    border-radius: 100%;
    border: 1px solid rgba(0, 19, 21, 0.1);
    position: relative;
    margin-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

section.medical-folder .patient-box .details .name {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
}

section.medical-folder .patient-box .details .age {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #80898A;
    margin-top: 2px;
}

section.medical-folder .patient-box button {
    padding: 0;
    border: 0;
    background: none;
}

section.medical-folder .patient-box a {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: right;
    color: #FF6A39 !important;
    text-decoration: none !important;
}

section.medical-folder a.white-button.disabled,
section.medical-folder .patient-box.disabled,
section.medical-folder .info-message.disabled {
    opacity: 0.5 !important;
    pointer-events: none !important;
}

section.medical-folder .change-picture {
    display: flex;
    align-items: center;
}

section.medical-folder .change-picture .files-box {
    background: #FFFFFF;
    border-radius: 12px;
    width: calc(100% - 46px);
}

section.medical-folder .change-picture .files-box label {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 20px;
    margin: 0;
    cursor: pointer;
}

section.medical-folder .change-picture .files-box label.add {
    color: #001315;
}

section.medical-folder .change-picture .files-box label.modify {
    color: #FF6A39;
}

section.medical-folder .change-picture .files-box input {
    display: none;
}

section.medical-folder .change-picture .avatar {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    width: 46px;
    height: 46px;
    border-radius: 100%;
    border: 1px solid rgba(0, 19, 21, 0.1);
    position: relative;
    margin-left: 10px;
}

section.medical-folder .change-picture .no-avatar {
    background: #E5E7E8;
    border-radius: 100%;
    width: 46px;
    height: 46px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
}

section.medical-folder.add-profile .custom-checkbox {
    margin-top: 30px;
    margin-bottom: 20px;
}

section.medical-folder .switch-box {
    background: #E5E7E8;
    border-radius: 12px;
    padding: 12px 20px;
    margin-bottom: 10px;
}

section.medical-folder .nav.nav-tabs {
    border: 0;
    display: flex;
    margin-left: -5px;
    margin-right: -5px;
    margin-bottom: 15px;
}

section.medical-folder .nav.nav-tabs .nav-item {
    flex: 1 1 auto;
    padding: 0 5px;
}

section.medical-folder .nav.nav-tabs .nav-item .nav-link {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #001315;
    background: #E5E7E8;
    border-radius: 10px;
    padding: 8px 20px;
    border: 0;
    cursor: pointer;
}

section.medical-folder .nav.nav-tabs .nav-item .nav-link.active {
    background: #FFFFFF;
}

section.medical-folder .add-btn {
    /*padding: 9px 20px;*/
    min-height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FF6A39;
    margin-bottom: 10px;
}

section.medical-folder .add-btn svg {
    margin-right: 10px;
}

section.medical-folder .family-history .box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 20px 20px 13px 20px;
    margin-bottom: 10px;
}

section.medical-folder .family-history .box .title {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
    margin-bottom: 12px;
}

section.medical-folder .family-history .box .description {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
    margin-bottom: 8px;
    word-break: break-word;
}

section.medical-folder .family-history .box .read-all-btn {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #FF6A39;
    border: 0;
    padding: 0;
    background: none;
}

section.medical-folder .family-history .box .action-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-top: 1px solid #F2F3F3;
    margin-top: 16px;
    padding-top: 13px;
}

section.medical-folder .family-history .box .action-buttons .modify-btn {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #FF6A39;
    border: 0;
    padding: 0;
    background: none;
    display: flex;
    align-items: center;
    margin-left: 24px;
}

section.medical-folder .family-history .box .action-buttons svg {
    margin-right: 7px;
}

section.medical-folder .family-history .box .action-buttons .delete-btn {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #80898A;
    border: 0;
    padding: 0;
    background: none;
    display: flex;
    align-items: center;
    margin-left: 24px;
}

.modal-add-family-history .modal-body .form-textarea textarea {
    margin-bottom: 0;
}

section.medical-folder .recommendation-tab {
    margin-top: 20px;
}

section.medical-folder .recommendation-tab .box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 20px 40px 20px 20px;
    display: flex;
    margin-bottom: 20px;
    word-break: break-word;
}

section.medical-folder .recommendation-tab .box:last-child {
    margin-bottom: 0;
}

section.medical-folder .recommendation-tab .box .avatar {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    display: inline-flex;
    width: 24px;
    height: 24px;
    min-width: 24px;
    border-radius: 100%;
    border: 1px solid rgba(0, 19, 21, 0.1);
    position: relative;
    margin-right: 16px;
}

section.medical-folder .recommendation-tab .box .details {
    flex: 1;
    display: inline-flex;
    flex-direction: column;
}

section.medical-folder .recommendation-tab .box .details .fullName {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 18px;
    color: #001315;
    margin-bottom: 4px;
}

section.medical-folder .recommendation-tab .box .details .data {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #80898A;
}

section.medical-folder .recommendation-tab .box .details .specialization {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #80898A;
}

section.medical-folder .recommendation-tab .box .details .item .title {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #80898A;
    border-top: 1px solid #F2F3F3;
    margin-bottom: 5px;
    margin-top: 10px;
    padding-top: 15px;
}

section.medical-folder .recommendation-tab .box .details .item .description {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
}

section.medical-folder .recommendation-tab .box .details .item .document {
    display: flex;
    align-items: center;
    position: relative;
    margin-top: 15px;
    text-decoration: none !important;
}

section.medical-folder .recommendation-tab .box .details .item .document .info {
    display: inline-flex;
    flex-direction: column;
}

section.medical-folder .recommendation-tab .box .details .item .document .info .name {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #001315 !important;
    margin-bottom: 2px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 250px;
}

section.medical-folder .recommendation-tab .box .details .item .document .info .data {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #80898A !important;
}

section.medical-folder .recommendation-tab .box .details .item .document .custom-icon.document {
    margin-right: 16px;
    display: inline-flex;
}

section.medical-folder .recommendation-tab .box .details .item .document .custom-icon.arrow {
    position: absolute;
    right: -28px;
    top: auto;
    bottom: auto;
}

section.medical-folder .folder-tab {
    margin-top: 20px;
}

section.medical-folder .folder-tab .box {
    background: #FFFFFF;
    border-radius: 12px;
    margin-bottom: 10px;
    border: 0;
}

section.medical-folder .folder-tab .box:last-child {
    margin-bottom: 0;
}

section.medical-folder .folder-tab .box .title {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
}

section.medical-folder .folder-tab .box .accordion-header .accordion-button .counter-badge {
    background: #F2F3F3;
    border-radius: 12px;
    color: #001315;
    font-family: 'Rota';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    margin-left: auto;
    padding: 5px 8px;
}

section.medical-folder .folder-tab .box .accordion-header .accordion-button::after {
    background-image: url("shared/assets/icons/accordion-item-closed.svg");
    height: 10px;
    margin-left: 15px;
}

section.medical-folder .folder-tab .box .accordion-header {
    border-bottom: 1px solid #F2F3F3;
}

section.medical-folder .folder-tab .box .accordion-button {
    padding: 15px 20px;
    border: 0 !important;
    background: none !important;
    box-shadow: none !important;
}

section.medical-folder .folder-tab .box .accordion-body {
    padding: 0 20px 0 20px;
}

section.medical-folder .folder-tab .box .document {
    display: flex;
    flex-direction: column;
}

section.medical-folder .folder-tab .box .document button {
    display: flex;
    align-items: center;
    position: relative;
    border: 0;
    padding: 0;
    margin: 13px 0;
    background: none;
    text-decoration: none !important;
}

section.medical-folder .folder-tab .box .document button .info {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
}

section.medical-folder .folder-tab .box .document button .info .name {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #001315 !important;
    margin-bottom: 2px;
}

section.medical-folder .folder-tab .box .document button .info .data {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #80898A !important;
}

section.medical-folder .folder-tab .box .document button .custom-icon.document {
    margin-right: 16px;
    display: inline-flex;
}

section.medical-folder .folder-tab .box .document button .custom-icon.arrow {
    position: absolute;
    right: -8px;
    top: auto;
    bottom: auto;
}

section.medical-folder .empty-container {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #80898A;
    margin-top: 60px;
}

.modal-edit-document .modal-dialog,
.modal-add-document .modal-dialog {
    max-width: 440px;
}

.modal-edit-document .modal-header {
    align-items: flex-start;
}

.modal-edit-document .modal-header .modal-title .data {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #80898A;
}

.modal-add-document .info-message {
    background: #E5E7E8;
    padding: 16px 20px;
}

.modal-edit-document .files .subtitle,
.modal-add-document .files .subtitle {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #80898A;
    margin-top: 20px;
    margin-bottom: 10px;
}

.modal-edit-document .add-files .files-box,
.modal-add-document .files .files-box {
    background: #FFFFFF;
    border-radius: 12px;
}

.modal-edit-document .add-files .files-box label,
.modal-add-document .files .files-box label {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 20px;
    margin: 0;
    cursor: pointer;
}

.modal-edit-document .add-files .files-box label svg,
.modal-add-document .files .files-box label svg {
    margin-right: 10px;
}

.modal-edit-document .add-files .files-box input,
.modal-add-document .files .files-box input {
    display: none;
}

.modal-edit-document .files .uploaded-box,
.modal-add-document .files .uploaded-box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 15px 20px;
    margin-bottom: 10px;
}

.modal-edit-document .files .uploaded-box:last-child,
.modal-add-document .files .uploaded-box:last-child {
    margin-bottom: 0;
}

.modal-edit-document .files .uploaded-file,
.modal-add-document .files .uploaded-file {
    display: flex;
    align-items: center;
    position: relative;
}

.modal-edit-document .files .uploaded-file .circle.new-file {
    background: #FF6A39;
    border-radius: 10px;
    width: 10px;
    height: 10px;
    position: absolute;
    top: auto;
    bottom: auto;
    left: -13px;
}

.modal-edit-document .files .uploaded-file .file-name,
.modal-add-document .files .uploaded-file .file-name {
    display: inline-flex;
    align-items: center;
    max-width: 295px;
    margin-right: auto;
}

.modal-edit-document .files .uploaded-file .file-name span,
.modal-add-document .files .uploaded-file .file-name span {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.modal-edit-document .files .uploaded-file .file-name svg,
.modal-add-document .files .uploaded-file .file-name svg {
    margin-right: 16px;
    min-width: 24px;
}

.modal-edit-document .files .uploaded-file button,
.modal-add-document .files .uploaded-file button {
    border: 0;
    padding: 0;
    margin: 0;
    background: none;
}

.modal-edit-document .details .subtitle,
.modal-add-document .details .subtitle {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #80898A;
    margin-top: 20px;
    margin-bottom: 10px;
}

.modal-edit-document .details .subtitle {
    margin-top: 0;
}

.modal-add-document .details .form-select-label select {
    margin-bottom: 0;
}

.modal-edit-document .add-files .subtitle {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #80898A;
    margin-top: 20px;
    margin-bottom: 10px;
}

.modal-edit-document .orange-button {
    margin-top: 20px;
}

.modal-edit-document .modal-footer .white-button {
    color: #EB3300;
}

section.medical-folder .phone-number-with-prefix {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
}

section.medical-folder .phone-number-with-prefix select {
    width: 105px;
}

section.medical-folder .phone-number-with-prefix .form-input {
    width: calc(100% - 105px - 10px);
}

section.medical-folder #birthDate {
    padding: 28px 18px 10px 18px
}

.modal-add-document #date-document {
    padding: 28px 18px 10px 18px
}

section.medical-folder .profile-picture-file-extention-tooltip {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    padding-left: 10px;
    padding-top: 5px;
    font-size: 14px;
    line-height: 17px;
    color: #80898A;
}

.orange-button.disabled {
    background: #BFC4C5;
    border: 1px solid #BFC4C5;
    color: #FFF;
}

.uploading-files-animation {
    width: 79px;
    height: 29px;
    margin-left: auto;
    margin-right: auto;
}
