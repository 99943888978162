.modal-call-alert .patient-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.modal-call-alert .patient-box .avatar-section {
    margin-bottom: 6px;
}
.modal-call-alert .patient-box .avatar-section .user-image {
    margin: 0!important;
}
.modal-call-alert .patient-box .avatar-section .avatar-picture {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    width: 36px;
    height: 36px;
    border-radius: 100%;
    border: 1px solid rgba(0, 19, 21, 0.1);
    position: relative;
    position: relative;
}
.modal-call-alert .patient-box .avatar-section .user-text {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Rota';
    font-style: normal;
    font-weight: 700;
    font-size: 16px!important;
    line-height: 20px!important;
    text-align: center;
    color: #80898A!important;
    width: 36px;
    height: 36px;
    border-radius: 100%;
    border: 1px solid rgba(0, 19, 21, 0.1);
}
.modal-call-alert .patient-box .fullName {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #001315;
    margin-bottom: 2px;
}
.modal-call-alert .patient-box .year-files {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #80898A;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}
.modal-call-alert .patient-box .year-files svg {
    margin-right: 4px;
    margin-left: 4px;
}
.modal-call-alert .actions {
    display: flex;
    align-items: center;
    margin-top: 14px;
    margin-left: -10px;
    margin-right: -10px;
}
.modal-call-alert .actions .cancel-btn {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #EB3300;
    background: #FFFFFF;
    border: 1px solid #E5E7E8;
    box-sizing: border-box;
    border-radius: 12px;
    padding: 16px 10px;
    display: inline-flex;
    justify-content: center;
    width: 50%;
    margin: 0 5px;
}
.modal-call-alert .actions .start-now-btn {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF!important;
    text-decoration: none!important;
    background: #00CFB4;
    border-radius: 12px;
    padding: 16px 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    margin: 0 5px;
    border: 0!important;
}
.modal-call-alert .actions .start-now-btn svg {
    margin-right: 10px;
}

.modal-time-waiting .patient-box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 12px 20px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}
.modal-time-waiting .patient-box .avatar-section {
    margin-right: 20px;
}
.modal-time-waiting .patient-box .avatar-section .user-image {
    margin: 0!important;
}
.modal-time-waiting .patient-box .avatar-section .avatar-picture {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    width: 36px;
    height: 36px;
    border-radius: 100%;
    border: 1px solid rgba(0, 19, 21, 0.1);
    position: relative;
    position: relative;
}
.modal-time-waiting .patient-box .avatar-section .user-text {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Rota';
    font-style: normal;
    font-weight: 700;
    font-size: 16px!important;
    line-height: 20px!important;
    text-align: center;
    color: #80898A!important;
    width: 36px;
    height: 36px;
    border-radius: 100%;
    border: 1px solid rgba(0, 19, 21, 0.1);
}
.modal-time-waiting .patient-box .info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.modal-time-waiting .patient-box .info .fullName {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
    text-align: left;
}
.modal-time-waiting .patient-box .year-files {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #80898A;
    display: flex;
    align-items: center;
}
.modal-time-waiting .patient-box .year-files svg {
    margin-right: 4px;
    margin-left: 4px;
}

.modal-time-waiting .minutes {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 60px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.modal-time-waiting .minutes .form-select-label {
    max-width: 91px!important;
}
.modal-time-waiting .minutes .custom-select {
    border-color: #F2f3f3!important;
    padding: 10px 10px 10px 30px!important;
    background-position: right 7px center;
    height: 46px !important;
    margin: 0!important;
    text-align: left!important;
}
.modal-time-waiting .minutes .min {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
    margin-left: 10px;
}

.modal-call-alert .patient-box .avatar-section {
    margin-bottom: 6px;
}
.modal-time-waiting .patient-box .avatar-section {
    margin-right: 20px;
}
.modal-call-alert .patient-box .avatar-section .user-image,
.modal-time-waiting .patient-box .avatar-section .user-image {
    margin: 0!important;
}
.modal-call-alert .patient-box .avatar-section .avatar-picture,
.modal-time-waiting .patient-box .avatar-section .avatar-picture {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    width: 36px;
    height: 36px;
    border-radius: 100%;
    border: 1px solid rgba(0, 19, 21, 0.1);
    position: relative;
    margin-right: 10px;
    position: relative;
}
.modal-call-alert .patient-box .avatar-section .user-text,
.modal-time-waiting .patient-box .avatar-section .user-text {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Rota';
    font-style: normal;
    font-weight: 700;
    font-size: 16px!important;
    line-height: 20px!important;
    text-align: center;
    color: #80898A!important;
    width: 36px;
    height: 36px;
    border-radius: 100%;
    border: 1px solid rgba(0, 19, 21, 0.1);
}