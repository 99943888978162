.permissions-bg {
    width: 100%;
    height: 100vh;
}

.button-image {
    width: 100%;
    display: block;
}

.permissions-page {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15), 0px 4px 8px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
    display: flex;
    min-width: 900px;
    max-width: 900px;
    background: #FFFFFF;
}

.permissions-page .left-column {
    background: #FFF;
    border-radius: 8px 0 0 8px;
    display: flex;
    flex-direction: column;
    width: 60%;
    padding: 40px;
}

.permissions-page .right-column {
    display: flex;
    width: 40%;
    /*padding: 28px;*/
    align-items: center;
    margin-top: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
    background: #E5E7E8;
    border-radius: 12px;
}

.permissions-page h2 {
    font-family: 'Rota';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 110%;
    color: #001315;
    margin-bottom: 30px;
}

.permissions-page ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.permissions-page ul li .circle-icon {
    background: rgba(255, 106, 57, 0.1);
    border-radius: 50px;
    width: 59px;
    height: 59px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
}

.permissions-page ul li {
    font-family: 'Rota';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    color: #001315;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.permissions-page ul li span {
    display: inline-flex;
    width: calc(100% - 75px);
}

.permissions-page .btn-activation {
    background: #FF6A39;
    border-radius: 12px;
    padding: 18px 25px;
    font-family: 'Rota';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #FFFFFF;
    cursor: pointer;
    margin-top: 10px;
}

.circle-arrow-permission {
    background: #FFFFFF;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.15), 0px 4px 8px rgba(0, 0, 0, 0.15);
    border-radius: 50px;
    width: 100px;
    height: 100px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 60px;
    left: 75px;
    animation: MoveUpDown 1.5s linear infinite;
}

@keyframes MoveUpDown {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-60px);
    }
}

.circle-arrow-permission svg {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    transform: rotate(90deg);
}

.video-camera-permissions-icon {
    background-image: url("../../shared/assets/icons/permissions/doctor/video-camera-permissions-icon.svg");
    background-position: center center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 50px;
    height: 50px;
    display: inline-block;
    margin-right: 25px;
}

.mic-permissions-icon {
    background-image: url("../../shared/assets/icons/permissions/doctor/mic-permissions-icon.svg");
    background-position: center center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 50px;
    height: 50px;
    display: inline-block;
    margin-right: 25px;
}

.notifications-permissions-icon {
    background-image: url("../../shared/assets/icons/permissions/doctor/notifications-permissions-icon.svg");
    background-position: center center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 50px;
    height: 50px;
    display: inline-block;
    margin-right: 25px;
}

@media (max-width: 767px) {
    .permissions-page {
        flex-direction: column;
        min-width: 100%;
        max-width: 100%;
    }

    .permissions-page .left-column {
        background: #FFF;
        border-radius: 8px 8px 0 8px;
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 20px;
    }

    .permissions-page .right-column {
        display: flex;
        width: 100%;
        padding: 28px;
        align-items: center;
        margin-top: 0;
        margin-right: 0;
        margin-bottom: 0;
        background: #E5E7E8;
        border-radius: 0 0 8px 8px;
    }

    .permissions-page h2 {
        font-size: 20px;
        margin-bottom: 0;
    }

    .permissions-page ul li .circle-icon {
        width: 40px;
        height: 40px;
    }

    .permissions-page ul li {
        font-family: 'Rota';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #001315;
        display: flex;
        align-items: center;
        margin-bottom: 20px;
    }

    .permissions-page ul li span {
        display: inline-flex;
        width: calc(100% - 75px);
    }

    .permissions-page .btn-activation {
        background: #FF6A39;
        border-radius: 12px;
        padding: 18px 25px;
        font-family: 'Rota';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        color: #FFFFFF;
        cursor: pointer;
        margin-top: 0;
    }
}

/*////////////////////////////////*/
.permissions-carousel .next-slider .custom-icon,
.permissions-carousel .prev-slider .custom-icon {
    margin-right: 0;
}

.permissions-carousel .prev-slider {
    position: absolute;
    top: calc(50% - 18px);
    height: 35px;
    width: 35px;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 0 5px 0 #0009;
    z-index: 10;
    cursor: pointer;
    font-size: 10px;
    opacity: 0.6;
    transition: opacity 0.25s;
    left: 5px;
    right: initial;
    align-items: center;
    justify-content: center;
    display: flex;
}

.permissions-carousel .next-slider {
    position: absolute;
    top: calc(50% - 18px);
    height: 35px;
    width: 35px;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 0 5px 0 #0009;
    z-index: 10;
    cursor: pointer;
    font-size: 10px;
    opacity: 0.6;
    -webkit-transition: opacity 0.25s;
    transition: opacity 0.25s;
    left: initial;
    right: 5px;
    align-items: center;
    justify-content: center;
    display: flex;
}

.permissions-carousel .permissions-carousel-image {
    width: 100%
}



/*.permissions-carousel .permissions-carousel-item {*/
/*    background: #F2F2F3;*/
/*    border-radius: 12px;*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*    padding: 30px 0;*/
/*    cursor: pointer;*/
/*    height: 250px;*/
/*}*/

/*.permissions-carousel .permissions-carousel-item .permissions-carousel-item-content {*/
/*    width: 100%;*/
/*    max-width: 400px;*/
/*}*/

/*.permissions-carousel .permissions-carousel-item .permissions-carousel-item-content > div[style^="position: relative"] {*/
/*    width: 100%;*/
/*}*/
