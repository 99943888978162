.modal-share-social .social-list {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    flex-wrap: wrap;
}
.modal-share-social .social-list button {
    margin: 5px;
}
.modal-share-social .social-list svg {
    border-radius: 12px;
}
.modal-share-social .white-button {
    padding: 10px 20px;
    min-height: 46px;
}
.modal-share-social .link-text {
    background: #E5E7E8;
    border-radius: 12px;
    font-family: 'Rota';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #80898A;
    padding: 17px 20px;
    margin-top: 20px;
    margin-bottom: 10px;
    overflow: hidden;
}
.modal-share-social .medic-box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 12px 20px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}
.modal-share-social .medic-box .avatar {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    width: 36px;
    height: 36px;
    border-radius: 100%;
    border: 1px solid rgba(0, 19, 21, 0.1);
    position: relative;
    margin-right: 20px;
}
.modal-share-social .medic-box .name {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
}
.modal-share-social .medic-box .rating {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #80898A;
    display: flex;
}
.modal-share-social .medic-box .rating svg {
    margin-right: 5px;
}

.modal-voucher-box {
    /*background: #FFFFFF;*/
    border-radius: 12px;
    padding: 20px;
    position: relative;
    margin-bottom: 10px;
    border: 1px solid #E5E7E8;
}

.modal-voucher-box .description {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    color: #80898A;
    /*text-align: justify;*/
    padding-bottom: 10px;
}

.shareModalFooterTitle {
    flex-grow: 1;
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
    color: #001315;
}

.react-confirm-alert-overlay > .react-confirm-alert > .react-confirm-alert-modal > .shareAlertModal {
    border-top: 1px solid #E5E7E8;
    padding: 20px;
}

.react-confirm-alert-overlay > .react-confirm-alert > .react-confirm-alert-modal > .shareAlertModal .social-list{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    flex-wrap: wrap;
}

.react-confirm-alert-overlay > .react-confirm-alert > .react-confirm-alert-modal > .shareAlertModal .social-list button {
    margin: 5px;
}

.react-confirm-alert-overlay > .react-confirm-alert > .react-confirm-alert-modal > .shareAlertModal .social-list svg {
    border-radius: 12px;
}