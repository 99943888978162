.doctor-app section.account .nav .nav-item a.active {
    background: #00CFB4;
}

.doctor-app section.account .nav .nav-item a .title .number {
    background: #00CFB4;
}

.doctor-app section.account .nav .nav-item a.active .title .number {
    color: #00CFB4;
}

.doctor-app section.account .nav .nav-item a.active .title .description {
    color: #B3F1E9;
}

.doctor-app section.account .account-change-password button.green-button {
    margin-top: 10px;
}

section.account .back-to {
    display: none;
}

.doctor-app section.account .tab-content {
    min-height: 634px;
}

@media (max-width: 991px) {
    section.account .menu-box .menu-account-mobile {
        display: flex !important;
    }

    section.account .menu-box > .nav.nav-tabs {
        display: none !important;
    }

    section.account .content-box {
        display: block;
    }

    section.account .menu-box,
    section.account .content-box {
        width: 100% !important;
    }
}

.is-pharmacist-only-item {
    border-radius: 12px 12px 12px 12px !important;
}

.is-pharmacist-agreement-label {
    font-size: 14px !important;
}
