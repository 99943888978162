section.notifications {
    /*max-width: 400px;*/
    margin: auto;
}

section.notifications .title-section {
    margin-top: 40px;
    margin-bottom: 35px;
}

section.notifications .title-section h1 {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 110%;
    text-align: center;
    color: #001315;
    margin: 0;
}

section.notifications .subtitle {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #80898A;
    margin-top: 20px;
    margin-bottom: 10px;
}

section.notifications .box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 20px;
    margin-bottom: 10px;
    position: relative;
}

section.notifications .box .unread-notification {
    margin-right: 10px;
}

section.notifications .box .unread-notification .green-circle {
    background: #FF6A39;
    border-radius: 10px;
    width: 10px;
    height: 10px;
}

section.notifications .box .title {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #001315;
    display: flex;
    align-items: center;
    margin-bottom: 6px;
}

section.notifications .box .date {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #80898A;
    margin-bottom: 12px;
}

section.notifications .box .description {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
}

section.notifications .box button {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #FF6A39;
    padding: 10px 10px 10px 20px;
    background: #FFFFFF;
    border: 1px solid #E5E7E8;
    box-sizing: border-box;
    border-radius: 12px;
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 20px;
}

section.notifications .box button svg {
    margin-left: auto;
}

section.notifications .empty-container {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #80898A;
    margin-top: 60px;
}
