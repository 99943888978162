.doctor-app section.consultation-details .patient-box .age {
    display: flex;
    align-items: center;
}
.doctor-app section.consultation-details .patient-box .requested-specialization {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #001315;
}
.doctor-app section.consultation-details .patient-box .age svg {
    margin-right: 4px;
    margin-left: 4px;
}
.doctor-app section.consultation-details .nav.nav-tabs {
    border: 0;
    display: flex;
    margin-left: -5px;
    margin-right: -5px;
    margin-bottom: 30px;
}
.doctor-app section.consultation-details .nav.nav-tabs .nav-item {
    flex: 1 1 auto;
    padding: 0 5px;
}
.doctor-app section.consultation-details .nav.nav-tabs .nav-item .nav-link {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #001315;
    background: #E5E7E8;
    border-radius: 10px;
    padding: 8px 20px;
    border: 0;
    cursor: pointer;
}
.doctor-app section.consultation-details .nav.nav-tabs .nav-item .nav-link.active {
    background: #FFFFFF;
}
.doctor-app section.consultation-details .start-consultation-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF!important;
    text-decoration: none!important;
}
.doctor-app section.consultation-details .start-consultation-btn svg {
    margin-right: 10px;
}
.doctor-app section.consultation-details .white-button.cancel-consultation-btn {
    padding: 14px 20px;
    line-height: 21px;
    min-height: 56px;
    color: #EB3300!important;
}
.doctor-app section.consultation-details .white-button.add-recommendation-btn {
    color: #00CFB4!important;
    margin-bottom: 10px;
}
.doctor-app section.consultation-details .info-message {
    color: #EB3300;
}
.doctor-app section.consultation-details .disease-box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 15px 20px;
}
.doctor-app section.consultation-details .disease-box .list-group {
    border-radius: 0;
}
.doctor-app section.consultation-details .disease-box .list-group-item {
    margin-bottom: 15px;
    border: 0;
    padding: 0 0 12px 0;
    border-bottom: 1px solid #F2F3F3;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.doctor-app section.consultation-details .disease-box .list-group-item .title {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #80898A;
    margin-right: auto;
    margin-bottom: 6px;
}
.doctor-app section.consultation-details .disease-box .list-group-item .details {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
}
.doctor-app section.consultation-details .disease-box .list-group-item:last-child {
    margin: 0;
    padding: 0;
    border: 0;
}
.doctor-app section.consultation-details .familiar-history-box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 18px 20px;
    margin-bottom: 10px;
}
.doctor-app section.consultation-details .familiar-history-box .title {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
    margin-bottom: 10px;
}
.doctor-app section.consultation-details .familiar-history-box .description {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 8px;
}
.doctor-app section.consultation-details .familiar-history-box button.read-all-btn {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #00CFB4;
    border: 0;
    background: none;
    margin: 0;
    padding: 0;
}
.doctor-app section.consultation-details .familiar-history-box > div:last-child {
    margin-bottom: 0;
}

section.consultation-details .folder-documents-box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}
section.consultation-details .folder-documents-box > .title {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
    margin-bottom: 17px;
    padding-bottom: 12px;
    border-bottom: 1px solid #F2F3F3;
}
section.consultation-details .folder-documents-box .document {
    display: flex;
    flex-direction: column;
}
section.consultation-details .folder-documents-box .document button {
    display: flex;
    align-items: center;
    position: relative;
    border: 0;
    padding: 0;
    margin: 13px 0;
    background: none;
    text-decoration: none!important;
}
section.consultation-details .folder-documents-box .document:nth-child(2) button {
    margin-top: 0;
}
section.consultation-details .folder-documents-box .document:last-child button {
    margin-bottom: 0;
}
section.consultation-details .folder-documents-box .document button .info {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
}
section.consultation-details .folder-documents-box .document button .info .name {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #001315!important;
    margin-bottom: 2px;
}
section.consultation-details .folder-documents-box .document button .info .data {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #80898A!important;
}
section.consultation-details .folder-documents-box .document button .custom-icon.document {
    margin-right: 16px;
    display: inline-flex;
}
section.consultation-details .folder-documents-box .document button .custom-icon.arrow {
    position: absolute;
    right: -8px;
    top: auto;
    bottom: auto;
}
section.consultation-details .folder-documents-box .list {
    display: flex;
}
section.consultation-details .folder-documents-box .list .avatar {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    display: inline-flex;
    width: 24px;
    height: 24px;
    min-width: 24px;
    border-radius: 100%;
    border: 1px solid rgba(0, 19, 21, 0.1);
    position: relative;
    margin-right: 16px;
}
section.consultation-details .folder-documents-box .list .details {
    display: inline-flex;
    flex-direction: column;
    width: 100%;
}
section.consultation-details .folder-documents-box .list .details .fullName {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 18px;
    color: #001315;
    margin-bottom: 4px;
}
section.consultation-details .folder-documents-box .list .details .data {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #80898A;
}
section.consultation-details .folder-documents-box .list .details .specialization {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #80898A;
}
section.consultation-details .folder-documents-box .list .details .item:first-child .title {
    border-top: 0;
    margin-top: 0;
    padding-top: 0;
}
section.consultation-details .folder-documents-box .list .details .item .title {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #80898A;
    border-top: 1px solid #F2F3F3;
    margin-bottom: 5px;
    margin-top: 10px;
    padding-top: 15px;
}
section.consultation-details .folder-documents-box .list .details .item .description {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
}
section.consultation-details .folder-documents-box .list .details .item .document {
    display: flex;
    align-items: center;
    position: relative;
    margin-top: 15px;
    text-decoration: none!important;
    flex-direction: row;
    cursor: pointer;
}
section.consultation-details .folder-documents-box .list .details .item .document .info {
    display: inline-flex;
    flex-direction: column;
}
section.consultation-details .folder-documents-box .list .details .item .document .info .name {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #001315!important;
    margin-bottom: 2px;
}
section.consultation-details .folder-documents-box .list .details .item .document .info .data {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #80898A!important;
}
section.consultation-details .folder-documents-box .list .details .item .document .custom-icon.document {
    margin-right: 16px;
    margin-top: 0;
    display: inline-flex;
}
section.consultation-details .folder-documents-box .list .details .item .document .custom-icon.arrow {
    position: absolute;
    right: -8px;
    top: auto;
    bottom: auto;
}

section.consultation-details .uploaded-box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 15px 20px;
    margin-bottom: 10px;
}
section.consultation-details .uploaded-box:last-child {
    margin-bottom: 0;
}
section.consultation-details .uploaded-file {
    display: flex;
    align-items: center;
    position: relative;
}
section.consultation-details .uploaded-file .circle.new-file {
    background: #FF6A39;
    border-radius: 10px;
    width: 10px;
    height: 10px;
    position: absolute;
    top: auto;
    bottom: auto;
    left: -13px;
}
section.consultation-details .uploaded-file .file-name {
    display: inline-flex;
    align-items: center;
    max-width: 295px;
    margin-right: auto;
}
section.consultation-details .uploaded-file .file-name span {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
section.consultation-details .uploaded-file .file-name svg {
    margin-right: 16px;
    min-width: 24px;
}
section.consultation-details .uploaded-file button {
    border: 0;
    padding: 0;
    margin: 0;
    background: none;
}

section.consultation-details .upload-files {
    width: 100%;
}
section.consultation-details .upload-files .files-box label {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 11px 20px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
}
section.consultation-details .upload-files .files-box:last-child label {
    margin: 0;
}
section.consultation-details .upload-files .files-box label .info-file {
    display: inline-flex;
    flex-direction: column;
    margin-right: auto;
}
section.consultation-details .upload-files .files-box label .info-file .title {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #00CFB4;
    margin-bottom: 2px;
}
section.consultation-details .upload-files .files-box label .info-file .details {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #80898A;
}
section.consultation-details .upload-files .files-box input {
    display: none;
}
section.consultation-details .custom-checkbox {
    margin-top: 20px;
    margin-bottom: 20px;
}

.modal-consultation-has-begun .patient-box {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.modal-consultation-has-begun .patient-box .avatar {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    width: 36px;
    height: 36px;
    border-radius: 100%;
    border: 1px solid rgba(0, 19, 21, 0.1);
    position: relative;
    margin-bottom: 6px;
}
.modal-consultation-has-begun .patient-box .info {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.modal-consultation-has-begun .patient-box .info .fullName {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
}
.modal-consultation-has-begun .patient-box .info .age {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #80898A;
    display: flex;
    align-items: center;
}
.modal-consultation-has-begun .patient-box .info .age svg {
    margin-right: 4px;
    margin-left: 4px;
}
.modal-consultation-has-begun .modal-footer .waiting-you-text {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #001315;
    margin-bottom: 14px;
}
.modal-consultation-has-begun .modal-footer button {
    display: flex;
    align-items: center;
    justify-content: center;
}
.modal-consultation-has-begun .modal-footer button svg {
    margin-right: 10px;
}

section.consultation-details .medic-box .action-buttons .medic-action-btn {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 1;
    color: #FFFFFF!important;
    text-decoration: none!important;
    background: #00CFB4;
    border-radius: 12px;
    padding: 11px 14px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    margin: 0 5px;
}

section.consultation-details .medic-box .action-buttons .offline-btn.medic-action-btn {
    pointer-events: none;
    padding: 14px;
    background: #F2F3F3!important;
    color: #80898A!important;
}
section.consultation-details .medic-box .action-buttons .busy-btn.medic-action-btn
{
    padding: 14px;
    color: #F4AF23 !important;
    background: #FEF5D9;
    pointer-events: none;
}
section.video-call .empty-container,
section.consultation-details .empty-container {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #80898A;
    margin-top: 60px;
}

section.consultation-details .btn-add-medication,
section.consultation-details .btn-cancel-prescription {
    min-height: 56px;
    border-color: #FFFFFF;
}

.btn-cancel-prescription {
    min-height: 56px;
    padding: 14px 12px !important;
}

.medical-recommendation {
    margin-bottom: 10px;
    border: 0;
    padding: 0 0 7px 0;
    border-bottom: 1px solid #F2F3F3;
    align-items: center;
}

.medical-recommendation .title {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #80898A;
    margin-right: auto;
}

.medical-recommendation .details {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #001315;
}

.medical-recommendation:last-child {
    margin: 0;
    padding: 0;
    border: 0;
}

@media (max-width: 767px) {
    .btn-cancel-prescription, .btn-send-conclusions {
        min-height: 74px;
    }
}