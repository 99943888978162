section.account .account-cards button.white-button {
    margin-bottom: 20px;
    min-height: auto;
    height: 46px;
    line-height: 27px;
    padding: 8px 20px;
    display: flex;
    justify-content: center;
    border: 1px solid #E5E7E8;
}
section.account .account-cards button.white-button svg {
    margin-right: 16px;
}
section.account .account-cards .saved-cards .card-box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 13px 20px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    border: 1px solid #E5E7E8;
}
section.account .account-cards .saved-cards .card-box:last-child {
    margin-bottom: 0;
}
section.account .account-cards .saved-cards .card-box .details {
    display: flex;
    align-items: center;
    margin-right: auto;
}
section.account .account-cards .saved-cards .card-box .details img {
    margin-right: 20px;
}
section.account .account-cards .saved-cards .card-box .details .number {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
    margin-bottom: 2px;
}
section.account .account-cards .saved-cards .card-box .details .date {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #80898A;
}
.voucher-info.pointer {
    cursor: pointer;
}

section.appointment-steps .voucher-button {
    display: flex;
    flex-direction: column;
}

section.appointment-steps .voucher-button .voucher-button-style {
    padding: 11px 20px;
}

.invoicing-address .subtitle {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #80898A;
    margin-top: 20px;
    margin-bottom: 10px;
}