section.final-consultation {
    max-width: 400px;
    margin: auto;
}
section.final-consultation .title-section {
    margin-top: 40px;
    margin-bottom: 40px;
}
section.final-consultation .title-section .small-title-text {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #001315;
}
section.final-consultation .title-section h1 {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 110%;
    text-align: center;
    color: #001315;
    margin: 0;
}

section.final-consultation .patient-box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 12px 20px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}
section.final-consultation .patient-box .avatar {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    width: 36px;
    height: 36px;
    border-radius: 100%;
    border: 1px solid rgba(0, 19, 21, 0.1);
    position: relative;
    margin-right: 20px;
}
section.final-consultation .patient-box .avatar-section {
    margin-right: 20px;
}
section.final-consultation .patient-box .avatar-section .user-image {
    margin: 0!important;
}
section.final-consultation .patient-box .avatar-section .avatar-picture {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    width: 36px;
    height: 36px;
    border-radius: 100%;
    border: 1px solid rgba(0, 19, 21, 0.1);
    position: relative;
    margin-right: 20px;
    position: relative;
}
section.final-consultation .patient-box .avatar-section .user-text {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Rota';
    font-style: normal;
    font-weight: 700;
    font-size: 16px!important;
    line-height: 20px!important;
    text-align: center;
    color: #80898A!important;
    width: 36px;
    height: 36px;
    border-radius: 100%;
    border: 1px solid rgba(0, 19, 21, 0.1);
}
section.final-consultation .patient-box .fullName {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
}
section.final-consultation .patient-box .info .age {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #80898A;
    display: flex;
    align-items: center;
}
section.final-consultation .patient-box .info .age svg {
    margin-right: 4px;
    margin-left: 4px;
}

section.final-consultation .nav.nav-tabs {
    border: 0;
    display: flex;
    margin-left: -5px;
    margin-right: -5px;
    margin-bottom: 30px;
}
section.final-consultation .nav.nav-tabs .nav-item {
    flex: 1 1 auto;
    padding: 0 5px;
}
section.final-consultation .nav.nav-tabs .nav-item .nav-link {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #001315;
    background: #E5E7E8;
    border-radius: 10px;
    padding: 8px 20px;
    border: 0;
    cursor: pointer;
}
section.final-consultation .nav.nav-tabs .nav-item .nav-link.active {
    background: #FFFFFF;
}

section.final-consultation .uploaded-box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 15px 20px;
    margin-bottom: 10px;
}
section.final-consultation .uploaded-box:last-child {
    margin-bottom: 0;
}
section.final-consultation .uploaded-file {
    display: flex;
    align-items: center;
    position: relative;
}
section.final-consultation .uploaded-file .circle.new-file {
    background: #FF6A39;
    border-radius: 10px;
    width: 10px;
    height: 10px;
    position: absolute;
    top: auto;
    bottom: auto;
    left: -13px;
}
section.final-consultation .uploaded-file .file-name {
    display: inline-flex;
    align-items: center;
    max-width: 295px;
    margin-right: auto;
}
section.final-consultation .uploaded-file .file-name span {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
section.final-consultation .uploaded-file .file-name svg {
    margin-right: 16px;
    min-width: 24px;
}
section.final-consultation .uploaded-file button {
    border: 0;
    padding: 0;
    margin: 0;
    background: none;
}

section.final-consultation .upload-files {
    width: 100%;
    margin-bottom: 10px;
}
section.final-consultation .upload-files .files-box label {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 11px 20px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
}
section.final-consultation .upload-files .files-box:last-child label {
    margin: 0;
}
section.final-consultation .upload-files .files-box label .info-file {
    display: inline-flex;
    flex-direction: column;
    margin-right: auto;
}
section.final-consultation .upload-files .files-box label .info-file .title {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #00CFB4;
    margin-bottom: 2px;
}
section.final-consultation .upload-files .files-box label .info-file .details {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #80898A;
}
section.final-consultation .upload-files .files-box input {
    display: none;
}

section.final-consultation .custom-checkbox {
    margin-top: 20px;
    margin-bottom: 20px;
}

section.final-consultation .subtitle {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #80898A;
    margin-top: 20px;
    margin-bottom: 10px;
}
section.final-consultation .details-box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 15px 20px;
}
section.final-consultation .details-box .list-group {
    border-radius: 0;
}
section.final-consultation .details-box .list-group-item {
    margin-bottom: 10px;
    border: 0;
    padding: 0 0 7px 0;
    border-bottom: 1px solid #F2F3F3;
    display: flex;
    align-items: center;
}
section.final-consultation .details-box .list-group-item .title {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #80898A;
    margin-right: auto;
}
section.final-consultation .details-box .list-group-item .details {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: right;
    color: #001315;
}
section.final-consultation .details-box .list-group-item:last-child {
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 0;
}
section.final-consultation .disease-box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 15px 20px;
}
section.final-consultation .disease-box .list-group {
    border-radius: 0;
}
section.final-consultation .disease-box .list-group-item {
    margin-bottom: 15px;
    border: 0;
    padding: 0 0 12px 0;
    border-bottom: 1px solid #F2F3F3;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
section.final-consultation .disease-box .list-group-item .title {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #80898A;
    margin-right: auto;
    margin-bottom: 6px;
}
section.final-consultation .disease-box .list-group-item .details {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
}
section.final-consultation .disease-box .list-group-item:last-child {
    margin: 0;
    padding: 0;
    border: 0;
}
section.final-consultation .familiar-history-box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 18px 20px;
    margin-bottom: 10px;
}
section.final-consultation .familiar-history-box .title {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
    margin-bottom: 10px;
}
section.final-consultation .familiar-history-box .description {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 8px;
}
section.final-consultation .familiar-history-box button.read-all-btn {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #00CFB4;
    border: 0;
    background: none;
    margin: 0;
    padding: 0;
}
section.final-consultation .familiar-history-box > div:last-child {
    margin-bottom: 0;
}

section.final-consultation .folder-documents-box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}
section.final-consultation .empty-container {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #80898A;
    margin-top: 20px;
}
section.final-consultation .folder-documents-box > .title {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
    margin-bottom: 17px;
    padding-bottom: 12px;
    border-bottom: 1px solid #F2F3F3;
}
section.final-consultation .folder-documents-box .document {
    display: flex;
    flex-direction: column;
}
section.final-consultation .folder-documents-box .document button {
    display: flex;
    align-items: center;
    position: relative;
    border: 0;
    padding: 0;
    margin: 13px 0;
    background: none;
    text-decoration: none!important;
}
section.final-consultation .folder-documents-box .document:nth-child(2) button {
    margin-top: 0;
}
section.final-consultation .folder-documents-box .document:last-child button {
    margin-bottom: 0;
}
section.final-consultation .folder-documents-box .document button .info {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
}
section.final-consultation .folder-documents-box .document button .info .name {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #001315!important;
    margin-bottom: 2px;
}
section.final-consultation .folder-documents-box .document button .info .data {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #80898A!important;
}
section.final-consultation .folder-documents-box .document button .custom-icon.document {
    margin-right: 16px;
    display: inline-flex;
}
section.final-consultation .folder-documents-box .document button .custom-icon.arrow {
    position: absolute;
    right: -8px;
    top: auto;
    bottom: auto;
}
section.final-consultation .folder-documents-box .list {
    display: flex;
}
section.final-consultation .folder-documents-box .list .avatar {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    display: inline-flex;
    width: 24px;
    height: 24px;
    min-width: 24px;
    border-radius: 100%;
    border: 1px solid rgba(0, 19, 21, 0.1);
    position: relative;
    margin-right: 16px;
}
section.final-consultation .folder-documents-box .list .details {
    display: inline-flex;
    flex-direction: column;
}
section.final-consultation .folder-documents-box .list .details .fullName {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 18px;
    color: #001315;
    margin-bottom: 4px;
}
section.final-consultation .folder-documents-box .list .details .data {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #80898A;
}
section.final-consultation .folder-documents-box .list .details .specialization {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #80898A;
}
section.final-consultation .folder-documents-box .list .details .item:first-child .title {
    border-top: 0;
    margin-top: 0;
    padding-top: 0;
}
section.final-consultation .folder-documents-box .list .details .item .title {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #80898A;
    border-top: 1px solid #F2F3F3;
    margin-bottom: 5px;
    margin-top: 10px;
    padding-top: 15px;
}
section.final-consultation .folder-documents-box .list .details .item .description {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
}
section.final-consultation .folder-documents-box .list .details .item .document {
    display: flex;
    align-items: center;
    position: relative;
    margin-top: 15px;
    text-decoration: none!important;
    flex-direction: row;
    cursor: pointer;
}
section.final-consultation .folder-documents-box .list .details .item .document .info {
    display: inline-flex;
    flex-direction: column;
}
section.final-consultation .folder-documents-box .list .details .item .document .info .name {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #001315!important;
    margin-bottom: 2px;
}
section.final-consultation .folder-documents-box .list .details .item .document .info .data {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #80898A!important;
}
section.final-consultation .folder-documents-box .list .details .item .document .custom-icon.document {
    margin-right: 16px;
    margin-top: 0;
    display: inline-flex;
}
section.final-consultation .folder-documents-box .list .details .item .document .custom-icon.arrow {
    position: absolute;
    right: -8px;
    top: auto;
    bottom: auto;
}
section.final-consultation .textarea-autoheight {
    overflow: hidden;
}

.modal-confirmation .modal-body p {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
    margin: 0;
}
.modal-confirmation .modal-body .actions {
    display: flex;
    align-items: center;
    margin-top: 15px;
}
.modal-confirmation .modal-body .actions .white-button {
    max-width: 109px;
    margin-right: 10px;
}