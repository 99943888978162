section.account .account-transaction-history .box {
    padding: 17px 20px;
    background: #FFFFFF;
    border-radius: 12px;
    margin-bottom: 10px;
    border: 1px solid #E5E7E8;
}
section.account .account-transaction-history .transaction:last-child .box:last-child {
    margin-bottom: 0;
}
section.account .account-transaction-history .header {
    display: flex;
    align-items: center;
    text-decoration: none!important;
}
section.account .account-transaction-history .header .details {
    display: inline-flex;
    margin-right: auto;
    flex-direction: column;
}
section.account .account-transaction-history .header .details .title {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #80898A;
    margin-bottom: 2px;
}
section.account .account-transaction-history .header .details .fullName {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
    margin-bottom: 2px;
}
section.account .account-transaction-history .header .details .date {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #80898A;
}
section.account .account-transaction-history .header svg {
    margin-right: -8px;
}
section.account .account-transaction-history .footer {
    display: flex;
    align-items: center;
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid #F2F3F3;
}
section.account .account-transaction-history .footer .card-info {
    display: inline-flex;
    align-items: center;
    margin-right: auto;
}
section.account .account-transaction-history .footer .card-info img {
    margin-right: 10px;
}
section.account .account-transaction-history .footer .card-info span {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #80898A;
}
section.account .account-transaction-history .footer .price {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-align: right;
    color: #001315;
}
