.support-menu {
    padding-top: 5px;
    font-family: "Rota";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #80898A;
    text-decoration: none;
}

.phone-number-suport {
    margin-left: 10px;
}

.container:has(.main-screen-page-is-landing) {
    max-width: 100% !important;
    padding: 0px;
}

.main-screen-gradient1 {
    background-image: url("../../shared/assets/images/main-screen-gradient1.jpg");
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
    width: 100%;
}

.main-screen-gradient1 h1 {
    font-family: Rota, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 64px;
    line-height: 110%;
    color: #ffffff;
    width: 70%;
}

.main-screen-gradient1 h4 {
    font-family: Rota, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 110%;
    color: #ffffff;
    width: 70%;
}

.main-screen-gradient2 .specializations-carousel .specializations-carousel-item .specializations-carousel-item-content {
    width: 100%;
    max-width: 115px;
}

.main-screen-gradient2 .specializations-carousel .specializations-carousel-item .specializations-carousel-item-content > div[style^="position: relative"] {
    width: 100%;
}

.homepage-benefits .benefit-item .benefit-img-wrapper > div {
    width: 62px;
}

@media (max-width: 1399px) {
    .main-screen-gradient1 h1 {
        font-size: 42px;
    }

    .main-screen-gradient1 h4 {
        font-size: 26px;
    }
}

@media (max-width: 1199px) {
    .main-screen-gradient1 h1 {
        font-size: 32px;
    }

    .main-screen-gradient1 h4 {
        font-size: 18px;
    }

    .main-screen-gradient1 .green-button .nr-of-doctors {
        font-size: 16px;
        line-height: 20px;
    }
}

.main-screen-gradient1 p {
    font-family: Rota, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 110%;
    color: #ffffff;
}

.main-screen-gradient1 .main-screen-gradient1-row1,
.main-screen-gradient1 .how-it-works-wrapper {
    padding-top: 50px;
    margin: 0;
}

.main-screen-gradient1 .main-screen-gradient1-row1 .col-md-6:first-of-type {
    padding-left: 10%;
}

.patient-main-screen-row1-image {
    display: flex;
    align-items: center;
}

.patient-main-screen-row1, .how-it-works-img {
    width: 100%;
    display: block;
}

.main-screen-gradient1 .green-button {
    margin: 24px 0 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 397px;
    box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.2), inset 0 0 0 2px rgba(255, 255, 255, 0.5);
    padding-left: 60px;
    padding-right: 60px;
}

.main-screen-gradient1 .green-button .btn-text {
    text-align: left;
}

.main-screen-gradient1 .green-button .custom-icon {
    margin-right: 20px;
}

.main-screen-gradient1 .green-button .see-all-doctors {
    font-family: 'Rota';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 32px;
    color: #FFFFFF;
}

.main-screen-gradient1 .green-button .nr-of-doctors {
    font-family: 'Rota';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
}

.main-screen-gradient1 .app-store-icon {
    background-image: url("../../shared/assets/images/app-store-icon-white.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    width: 143px;
    height: 49px;
    display: inline-flex;
    margin-right: 14px;
    cursor: pointer;
}

.main-screen-gradient1 .google-play-icon {
    background-image: url("../../shared/assets/images/google-play-icon-white.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    width: 159px;
    height: 49px;
    display: inline-flex;
    cursor: pointer;
}

.how-it-works-wrapper .container {
    background-color: #ffffff;
    border-radius: 12px;
    max-width: 1340px !important;
}

.main-screen-gradient1 .how-it-works-wrapper h1 {
    font-family: Rota, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 64px;
    line-height: 110%;
    color: #000000;
    width: 100%;
    text-align: center;
    margin-top: 60px;
    margin-bottom: 20px;
}

.main-screen-gradient1 .how-it-works-wrapper .box {
    border-radius: 20px;
    padding: 10px 10px 10px 50px;
    margin-bottom: 20px;
}

.main-screen-gradient1 .how-it-works-wrapper .box .description {
    font-family: Rota, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 110%;
    color: #001315;
    margin: 10px 0;
}

.main-screen-gradient1 .how-it-works-wrapper .box .description2 {
    font-family: Rota, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 110%;
    color: #001315;
    margin-bottom: 10px;
}

.main-screen-gradient1 .how-it-works-wrapper .box .circle {
    border-radius: 100%;
    width: 50px;
    height: 50px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-family: Rota, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 110%;
    text-align: center;
    color: #FFFFFF;
}

.main-screen-gradient1 .how-it-works-wrapper .box .circle.one {
    background: #FF6A39;
}

.main-screen-gradient1 .how-it-works-wrapper .box .circle.two {
    background: #EDE939;
}

.main-screen-gradient1 .how-it-works-wrapper .box .circle.three {
    background: #00CFB4;
}

.main-screen-gradient1 .how-it-works-wrapper .box .circle.two span {
    color: #001315;
    height: 28px;
}

.main-screen-gradient1 .how-it-works-wrapper .box .circle.one span,
.main-screen-gradient1 .how-it-works-wrapper .box .circle.three span {
    color: #ffffff;
    height: 28px;
}

.main-screen-gradient1 .how-it-works-wrapper .box.box1 {
    background: #FFF0EB;
    border: 1px solid rgba(0, 19, 21, 0.05);
}

.main-screen-gradient1 .how-it-works-wrapper .box.box2 {
    background: #FDFDEB;
    border: 1px solid rgba(0, 19, 21, 0.05);
}

.main-screen-gradient1 .how-it-works-wrapper .box.box3 {
    background: #E5FAF7;
    border: 1px solid rgba(0, 19, 21, 0.05);
}

.main-screen-gradient1 .how-it-works-wrapper .box .app-store-icon {
    background-image: url("../../shared/assets/images/app-store-download-icon-black.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    width: 100%;
    max-width: 150px;
    height: 43px;
    display: inline-flex;
    cursor: pointer;
}

.main-screen-gradient1 .how-it-works-wrapper .box .google-play-icon {
    background-image: url("../../shared/assets/images/google-play-download-icon-black.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    width: 100%;
    max-width: 150px;
    height: 43px;
    display: inline-flex;
    cursor: pointer;
}

.main-screen-gradient2 {
    background-image: url("../../shared/assets/images/main-screen-gradient2.jpg");
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
    width: 100%;
}

.main-screen-gradient2 .container,
.main-screen-gradient3 .container {
    max-width: 1340px !important;
}

.main-screen-gradient2 .accordion-item {
    /*min-width: 400px;*/
}

.main-screen-gradient2 h1 {
    margin: 0 auto;
    padding: 50px 0;
    /*width: 70%;*/
    text-align: center;
    font-family: Rota, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 64px;
    line-height: 110%;
    color: #000000;
    max-width: 720px;
}

.main-screen-gradient2 .main-screen-gradient2-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.main-screen-gradient2 .demo-img {
    display: block;
    width: 100%;
    max-width: 460px;
}

.main-screen-gradient2 .box-white .circle {
    border-radius: 100%;
    width: 62px;
    min-width: 62px;
    height: 62px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-family: Rota, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 110%;
    text-align: center;
    color: #FFFFFF;
    margin-right: 20px;
}

.main-screen-gradient2 .box-white button[aria-expanded="false"] .circle {
    background: #F2F3F3;
}

.main-screen-gradient2 .box-white button[aria-expanded="true"] .circle {
    background: #00CFB4;
}

.main-screen-gradient2 .box-white button[aria-expanded="false"] .circle span {
    color: #001315;
    height: 28px;
}

.main-screen-gradient2 .box-white button[aria-expanded="true"] .circle span {
    color: #ffffff;
    height: 28px;
}

.main-screen-gradient2 .box-white .description {
    font-family: Rota, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 110%;
    color: #001315;
}

.main-screen-gradient2 .box-white .accordion-button:not(.collapsed) {
    background-color: transparent;
    box-shadow: none;
}

.main-screen-gradient2 .homepage-specializations {
    background-color: #fff;
    border-radius: 12px;
    margin-top: 100px;
    padding-bottom: 50px;
}

.main-screen-gradient2 .homepage-specializations h1 {
    margin: 0 auto;
    padding: 50px 0;
    /*width: 70%;*/
    text-align: center;
    font-family: Rota, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 64px;
    line-height: 110%;
    color: #000000;
}

.main-screen-gradient2 .specializations-carousel .specializations-carousel-item {
    background: #F2F2F3;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px 0;
    cursor: pointer;
    height: 250px;
}

.main-screen-gradient2 .specializations-carousel .specializations-carousel-item .specializations-carousel-item-content {
    padding-left: 20px;
    padding-right: 20px;
}

.specializations-carousel-item-hover {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
}

.main-screen-gradient2 .specializations-carousel .specializations-carousel-item-hover img {
    padding-bottom: 0;
}

.main-screen-gradient2 .specializations-carousel .specializations-carousel-item-hover .name {
    margin: 14px 0;
}

.hoverable-show {
    display: none;
}

.hoverable-hide {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.hoverable-item:hover .hoverable-show {
    display: block;
}

.hoverable-item:hover .hoverable-hide {
    display: none;
}


.main-screen-gradient2 .specializations-carousel .specializations-carousel-item .name {
    font-family: Rota, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 110%;
    text-align: center;
    color: #001315;
}

.main-screen-gradient2 .specializations-carousel .specializations-carousel-item img {
    height: 80px;
    padding-bottom: 15px;
}

.main-screen-gradient2 .specializations-carousel .specializations-carousel-item .specializations-carousel-item-hover img {
    object-fit: contain;
}

.how-it-works-wrapper .specializations-carousel .next-slider,
.main-screen-gradient2 .specializations-carousel .next-slider,
.main-screen-gradient3 .feedback-carousel .next-slider,
.main-screen-gradient3 .defiro-carousel .next-slider {
    position: absolute;
    top: calc(50% - 18px);
    height: 35px;
    width: 35px;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 0 5px 0 #0009;
    z-index: 10;
    cursor: pointer;
    font-size: 10px;
    opacity: 0.6;
    -webkit-transition: opacity 0.25s;
    transition: opacity 0.25s;
    left: initial;
    right: 5px;
    align-items: center;
    justify-content: center;
    display: flex;
}

.main-screen-gradient3 .next-slider .custom-icon,
.main-screen-gradient3 .prev-slider .custom-icon {
    margin-right: 0;
}

.how-it-works-wrapper .specializations-carousel .prev-slider,
.main-screen-gradient2 .specializations-carousel .prev-slider,
.main-screen-gradient3 .defiro-carousel .prev-slider,
.main-screen-gradient3 .feedback-carousel .prev-slider {
    position: absolute;
    top: calc(50% - 18px);
    height: 35px;
    width: 35px;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 0 5px 0 #0009;
    z-index: 10;
    cursor: pointer;
    font-size: 10px;
    opacity: 0.6;
    transition: opacity 0.25s;
    left: 5px;
    right: initial;
    align-items: center;
    justify-content: center;
    display: flex;
}

.homepage-benefits .benefit-collection {
    display: flex;
    flex-wrap: wrap;
    padding: 0px;
    justify-content: space-between;
    margin-right: 0px;
    margin-left: 0px;
}

.homepage-benefits .benefit-item {
    align-items: center;
    background: #fff;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 20px;
    padding: 56px 66px;
    text-align: center;
    max-width: 413px;
    height: 100%
}

.benefit-item-container {
    padding-bottom: 20px;
}

@media (max-width: 1024px) {
    .homepage-benefits .benefit-item {
        padding: 40px 20px;
    }
}

.homepage-benefits .benefit-item .benefit-img-wrapper {
    margin-bottom: 15px;
    border-radius: 100%;
    width: 132px;
    height: 132px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.homepage-benefits .benefit-item .title {
    display: block;
    font-family: Rota, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 110%;
    text-align: center;
    color: #001315;
}

.main-screen-gradient2 .homepage-benefits h1 {
    margin: 0 auto;
    padding: 90px 0 50px;
    text-align: center;
    font-family: Rota, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 64px;
    line-height: 110%;
    color: #000000;
}

.main-screen-gradient3 {
    background-image: url("../../shared/assets/images/main-screen-gradient3.jpg");
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
}

.homepage-permanent-access {
    background-color: #ffffff;
    border-radius: 12px;
    padding: 40px;
}

.homepage-permanent-access p {
    font-family: Rota, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 110%;
    text-align: center;
    color: #001315;
    width: 100%;
}

.homepage-permanent-access .orange-button {
    width: 30%;
    margin: 0 auto;
}

.main-screen-page .custom-icon {
    margin-right: 0;
}

.homepage-feedback h1 {
    margin: 0 auto;
    padding: 90px 0 50px;
    text-align: center;
    font-family: Rota, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 64px;
    line-height: 110%;
    color: #000000;
}

.review-carousel-item, .defiro-carousel-product {
    background: #FFFFFF;
    border-radius: 30px;
    padding: 20px 30px;
}

.review-carousel-item .review-details p {
    font-family: Rota, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    color: #FF6A39;
}

.review-carousel-item .review-details p span {
    font-family: Rota, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    color: #80898A;
}

.review-carousel-item .stars {
    margin-right: 0;
    padding-right: 0;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.review-carousel-item .avatar {
    align-items: center;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border: 1px solid rgba(0, 19, 21, .1);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    height: 35px;
    width: 35px;
}

.review-carousel-item .avatar > div:not(.image-placeholder) {
    width: 100%;
}

.review-carousel-item .avatar img {
    object-fit: cover;
    border-radius: 50%;
}

.review-message {
    font-family: Rota, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 25px;
    color: #001315;
}

.main-screen-page .review-carousel-item svg.custom-icon {
    margin-right: 0;
}

.review-carousel-item .review-doctor-details p {
    margin-bottom: 0;
    font-family: Rota, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #001315;
}

.review-carousel-item .review-doctor-details p.smaller-text {
    margin-bottom: 0;
    font-family: Rota, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
    color: #001315;
}

.homepage-newsletter {
    margin-top: 80px;
    background-color: #ffffff;
    border-radius: 12px;
    padding: 40px;
}

.homepage-newsletter img {
    height: 80px;
    display: block;
    margin-bottom: 20px;
}

.homepage-newsletter h1 {
    font-family: Rota, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 64px;
    line-height: 110%;
    text-align: center;
    color: #001315;
    margin-bottom: 40px;
}

.homepage-newsletter h4 {
    font-family: Rota, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 110%;
    text-align: center;
    color: #001315;
    margin: 30px auto;
    max-width: 814px;
}

.homepage-newsletter p {
    font-family: Rota, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 110%;
    text-align: center;
    color: #BFC4C5;
    margin-top: 20px;
}

.homepage-newsletter .orange-button {
    position: absolute;
    top: 4%;
    width: auto;
    right: 20.5%;
    padding: 10px;
    min-height: auto;
}

.mailchimp-form {
    margin: auto;
    width: 60%;
    position: relative;
}

.mailchimp-form {
    display: flex;
    flex-direction: column-reverse;

}

.mailchimp-form .success-response {
    font-family: Rota, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 110%;
    color: #00CBB2;
    padding-top: 10px;
}

.mailchimp-form .error-response {
    font-family: Rota, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 110%;
    color: #EB3300;
    padding-top: 10px;
}

.mailchimp-form .mailchimp-form-button {
    background-color: #FF6A39;
    border-radius: 12px;
    font-family: Rota, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    color: #FFFFFF;
    border: 2px solid #FF6A39;
    text-decoration: none;
    position: absolute;
    top: 3px;
    width: auto;
    right: 15px;
    padding: 10px;
    min-height: auto;
}

.mailchimp-form .mailchimp-form-status {
    font-family: Rota, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: #00CFB4;
}

.homepage-doctor-section {
    margin-top: 80px;
    background-color: #ffffff;
    border-radius: 12px;
    padding: 40px;
    display: flex;
    flex-direction: column;
}

.homepage-doctor-section .homepage-doctor-section-logo {
    display: block;
    max-width: 300px;
    margin: 0 auto;
    padding-bottom: 30px;
}

.homepage-doctor-section h1 {
    font-family: Rota, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 64px;
    line-height: 110%;
    text-align: center;
    color: #001315;
    margin: 40px auto;
    max-width: 712px;
}

.homepage-doctor-section .green-button {
    width: auto;
    margin: 0 auto 40px;
}

.demo-image-wrapper {
    text-align: center;
}

@supports (not (-ms-ime-align:auto)) {
    .homepage-newsletter input.form-control {
        font-family: Rota, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #001315;
        padding: 19px 20px;
        height: 56px !important;
        transition: all 0.2s ease-out;
        background: #FFFFFF;
        border: 1px solid #E5E7E8;
        border-radius: 15px;
        width: 60%;
        margin: 0 auto;
    }

    .homepage-newsletter input.form-control + label {
        font-family: Rota, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #80898A;
        padding-left: 20px;
        position: absolute;
        top: 18px;
        width: auto;
        left: 21%;
        transition: all 0.2s ease-out;
    }
}

.accordion-wrapper-div {
    width: 100%;
    max-width: 606px;
    border-radius: 12px;
    background-color: #FFFFFF;
    padding: 10px;
}

.main-screen-gradient2 .box-white .accordion-button {
    padding: 16px 30px;
}

.accordion-wrapper-div .accordion-item {
    border-color: #FFFFFF !important;
}

.accordion-wrapper-div .accordion-item .accordion-button.collapsed {
    border-bottom: 1px solid #E5E7E8;
}

.accordion-wrapper-div .accordion-item:last-child .accordion-button.collapsed {
    border-bottom: none;
}

.main-screen-gradient2 .accordion-body {
    font-family: 'Rota';
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 110%;
    padding: 0px 30px 30px;
}

.how-it-works-wrapper-play-video-relative {
    position: relative;
    width: 100%;
}

.how-it-works-wrapper-play-video {
    position: absolute;
    right: 0;
    margin-top: 60px;
    padding-right: 20px;
    padding-top: 16px;
    font-family: Rota, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 110%;
    cursor: pointer;
}

.how-it-works-wrapper-play-video-center-notmobile {
    width: 100%;
    text-align: center;
    font-family: Rota, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 110%;
    cursor: pointer;
    margin-bottom: 30px;
}

.how-it-works-wrapper-play-video-center {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
}

.vezi-video-ml {
    margin-left: 10px;
}

@media (max-width: 767px) {
    .main-screen-gradient2 .homepage-benefits h1 {
        padding: 40px 0;
        text-align: center;
        font-family: Rota, sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        line-height: 110%;
        color: #000000;
    }

    .homepage-benefits .row {
        margin: 0;
        padding: 0;
    }

    .homepage-benefits .benefit-item {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    .homepage-benefits .benefit-item .benefit-img-wrapper {
        width: 100px;
        height: 100px;
    }

    .homepage-permanent-access {
        padding: 35px 53px;
    }

    .homepage-permanent-access p {
        font-size: 24px;
        line-height: 110%;
        width: 100%;
    }

    .homepage-permanent-access .orange-button {
        width: 100%;
    }

    .homepage-feedback h1 {
        font-size: 32px;
        padding-left: 40px;
        padding-right: 40px;
    }

    .homepage-newsletter {
        padding: 40px 20px;
    }

    .homepage-newsletter img {
        height: 70px;
    }

    .homepage-newsletter h1 {
        font-size: 32px;
    }

    .homepage-newsletter h4 {
        font-size: 20px;
        line-height: 25px;
        text-align: center;
        margin-bottom: 20px;
        padding-left: 0;
        padding-right: 0;
    }

    .homepage-newsletter form {
        padding-left: 0;
        padding-right: 0;
    }

    .demo-image-wrapper .demo-img-mobile {
        max-width: 100%;
    }

    @supports (not (-ms-ime-align:auto)) {
        .homepage-newsletter input.form-control {
            width: 100%;
            text-align: left;
        }

        .homepage-newsletter .mailchimp-form .mailchimp-form-email-input {
            font-family: Rota, sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            color: #001315;
            padding: 19px 20px;
            height: 56px !important;
            transition: all 0.2s ease-out;
            background: #FFFFFF;
            border: 1px solid #E5E7E8;
            border-radius: 15px;
            width: 100%;
            margin: 0 auto;
        }

        .homepage-newsletter .mailchimp-form .mailchimp-form-email-input {
            display: block;
        }

        .homepage-newsletter .mailchimp-form .mailchimp-form-email-input {
            border: 1px solid #E5E7E8;
        }

        .homepage-newsletter input.form-control + label {
            left: 1%;
        }

        .homepage-newsletter .orange-button {
            right: 1%;
        }
    }

    .homepage-doctor-section {
        padding-left: 20px;
        padding-right: 0;
    }

    .homepage-doctor-section .homepage-doctor-section-logo {
        padding-right: 20px;
    }

    .homepage-doctor-section .homepage-doctor-section-img {
        padding-right: 0;
    }

    .homepage-doctor-section h1 {
        font-size: 32px;
        margin-bottom: 26px;
        padding-right: 20px;
    }

    .homepage-doctor-section .green-button {
        width: calc(100% - 20px);
        margin: 0 0 40px;
    }

    .main-screen-gradient2 .homepage-specializations h1 {
        font-size: 32px;
        padding: 40px 0 30px;
    }

    .main-screen-gradient1 .main-screen-gradient1-row1 {
        /*padding-top: 30%;*/
    }

    .main-screen-gradient1 .main-screen-gradient1-row1 .col-md-6:first-of-type {
        padding-right: 20px;
        padding-left: 20px;
    }

    .main-screen-gradient1 h1 {
        font-size: 36px;
        width: 100%;
        text-align: center;
    }

    .main-screen-gradient1 h4 {
        font-size: 18px;
        width: 100%;
        text-align: center;
    }

    .main-screen-gradient1 .green-button {
        width: 100%;
        margin: 24px 0 16px;
    }

    .main-screen-gradient1 p {
        display: none;
    }

    .main-screen-gradient1 .app-store-icon {
        height: 44px;
        width: 128px;
    }

    .main-screen-gradient1 .google-play-icon {
        height: 44px;
        width: 142px;
    }

    .apps-button {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;

    }

    .apps-button a {
        width: 50%;
        display: flex;
        justify-content: flex-start;
    }

    .main-screen-gradient1-row1 .apps-button {
        margin-bottom: 20px;
    }

    .how-it-works-wrapper :not(.specializations-carousel) .apps-button a {
        margin-bottom: 10px;
    }

    .apps-button a:first-child {
        margin-right: 10px;
        justify-content: flex-end;
    }

    .main-screen-gradient1 .how-it-works-wrapper h1 {
        font-size: 32px;
        text-align: center;
        margin-top: 40px;
        margin-bottom: 30px;
    }

    .main-screen-gradient1 .how-it-works-wrapper .box {
        padding: 20px;
        height: auto;
        align-items: center;
        display: flex;
        justify-content: center;
    }

    .main-screen-gradient1 .how-it-works-wrapper .specializations-carousel .box {
        height: 100%;
    }

    .specializations-carousel .box .row {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .specializations-carousel .box .row .col-12:not(.justify-content-center) {
        flex-grow: 0;
        flex-shrink: 1;
    }

    .specializations-carousel .box .row .col-12.justify-content-center {
        flex-grow: 1;
        flex-shrink: 0;
        justify-content: flex-start !important;
        margin-bottom: 10px;
    }

    .main-screen-gradient2 h1 {
        padding: 40px 20px 30px;
        font-size: 32px;
        line-height: 35px;
    }

    .demo-img-mobile {
        margin-top: 20px;
        width: 100%;
    }

    .main-screen-gradient2 .homepage-specializations {
        padding-bottom: 100px;
    }

    .main-screen-gradient2 .specializations-carousel .next-slider {
        bottom: -80px;
        top: auto;
        right: 30%;
        height: 56px;
        width: 56px;
    }

    .main-screen-gradient2 .specializations-carousel .prev-slider {
        bottom: -80px;
        top: auto;
        left: 30%;
        height: 56px;
        width: 56px;
    }

    .main-screen-gradient3 .feedback-carousel {
        padding-bottom: 25px;
    }

    .main-screen-gradient3 .defiro-carousel {
        padding-bottom: 25px;
    }

    .how-it-works-wrapper .specializations-carousel {
        padding-bottom: 100px;
    }

    .main-screen-gradient3 .feedback-carousel .next-slider {
        bottom: -80px;
        top: auto;
        right: 30%;
        height: 56px;
        width: 56px;
    }

    .main-screen-gradient3 .defiro-carousel .next-slider {
        bottom: -80px;
        top: auto;
        right: 30%;
        height: 56px;
        width: 56px;
    }

    .main-screen-gradient3 .feedback-carousel .prev-slider {
        bottom: -80px;
        top: auto;
        left: 30%;
        height: 56px;
        width: 56px;
    }

    .main-screen-gradient3 .defiro-carousel .prev-slider {
        bottom: -80px;
        top: auto;
        left: 30%;
        height: 56px;
        width: 56px;
    }

    .how-it-works-wrapper .specializations-carousel .next-slider {
        bottom: -80px;
        top: auto;
        right: 30%;
        height: 56px;
        width: 56px;
    }

    .how-it-works-wrapper .specializations-carousel .prev-slider {
        bottom: -80px;
        top: auto;
        left: 30%;
        height: 56px;
        width: 56px;
    }

    .homepage-newsletter .mailchimp-form {
        width: 100%;
    }

}

@media (max-width: 1199px) {
    .main-screen-gradient1 .green-button {
        width: 300px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .accordion-wrapper-div {
        width: 100%;
    }

    .main-screen-gradient1 .how-it-works-wrapper .box .description {
        font-size: 26px;
    }

    .main-screen-gradient1 .how-it-works-wrapper .box .description2 {
        font-size: 26px;
    }
}

@media (max-width: 991px) {
    .main-screen-gradient1 .how-it-works-wrapper .box .description {
        font-size: 20px;
    }

    .main-screen-gradient1 .how-it-works-wrapper .box .description2 {
        font-size: 20px;
    }

    .homepage-benefits .benefit-item {
        padding-left: 50px;
        padding-right: 50px;
    }

    .homepage-benefits .benefit-item .title {
        font-size: 18px;
    }
}

@media (max-width: 767px) {
    .main-screen-gradient1 .green-button {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
    }

    .main-screen-gradient2 .box-white .accordion-button {
        padding: 16px 15px;
    }
}

.platform-login-button-container {
    width: 100%;
}

.platform-login-button-container .column-login {
    width: 180px;
    margin-left: auto;
    margin-right: auto;
}

.container-noMobile {
    display: flex;
    flex-wrap: wrap;
    padding-left: 12px;
    justify-content: space-between;
}

.container-noMobile .box {
    flex: 0 0 32.5%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}

.container-noMobile .box .row {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 0;
    justify-content: flex-start !important;
    margin-bottom: 10px;
}

.container-noMobile .box-img {
    margin-top: auto;
    margin-bottom: 0px;
}

.platform-login-button-container {
    width: 100%;
}

.platform-login-button-container .column-login {
    width: 180px;
    margin-left: auto;
    margin-right: auto;
}

.navigation .column {
    width: 170px;
}

.defiro-carousel-product {
    text-align: center;
}

.defiro-carousel-product a {
    color: #000000;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-decoration: none;
}

.defiro-carousel-product img {
    width: 100%;
}
