.wip-bg {
    background-image: url("../assets/images/background_blur.png");
    width: 100%;
    height: 100vh;
    background-position: 50% 50%;
    background-size: cover;
}

.ringdoc-logo {
    background-image: url("../assets/images/ringdoc-black.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center center;
    width: 87px;
    height: 23px;
    display: inline-block;
}

.store-image {
    max-height: 50px;
    width: auto;
    display: block;
}
@media (max-width: 1024px){
    .mentenante-page .header-title {
        padding-top: 30px;
    }
    .mentenante-page .wrapper-container {
        padding: 0!important;
    }
    .mentenante-page .w-75 {
        width: 100%!important;
    }
    .mentenante-page .store-image {
        margin: 10px 0;
    }
}
