section.account .title-section {
    margin-top: 40px;
    margin-bottom: 40px;
}

section.account .title-section h1 {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 110%;
    text-align: center;
    color: #001315;
    margin: 0;
}

section.account .menu-box {
    width: 312px;
    flex: 0 0 auto;
}

section.account .nav {
    border-radius: 12px;
    border: 0;
    flex-direction: column;
    width: 100%;
    margin-bottom: 10px;
}

section.account .nav .nav-item a {
    padding: 0 20px;
    border: 0;
    margin: 0;
    border-radius: 0;
    background: #FFFFFF;
    display: flex;
    align-items: center;
    cursor: pointer;
}

section.account .nav .nav-item a.active {
    background: #FF6A39;
}

section.account .nav .nav-item a > div {
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #F2F3F3;
    padding: 12px 0 11px 0;
}

section.account .nav.small-nav .nav-item a > div {
    padding: 14px 0 13px 0;
}

section.account .nav .nav-item a.active > div {
    border-bottom: 1px solid transparent;
}

section.account .nav .nav-item:last-child a > div {
    border-bottom: 1px solid transparent;
}

section.account .nav .nav-item:first-child a {
    border-radius: 12px 12px 0 0;
}

section.account .nav .nav-item:last-child a {
    border-radius: 0 0 12px 12px;
}

section.account .nav.single-nav .nav-item a {
    border-radius: 12px;
}

section.account .nav .nav-item a .title {
    display: inline-flex;
    flex-direction: column;
    margin-right: auto;
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
}

section.account .nav .nav-item a.active .title {
    color: #FFFFFF;
}

section.account .nav .nav-item a .title > div {
    display: flex;
}

section.account .nav .nav-item a .title .number {
    background: #FF6A39;
    border-radius: 10px;
    width: 20px;
    height: 20px;
    margin-left: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    padding-top: 2px;
}

section.account .nav .nav-item a.active .title .number {
    background: #FFFFFF;
    color: #FF6A39;
}

section.account .nav .nav-item a .title .description {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #80898A;
    padding-right: 10px;
}

section.account .nav .nav-item a.active .title .description {
    color: #FFCCB9;
}

section.account .nav .nav-item a svg {
    margin-left: 10px;
}

section.account .nav .nav-item a.active svg path {
    fill: #FFFFFF !important;
}

section.account .content-box {
    width: calc(100% - 312px);
    flex: 0 0 auto;
}

section.account .tab-content {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 50px;
    min-height: 600px;
}

section.account .tab-content .empty-section {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #80898A;
    min-height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
}

section.account .tab-content .subtitle {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #80898A;
    margin-top: 20px;
    margin-bottom: 10px;
}

section.account .tab-content .title-section {
    margin-top: 0;
    margin-bottom: 20px;
}

section.account .tab-content .title-section h2 {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 110%;
    color: #001315;
    margin: 0;
}

section.account .menu-box .menu-account-mobile {
    display: none;
}

@media (max-width: 991px) {
    section.account .menu-box .menu-account-mobile {
        display: flex !important;
    }

    section.account .menu-box > .nav.nav-tabs {
        display: none !important;
    }

    section.account .content-box {
        display: block;
    }

    section.account .menu-box,
    section.account .content-box {
        width: 100% !important;
    }
}

section.account .menu-box .menu-account-mobile .dropdown-toggle::after {
    display: none !important;
}

section.account .menu-box .menu-account-mobile .dropdown {
    width: 100%;
    z-index: 100;
}

section.account .menu-box .menu-account-mobile .dropdown-toggle {
    background: #FFF;
    box-shadow: none !important;
    outline: none !important;
    border: 0 !important;
    color: #001315;
    width: 100%;
    display: flex;
    margin-bottom: 10px;
    border-radius: 12px;
    align-items: center;
    padding: 16px 18px;
}

section.account .menu-box .menu-account-mobile .dropdown-toggle svg:first-child {
    margin-right: 10px;
}

section.account .menu-box .menu-account-mobile .dropdown-toggle svg:last-child {
    margin-left: auto;
}

section.account .menu-box .menu-account-mobile .dropdown-menu.show {
    width: 100%;
    border: 0;
    background: transparent;
    padding: 0;
    margin-top: 10px;
}

.dropdown-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #001315 !important;
    z-index: 98 !important;
}

.dropdown-backdrop.show {
    opacity: .5;
}

section.account .box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 0px;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

section.account .box .medic-card-header {
    height: 72px;
    padding: 18px;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    display: flex;
    justify-content: flex-end;
}

section.account .box .medic-card-header.online {
    background: #B3E6DF;
}

section.account .box .medic-card-header.busy {
    background: #FBE6BD;
}

section.account .box .medic-card-header.offline {
    background: #E5E7E8;
}

section.account .box .medic-card-header .share-btn {
    background: rgba(255, 255, 255, 0.8);
    width: 36px;
    height: 36px;
    text-align: center;
    vertical-align: middle;
    padding-top: 4px;
    cursor: pointer;
    border-radius: 50%;
    margin-left: 10px;
}

section.account .box .medic-card-header .favorite {
    background: rgba(255, 255, 255, 0.8);
    width: 36px;
    height: 36px;
    text-align: center;
    vertical-align: middle;
    padding-top: 4px;
    cursor: pointer;
    border-radius: 50%;
    margin-left: 10px;
}

section.account .box .link-box {
    padding-bottom: 10px;
    flex: 1;
    flex-wrap: wrap;
    justify-content: flex-start;
}

section.account .box .rating {
    font-family: Rota, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #80898A;
    margin-bottom: 15px;
    display: flex;
    flex: 1 1 100%
}

section.account .box .rating svg {
    margin-right: 5px;
}

section.account .box .medic-card-header.find-medic-card-header {
    background: #FFDBD0;
    justify-content: center;
    margin-bottom: 57px;
}


section.account .account-favorite-medics .box .medic-card-header .favorite {
    display: none;
}

section.account .box .share-btn .share-icon {
    background-image: url("../../shared/assets/icons/share-btn-icon.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center center;
    display: inline-block;
    width: 24px;
    height: 24px;
}

section.account .box .medic-info {
    display: flex;
    margin-bottom: 10px;
    padding: 0px 20px 0px 20px;
    flex-grow: 1;
}

section.account .box .medic-info .left-column {
    display: flex;
    flex-direction: column;
    margin-right: auto;
    min-height: 72px;
    width: 100%;
}

section.account .box .medic-info .name {
    font-family: Rota, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    color: #001315;
}

section.account .box .medic-info .specialization {
    font-family: Rota, serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #001315;
    margin-bottom: 2px;
}

section.account .box .medic-info .clinic {
    font-family: Rota, serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #80898A;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

section.account .box .medic-info .clinic .info-clinic {
    font-family: Rota, serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #80898A;
    justify-content: flex-start;
}

section.account .box .medic-info .clinic .avatar {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    width: 35px;
    height: 35px;
    border-radius: 100%;
    border: 1px solid rgba(0, 19, 21, 0.1);
    position: relative;
    margin-right: 10px;
    justify-content: flex-end;
}

section.account .box .medic-info .profile-picture {
    display: flex;
    align-items: center;
    margin-left: 20px;
}

section.account .box .medic-info .profile-picture .avatar {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    width: 66px;
    height: 66px;
    border-radius: 100%;
    border: 1px solid rgba(0, 19, 21, 0.1);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

section.account .box .medic-info .profile-picture .avatar .status {
    border: 3px solid #FFFFFF;
    width: 21px;
    height: 21px;
    border-radius: 100%;
    position: absolute;
    top: -3px;
    left: -3px;
}

section.account .box .medic-info .profile-picture svg {
    position: relative;
    right: -8px;
}

section.account .box .rating {
    display: flex;
    justify-content: space-between;
    flex: 1 1 100%;
    height: 50px
}

section.account .box .rating .profile-picture {
    align-items: center;
    margin-left: 20px;
    margin-top: -50px;
    height: 100px;
}

section.account .box .rating .profile-picture .avatar {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    width: 100px;
    height: 100px;
    border-radius: 100%;
    border: 3px solid #FFFFFF;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

section.account .box .rating .profile-picture .avatar .status {
    border: 3px solid #FFFFFF;
    width: 21px;
    height: 21px;
    border-radius: 100%;
    position: absolute;
    top: 72px;
    left: 72px;
}

section.account .box .rating .profile-picture .avatar .image-placeholder {
    font-size: 2.5em;
    font-weight: 600;
}

section.account .box .rating .profile-picture svg {
    position: relative;
    right: -8px;
}

section.account .box .rating .rating-value {
    height: 25px;
    margin-top: 20px;
    margin-right: 20px;
    vertical-align: center;
    display: flex;
    padding: 5px 10px 6px;
    border: 1px solid #E5E7E8;
    border-radius: 12px;
}

section.account .box .rating .rating-value .custom-icon {
    display: block !important;
}

section.account .box .rating .rating-value span {
    display: block;
    font-size: 16px;
    color: #001315;
}


section.account .box .footer-box .subtitle {
    font-family: Rota, serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #001315;
    margin-bottom: 2px;
}

section.account .box .footer-box .appointment-btn-texts .subtitle {
    font-family: Rota, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #80898A;
    margin-bottom: 2px;
}


section.account .box .footer-box .availability {
    font-family: Rota, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #80898A;
}

section.account .box .medic-info .profile-picture .avatar .status.online {
    background: #00CFB4;
}

section.account .box .medic-info .profile-picture .avatar .status.offline {
    background: #BFC4C5;
}

section.account .box .medic-info .profile-picture .avatar .status.busy {
    background: #F6BE00;
}

section.account .box .rating .profile-picture .avatar .status.online {
    background: #00CFB4;
}

section.account .box .rating .profile-picture .avatar .status.offline {
    background: #BFC4C5;
}

section.account .box .rating .profile-picture .avatar .status.busy {
    background: #F6BE00;
}

section.account .box .description {
    font-family: Rota, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #80898A;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin: 0px 20px 20px 20px;
    padding-top: 12px;
    border-top: 1px solid #F2F3F3 !important;
    flex: 1 1 100%;
}

section.account .box .footer-box {
    padding: 12px 20px 20px;
}

section.account .box .price {
    font-family: Rota, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #FF6A39;
    margin-bottom: 10px;
}

section.account .box .action-buttons {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 -5px;
    position: relative;
}

section.account .box .action-buttons .medic-action-btn {
    font-family: Rota, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    box-sizing: border-box;
    border-radius: 12px;
    padding: 20px 15px;
    margin: 0 5px;
    display: flex;
    text-decoration: none;
    flex: 1 1 100%;
}

section.account .box .action-buttons .appointment-btn {
    color: #001315;
    background: #FFFFFF;
    border: 1px solid #E5E7E8;
    display: flex;
    margin-bottom: 10px;
    height: 56px;
    padding: 9px 15px;
}

section.account .box .action-buttons .appointment-btn .appointment-btn-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
}

section.account .box .action-buttons .appointment-btn .appointment-btn-texts {
    padding-left: 20px;
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
}

section.account .box .action-buttons .appointment-btn .appointment-btn-texts .appointment-btn-texts-schedule {
    flex: 1 1 100%;
}

section.account .box .action-buttons .call-now-btn {
    color: #FFFFFF;
    background: #00CFB4;
    border: 1px solid #00CFB4;
    justify-content: center;
    padding: 15px;
}

section.account .box .action-buttons .call-now-btn .call-now-btn-text {
    padding-top: 2px;
}

section.account .box .action-buttons .busy-btn {
    color: #F4AF23;
    background: #FEF5D9;
    border: 1px solid #FEF5D9;
    pointer-events: none;
}

section.account .box .action-buttons .offline-btn {
    color: #80898A;
    background: #F2F3F3;
    border: 1px solid #F2F3F3;
    pointer-events: none;
    padding: 16px;
}

section.account .box .action-buttons .call-now-btn svg {
    margin-right: 13px;
}

section.account .box .medic-card-header.find-medic-card-header .avatar {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    width: 176px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("shared/assets/images/doctors.png");
}

section.account .box .find-medic-card-info {
    padding: 0px 100px 20px;
}

section.account .box .find-medic-card-info-text {
    font-family: 'Rota';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: #001315;
}

section.account .box .find-medic-card-info-text-details {
    font-family: 'Rota';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #001315;
}

section.account .box .footer-box.find-medic-card-footer .action-buttons {
    padding: 0px 80px 0px;
}

section.account .box .footer-box.find-medic-card-footer .action-buttons .call-now-btn {
    background: #FF6A39;
    border-color: #FF6A39;
}

section.account .box .footer-box.find-medic-card-footer .find-medic-card-footer-text {
    padding: 12px 80px 0px;
}

@media (max-width: 767px) {
    section.account .box .find-medic-card-info {
        padding: 0px 20px 20px;
    }

    section.account .box .find-medic-card-info-text {
        font-size: 20px;
        line-height: 25px;
        text-align: left;
        color: #001315;
    }

    section.account .box .find-medic-card-info-text-details {
        font-size: 18px;
        line-height: 22px;
        text-align: left;
    }

    section.account .box .footer-box.find-medic-card-footer .action-buttons {
        padding: 0px 0px 0px;
    }

    section.account .box .footer-box.find-medic-card-footer .find-medic-card-footer-text {
        padding: 12px 0px 0px;
    }

}
