section.wallet {
    max-width: 400px;
    margin: auto;
}
section.wallet .title-section {
    margin-top: 40px;
    margin-bottom: 40px;
}
section.wallet .title-section .small-title-text {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #001315;
    margin-top: 8px;
}
section.wallet .title-section h1 {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 110%;
    text-align: center;
    color: #001315;
    margin: 0;
}
section.wallet .subtitle {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #80898A;
    margin-top: 20px;
    margin-bottom: 10px;
}

section.wallet .nav.nav-tabs {
    border: 0;
    display: flex;
    margin-left: -5px;
    margin-right: -5px;
    margin-bottom: 30px;
}
section.wallet .nav.nav-tabs .nav-item {
    flex: 1 1 auto;
    padding: 0 5px;
}
section.wallet .nav.nav-tabs .nav-item .nav-link {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #001315;
    background: #E5E7E8;
    border-radius: 10px;
    padding: 8px 20px;
    border: 0;
    cursor: pointer;
}
section.wallet .nav.nav-tabs .nav-item .nav-link.active {
    background: #FFFFFF;
}

section.wallet .income-box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 18px 20px 14px 20px;
    margin-bottom: 20px;
}
section.wallet .income-box .monthly-income {
    text-decoration: none!important;
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
}
section.wallet .income-box .monthly-income .subtitle{
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #80898A;
    margin-top: 0px;
    margin-bottom: 10px;
}

section.wallet .income-box .monthly-income .amount {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 36px;
    color: #00CFB4;
    margin-bottom: 4px;
}
section.wallet .income-box .monthly-income .estimate {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    color: #001315;
}
section.wallet .income-box .monthly-income .arrow {
    position: absolute;
    top: 0;
    bottom: 0;
    right: -8px;
    margin: auto;
}
section.wallet .load-more-btn {
    margin-top: 30px;
    padding: 8px 20px;
    min-height: 46px;
}

section.wallet .withdrawals .withdrawals-box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 13px 20px;
    margin-bottom: 10px;
}
section.wallet .withdrawals .withdrawals-box:last-child {
    margin-bottom: 0;
}
section.wallet .withdrawals .withdrawals-box a {
    text-decoration: none!important;
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
}
section.wallet .withdrawals .withdrawals-box a .details {
    margin-left: 16px;
}
section.wallet .withdrawals .withdrawals-box a .details .amount {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
    margin-bottom: 2px;
}
section.wallet .withdrawals .withdrawals-box a .details .data {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #80898A;
}
section.wallet .withdrawals .withdrawals-box a .arrow {
    position: absolute;
    top: 0;
    bottom: 0;
    right: -8px;
    margin: auto;
}

section.wallet .download-all-invoices-btn {
    margin-bottom: 10px;
    min-height: 46px;
    padding: 8px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
section.wallet .download-all-invoices-btn svg {
    margin-right: 10px;
}
section.wallet .unfolded-box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 17px 20px;
    margin-bottom: 16px;
}
section.wallet .unfolded-box:last-child {
    margin-bottom: 0;
}
section.wallet .unfolded-box .header {
    text-decoration: none!important;
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
}
section.wallet .unfolded-box .header .details {
    margin-left: 16px;
    width: 100%;
    margin-right: 32px;
}

section.wallet .unfolded-box .header .details .info-text {
    font-family: Rota;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1em;
    /*text-transform: uppercase;*/
    color: #80898A;
    margin-right: auto;}

section.wallet .unfolded-box .header .details .amount {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: right;
    color: #001315;
}

/*section.wallet .unfolded-box .header .details .amount {*/
/*    font-family: Rota;*/
/*    font-style: normal;*/
/*    font-weight: bold;*/
/*    font-size: 18px;*/
/*    line-height: 22px;*/
/*    color: #001315;*/
/*    margin-bottom: 2px;*/
/*}*/
section.wallet .unfolded-box .header .details .fullName {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #80898A;
    margin-bottom: 3px;
}
section.wallet .unfolded-box .header .details .data {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #80898A;
}
section.wallet .unfolded-box .header .arrow {
    position: absolute;
    top: 0;
    bottom: 0;
    right: -8px;
    margin: auto;
}
section.wallet .amount-refunded {
    background: rgba(235, 51, 0, 0.1);
    border-radius: 12px;
    padding: 11px 20px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 16px;
}
section.wallet .amount-refunded svg {
    margin-right: 16px;
}
section.wallet .amount-refunded .text {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #EB3300;
}
section.wallet .amount-refunded .amount {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-align: right;
    color: #EB3300;
    display: inline-flex;
    margin-left: auto;
}
section.wallet .download-invoice-btn {
    border: 1px solid #E5E7E8;
    color: #00CFB4;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 46px;
    padding: 8px 20px;
    margin-top: 5px;
}
section.wallet .download-invoice-btn svg {
    margin-right: 10px;
}

section.wallet .empty-container {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #80898A;
    margin-top: 60px;
}

section.wallet .total-box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 15px 20px;
    margin-bottom: 20px;
}
section.wallet .total-box .list-group {
    border-radius: 0;
}
section.wallet .total-box .list-group-item {
    margin-bottom: 10px;
    border: 0;
    padding: 0 0 7px 0;
    border-bottom: 1px solid #F2F3F3;
    display: flex;
    align-items: center;
}
section.wallet .total-box .list-group-item .title {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #80898A;
    margin-right: auto;
}
section.wallet .total-box .list-group-item .details {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: right;
    color: #001315;
}
section.wallet .total-box .list-group-item:last-child {
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 0;
}