.how-it-works.gradient-how-it-works-patient {
    background-image: url("../assets/images/how-it-works-patient-big-image.png");
    background-size: auto;
    background-position: center 66px;
    background-repeat: no-repeat;
    width: 100%;
}

.how-it-works.gradient-homepage-doctor {
    background-image: url("../assets/images/how-it-works-patient-big-image.png");
    background-size: auto;
    background-position: center 66px;
    background-repeat: no-repeat;
    width: 100%;
}

.how-it-works.gradient-how-it-works-doctor {
    background-image: url("../assets/images/gradient-cum-functioneaza-medici.png");
    background-size: contain;
    background-position: center top;
    background-repeat: no-repeat;
    width: 100%;
}

.how-it-works section.title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.how-it-works section.title h1 {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 110%;
    text-align: center;
    color: #001315;
    margin-top: 60px;
    margin-bottom: 20px;
    max-width: 670px;
}

.how-it-works section.title h2 {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 110%;
    text-align: center;
    color: #001315;
    margin-bottom: 60px;
    max-width: 670px;
}

.how-it-works section.video {
    margin-bottom: 40px;
}

.how-it-works section.video h2 {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 110%;
    text-align: center;
    color: #001315;
    margin-bottom: 50px;
    margin-top: 20px;
}

.how-it-works section.video .box-number {
    background: #FFFFFF;
    border-radius: 12px;
    margin-bottom: 20px;
    padding: 40px;
    display: flex;
    align-items: center;
}

.how-it-works section.video .box-number .circle {
    border-radius: 100%;
    width: 122px;
    height: 122px;
    margin-right: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-family: 'Rota';
    font-style: normal;
    font-weight: 700;
    font-size: 90px;
    line-height: 110%;
    text-align: center;
    color: #FFFFFF;
}

.how-it-works section.video .box-number .circle span {
    height: 80px;
}

.how-it-works section.video .box-number.patient .circle.one {
    background: #EB3300;
}

.how-it-works section.video .box-number.patient .circle.two {
    background: #FF6A39;
}

.how-it-works section.video .box-number.patient .circle.three {
    background: #FF9775;
}

.how-it-works section.video .box-number.doctor .circle.one {
    background: #455A64;
}

.how-it-works section.video .box-number.doctor .circle.two {
    background: #259586;
}

.how-it-works section.video .box-number.doctor .circle.three {
    background: #00CFB4;
}

.how-it-works section.video .box-number.doctor .circle.four {
    background: #65DFCF;
}

.how-it-works section.video .box-number .description {
    font-family: 'Rota';
    font-style: normal;
    font-weight: 500;
    color: #000000;
    display: inline-flex;
    width: calc(100% - 122px - 40px);
}

.how-it-works section.video .box-number.patient .description {
    font-size: 18px;
    line-height: 22px;
}

.how-it-works section.video .box-number.doctor .description {
    font-size: 24px;
    line-height: 30px;
}

.how-it-works section.video p {
    font-family: 'Rota';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: #001315;
    margin-bottom: 40px;
    padding: 0 40px;
}

.how-it-works section.video .frame-video {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 458px;
    background: rgba(255, 255, 255, 0.001);
    border: 6px solid #FFFFFF;
    box-shadow: inset 0px 0px 111px 40px rgba(255, 255, 255, 0.6);
    border-radius: 24px;
    margin-bottom: 60px;
    margin-top: 30px;
}

.how-it-works section.video .frame-video .circle-play {
    width: 102px;
    height: 102px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FFF;
    border-radius: 100%;
}

.modal-video .iframe-responsive {
    max-width: 100%;
    padding-top: 56.25%;
    position: relative;
    width: 100%;
}

.modal-video .iframe-responsive iframe {
    border-radius: 24px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.modal.modal-video .modal-dialog {
    max-width: 1160px;
}

.modal.modal-video .modal-body {
    padding: 0;
}

.modal.modal-video .modal-content {
    background: transparent;
    border-radius: 24px;
}

.modal.modal-video .modal-header .btn-close {
    position: absolute;
    right: 0;
    top: -60px;
    background: #FFFFFF !important;
    border-radius: 12px;
    padding: 7px 20px;
}

.modal.modal-video .modal-header .btn-close:after {
    font-family: 'Rota';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #001315;
}

.modal.modal-video .modal-header {
    padding: 0;
    border-bottom: 0;
}

.how-it-works section.benefits h2 {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 110%;
    text-align: center;
    color: #001315;
    margin-top: 40px;
    margin-bottom: 30px;
}

.how-it-works section.benefits .colp10 {
    margin-bottom: 20px;
}

.how-it-works section.benefits .box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 40px;
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.how-it-works section.benefits .box p {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 110%;
    text-align: center;
    color: #001315;
    margin-top: 20px;
    margin-bottom: 0;
}

.how-it-works section.benefits .conceptul-icon {
    background-image: url("../assets/icons/about-us/conceptul-icon.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    width: 80px;
    height: 80px;
    display: inline-flex;
}

.how-it-works section.benefits .disponibila-icon {
    background-image: url("../assets/icons/about-us/disponibila-icon.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    width: 80px;
    height: 80px;
    display: inline-flex;
}

.how-it-works section.benefits .documente-icon {
    background-image: url("../assets/icons/about-us/documente-icon.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    width: 80px;
    height: 80px;
    display: inline-flex;
}

.how-it-works section.benefits .fluxul-icon {
    background-image: url("../assets/icons/about-us/fluxul-icon.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    width: 80px;
    height: 80px;
    display: inline-flex;
}

.how-it-works section.benefits .platforme-icon {
    background-image: url("../assets/icons/about-us/platforme-icon.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    width: 80px;
    height: 80px;
    display: inline-flex;
}

.how-it-works section.benefits .servicii-icon {
    background-image: url("../assets/icons/about-us/servicii-icon.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    width: 80px;
    height: 80px;
    display: inline-flex;
}

.how-it-works section.faq {
    margin-top: 60px;
    margin-bottom: 60px;
}

.how-it-works section.faq h3 {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 110%;
    text-align: center;
    color: #001315;
    margin-bottom: 30px;
}

.how-it-works section.faq .accordion {
    background-color: #FFFFFF;
    border-radius: 12px;
}

.how-it-works section.faq .accordion-item {
    border: 0;
    border-radius: 0;
    background-color: transparent;
}

.how-it-works section.faq .accordion-item .accordion-header.card-header .accordion-button {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: #001315;
}

.how-it-works section.faq .accordion-item:first-child .accordion-header {
    border: 0;
}

.how-it-works section.faq .accordion-header {
    display: flex;
    align-items: center;
    padding: 18px 20px;
    border-radius: 0;
    border-bottom: 0;
    border-top: 1px solid #E5E7E8;
    background-color: transparent;
}

.how-it-works section.faq .accordion-button {
    padding: 0;
    background-color: transparent;
    box-shadow: none;
}

.how-it-works section.faq .accordion-button:focus {
    border: none;
}

.how-it-works section.faq .accordion-body {
    padding: 0 20px;
}

.how-it-works section.faq .accordion-body p {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #80898A;
}

.how-it-works section.faq .accordion-header .accordion-button::after {
    background-image: url("../assets/images/about-us/plus-icon.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center center;
    width: 24px;
    height: 24px;
    display: inline-flex;
}

.how-it-works section.faq .accordion-header .accordion-button:not(.collapsed)::after {
    background-image: url("../assets/images/about-us/minus-icon.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center center;
    width: 24px;
    height: 24px;
    display: inline-flex;
}

.how-it-works section.download-app {
    background-color: #FFFFFF;
    border-radius: 12px;
    padding: 6px;
    margin-bottom: 30px;
    display: flex;
}

.how-it-works section.download-app .column {
    display: inline-flex;
    flex-direction: column;
    width: 50%;
}

.how-it-works section.download-app .column h3 {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 110%;
    color: #001315;
    margin-top: 34px;
    margin-left: 34px;
    margin-bottom: 60px;
}

.how-it-works section.download-app .column .apps-button {
    margin-bottom: 38px;
    margin-left: 34px;
}

.how-it-works section.download-app .app-store-icon {
    background-image: url("../assets/images/app-store-icon-black.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    width: 143px;
    height: 49px;
    display: inline-flex;
    margin-right: 14px;
    cursor: pointer;
}

.how-it-works section.download-app .google-play-icon {
    background-image: url("../assets/images/google-play-icon-black.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    width: 159px;
    height: 49px;
    display: inline-flex;
    cursor: pointer;
}

.how-it-works section.download-app.patient .column:last-child {
    background: #FF6A39;
    border-radius: 8px;
    align-items: center;
    justify-content: flex-end;
}

.how-it-works section.download-app.medic .column:last-child {
    background: #00CFB4;
    border-radius: 8px;
    align-items: center;
    justify-content: flex-end;
}

.how-it-works section.download-app .column img {
    max-width: 316px;
}

.how-it-works .landing-registration-btn {
    max-width: 400px;
    margin: auto;
}


@media (max-width: 1024px) {
    .how-it-works section.title h1 {
        font-size: 36px;
        margin-top: 40px;
        margin-bottom: 40px;
        max-width: none;
    }

    .how-it-works section.find-specialists {
        margin-bottom: 40px;
    }

    .how-it-works section.find-specialists h2 {
        font-size: 26px;
        margin-bottom: 25px;
    }

    .how-it-works section.benefits .box {
        padding: 25px;
        min-height: auto;
    }

    .how-it-works section.benefits .box h3 {
        font-size: 26px;
    }

    .how-it-works section.benefits .box p {
        font-size: 16px;
        line-height: 20px;
    }

    .how-it-works section.benefits .colp10:nth-child(2) .box,
    .how-it-works section.benefits .colp10:nth-child(4) .box,
    .how-it-works section.benefits .colp10:nth-child(6) .box,
    .how-it-works section.benefits .colp10:nth-child(8) .box {
        min-height: 340px;
        margin-bottom: 20px;
    }

    .how-it-works section.benefits .colp10:nth-child(3) {
        order: 3;
    }

    .how-it-works section.benefits .colp10:nth-child(4) {
        order: 4;
    }

    .how-it-works section.benefits .colp10:nth-child(7) {
        order: 7;
    }

    .how-it-works section.benefits .colp10:nth-child(8) {
        order: 8;
    }

    .how-it-works section.benefits h2 {
        font-size: 36px;
        margin-bottom: 25px;
    }

    .how-it-works section.benefits {
        margin-bottom: 20px;
    }

    .how-it-works section.faq h3 {
        font-size: 36px;
        margin-bottom: 25px;
    }

    .how-it-works section.faq .card-header .title {
        font-size: 20px;
        line-height: 26px;
    }

    .how-it-works section.faq .card-body p {
        font-size: 16px;
        line-height: 20px;
    }

    .how-it-works section.download-app {
        padding: 15px 15px 0 15px;
    }

    .how-it-works section.download-app .column h3 {
        font-size: 26px;
        margin-top: 10px;
        margin-left: 0;
        margin-bottom: 30px;
    }

    .how-it-works section.download-app .column .apps-button {
        margin-bottom: 30px;
        margin-left: 0;
    }

    .how-it-works section.download-app {
        flex-direction: column;
    }

    .how-it-works section.download-app .column {
        width: 100%;
    }

    .how-it-works section.download-app.patient .column:last-child {
        margin-left: -15px;
        margin-right: -15px;
        width: calc(100% + 30px);
        padding-top: 50px;
    }
}

@media (max-width: 767px) {
    .how-it-works section.video .box-number {
        background: #FFFFFF;
        border-radius: 12px;
        margin-bottom: 20px;
        padding: 20px 10px;
        display: flex;
        align-items: center;
    }

    .how-it-works section.video .box-number .circle {
        flex: 0 0 60px;
        border-radius: 100%;
        width: 60px;
        height: 60px;
        margin-right: 10px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 40px;
        text-align: center;
        color: #FFFFFF;
        padding-top: 3px;
    }

    .how-it-works section.video .box-number .circle span {
        height: 40px;
    }

    .how-it-works section.video .box-number .description {
        width: calc(100% - 12px - 4px);
    }

    .how-it-works section.video .box-number.doctor .description {
        font-size: 16px;
        line-height: 110%;
    }
}
