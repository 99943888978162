.partners-page section.title {
    display: flex;
    justify-content: center;
}

.partners-page section.title h1 {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 110%;
    text-align: center;
    color: #001315;
    margin-top: 60px;
    margin-bottom: 60px;
}

.partners-page .partners .partners-info {
    display: flex;
    margin-bottom: 20px;
    /*align-items: end;*/
}

.partners-page .partners .partners-info .partners-image {
    max-width: 45%;
}

.partners-page .partners .partners-info .partners-description {
    max-width: 55%;
    font-family: Rota;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    color: #001315;
    margin-bottom: 0px !important;
    text-align: justify;
}

.partners-page .partners .partners-info .partners-description.partners-description-mobile {
    max-width: 100%;
    text-align: justify;
}


.partners-page section.partners .box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 50px 40px;
    margin-bottom: 20px;
}

.partners-page section.partners .box .text-box {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
}

.partners-page section.partners .box h3 {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 110%;
    color: #001315;
    margin-bottom: 10px;
}

.partners-page section.partners .box.viziune {
    background-color: #FF6A39;
    width: 171px;
    height: 171px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    padding: 0;
    margin: 0;
    margin-left: auto;
}

.partners-page section.logos {
    display: flex;
    justify-content: space-between;
}

.partners-page section.logos.logos-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.partners-page section.logos .white-box {
    background-color: #FFFFFF;
    max-width: 258px;
    height: 258px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 4;
    margin-right: 20px;
    padding: 20px;
}

.partners-page section.logos.logos-mobile .white-box {
    width: 258px;
    margin-right: 0px;
    margin-bottom: 20px;
}

.partners-page section.logos .white-box:last-child {
    margin-right: 0px;
}

.partners-page section.download-app {
    background-color: #FFFFFF;
    border-radius: 12px;
    padding: 6px;
    margin-bottom: 30px;
    margin-top: 60px;
    display: flex;
}

.partners-page section.download-app .column {
    display: inline-flex;
    flex-direction: column;
    width: 50%;
}

.partners-page section.download-app .column h3 {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 110%;
    color: #001315;
    margin-top: 34px;
    margin-left: 34px;
    margin-bottom: 60px;
}

.partners-page section.download-app .column .apps-button {
    margin-bottom: 38px;
    margin-left: 34px;
}

.partners-page section.download-app .app-store-icon {
    background-image: url("../assets/images/app-store-icon-black.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    width: 143px;
    height: 49px;
    display: inline-flex;
    margin-right: 14px;
    cursor: pointer;
}

.partners-page section.download-app .google-play-icon {
    background-image: url("../assets/images/google-play-icon-black.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    width: 159px;
    height: 49px;
    display: inline-flex;
    cursor: pointer;
}

.partners-page section.download-app.patient .column:last-child {
    background: #FF6A39;
    border-radius: 8px;
    align-items: center;
    justify-content: flex-end;
}

.partners-page section.download-app.medic .column:last-child {
    background: #00CFB4;
    border-radius: 8px;
    align-items: center;
    justify-content: flex-end;
}

.partners-page section.download-app .column img {
    max-width: 316px;
}

@media (max-width: 767px) {
    .partners-page section.logos .white-box {
        height: 220px;
    }
}

@media (max-width: 575px) {
    .partners-page section.logos .white-box {
        height: 170px;
    }
}

@media (max-width: 1024px) {
    .partners-page section.title h1 {
        font-size: 36px;
        margin-top: 40px;
        margin-bottom: 40px;
        max-width: none;
    }

    .partners-page section.partners p {
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 25px;
    }

    .partners-page section.download-app {
        padding: 15px 15px 0 15px;
    }

    .partners-page section.download-app .column h3 {
        font-size: 26px;
        margin-top: 10px;
        margin-left: 0;
        margin-bottom: 30px;
    }

    .partners-page section.download-app .column .apps-button {
        margin-bottom: 30px;
        margin-left: 0;
    }

    .partners-page section.download-app {
        flex-direction: column;
    }

    .partners-page section.download-app .column {
        width: 100%;
    }

    .partners-page section.download-app.patient .column:last-child {
        margin-left: -15px;
        margin-right: -15px;
        width: calc(100% + 30px);
        padding-top: 50px;
    }
}
