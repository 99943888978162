section.account-activation,
section.login,
section.forgot-password,
section.register {
    max-width: 400px;
    margin: auto;
}

section.account-activation .title-section,
section.login .title-section,
section.forgot-password .title-section,
section.register .title-section {
    margin-top: 40px;
    margin-bottom: 40px;
}

section.login .title-section .small-title-text {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #001315;
}

section.account-activation .title-section h1,
section.login .title-section h1,
section.forgot-password .title-section h1,
section.register .title-section h1 {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 110%;
    text-align: center;
    color: #001315;
    margin: 0;
}

section.login .or,
section.register .or {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #80898A;
    margin: 18px 0;
}

section.account-activation form,
section.login form,
section.forgot-password form,
section.register form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

section.register form .custom-checkbox {
    margin-top: 10px;
    margin-bottom: 20px;
}

section.register form hr {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
}

.social-login {
    display: flex;
    width: calc(100% + 10px);
    justify-content: space-between;
    margin-left: -5px;
    margin-right: -5px;
}

.social-login .login-button {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 12px 20px;
    width: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
    margin-right: 5px;
}

.forgot-pass-btn {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #001315;
    display: inline-flex;
    margin: 30px 0;
}

.facebook-login-icon {
    background-image: url("../../shared/assets/icons/auth/facebook-login-icon.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center center;
    display: inline-block;
    width: 32px;
    height: 32px;
}

.google-login-icon {
    background-image: url("../../shared/assets/icons/auth/google-login-icon.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center center;
    display: inline-block;
    width: 32px;
    height: 32px;
}

.password-eye {
    position: relative;
    width: 100%;
}

.password-eye .eye {
    position: absolute;
    top: 16px;
    right: 20px;
    cursor: pointer;
}

.forgot-pass-btn svg {
    margin-right: 3px;
}

section.login button svg,
section.forgot-password button svg,
section.register button svg {
    position: absolute;
    right: 20px;
}

.phone-number-input {
    display: flex;
    width: 100%;
}

.phone-number-input .form-select-label {
    max-width: 93px;
    margin-right: 10px;
}

section.register .address-google-places-autocomplete {
    position: relative;
    z-index: 10;
    width: 100%;
}

section.register .address-google-places-autocomplete .css-1wy0on6 {
    display: none !important;
}

section.register .address-google-places-autocomplete .css-36g5w0-control,
section.register .address-google-places-autocomplete .css-9l12cq-control {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
    background: #E5E7E8;
    border: 2px solid #BFC4C5 !important;
    border-radius: 12px;
    padding: 19px 20px;
    margin-bottom: 10px;
    height: 56px !important;
    transition: all 0.2s ease-out;
    outline: none !important;
    box-shadow: none !important;
}

section.register .address-google-places-autocomplete.is-invalid .css-36g5w0-control,
section.register .address-google-places-autocomplete.is-invalid .css-9l12cq-control {
    border: 2px solid #ff3c3c !important;
}


section.register .address-google-places-autocomplete .css-44k718-singleValue,
section.register .address-google-places-autocomplete .css-70phce {
    margin: 0 !important;
}

section.register .address-google-places-autocomplete .css-tfl430-placeholder,
section.register .address-google-places-autocomplete .css-1hwfws3 {
    padding: 0 !important;
    margin: 0 !important;
}

section.register .address-google-places-autocomplete input {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
    width: auto;
}

section.register .address-google-places-autocomplete label {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    width: 100%;
    padding-left: 20px;
    position: absolute;
    font-size: 14px;
    line-height: 17px;
    color: #80898A;
    top: 10px;
    transition: all 0.2s ease-out;
}

.form-control-invitation-code {
    margin-bottom: 30px !important;
}
