section.call-specialist {
    max-width: 400px;
    margin: auto;
}

section.call-specialist > div:first-of-type {
    margin-top: 40px
}

section.call-specialist > div:last-of-type {
    margin-bottom: 40px
}

section.call-specialist .title-section {
    margin-top: 40px;
    margin-bottom: 40px;
}

section.call-specialist .title-section h1 {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 110%;
    text-align: center;
    color: #001315;
    margin: 0;
}

section.call-specialist .text-section {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #001315;
    margin-top: -10px;
    margin-bottom: 30px;
}

section.call-specialist .steps {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    text-transform: uppercase;
    color: #80898A;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
}

section.call-specialist .steps .icon-wrapper {
    position: absolute;
    left: 0;
    cursor: pointer;
}

section.call-specialist .steps .cancel-button {
    position: absolute;
    right: 0;
    margin: 0;
    width: auto;
}

section.call-specialist .specializations .searchbar {
    background: #E5E7E8;
    border-radius: 12px;
    padding: 17px 20px;
    width: 100%;
    display: flex;
    align-items: center;
}

section.call-specialist .specializations .searchbar svg {
    margin-right: 10px;
    min-width: 20px;
}

section.call-specialist .specializations .searchbar input {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #80898A;
    width: 100%;
    background: none;
    border: 0;
    padding: 0;
    outline: none;
    box-shadow: none;
}

section.call-specialist .specializations form {
    padding: 20px 0;
}

section.call-specialist .specializations .custom-control {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 16px 20px;
    margin-bottom: 10px;
}

section.call-specialist .specializations .custom-control:last-child {
    margin-bottom: 0;
}

section.call-specialist .box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
}

section.call-specialist .box img.searching-medic-icon {
    margin: -50px 0;
}

section.call-specialist .box h1 {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: #001315;
    padding: 0 40px;
    margin: 0;
}

section.call-specialist .countdown-box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 10px 20px;
    margin-bottom: 10px;
}

section.call-specialist .countdown {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

section.call-specialist .countdown .will-start {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
    flex: 0 0 50%;
}

section.call-specialist .countdown .timer {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: #FF6A39;
    flex: 0 0 50%;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: end;
}

section.call-specialist .countdown .timer p {
    margin-bottom: 0;
}

section.call-specialist .details-box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 15px 20px;
    margin-bottom: 10px;
}

section.call-specialist .details-box .list-group {
    border-radius: 0;
}

section.call-specialist .details-box .list-group-item {
    margin-bottom: 10px;
    border: 0;
    padding: 0 0 7px 0;
    border-bottom: 1px solid #F2F3F3;
    display: flex;
    align-items: center;
}

section.call-specialist .details-box .list-group-item .title {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #80898A;
    margin-right: auto;
}

section.call-specialist .details-box .list-group-item .details {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: right;
    color: #001315;
}

section.call-specialist .details-box .list-group-item:last-child {
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 0;
}

section.call-specialist .info-message.dark {
    background: #E5E7E8;
    margin-top: 10px;
}

section.call-specialist .medic-call-box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 20px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

section.call-specialist .medic-call-box .medic-avatar {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    width: 159px;
    height: 159px;
    border-radius: 100%;
    border: 1px solid rgba(0, 19, 21, 0.1);
    margin-bottom: 15px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.3em;
}

section.call-specialist .medic-call-box .rating {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #80898A;
    display: flex;
    margin-bottom: 2px;
}

section.call-specialist .medic-call-box .rating svg {
    margin-right: 5px;
}

section.call-specialist .medic-call-box .name {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: #001315;
}

section.call-specialist .medic-call-box .specialization {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #001315;
}

section.call-specialist .medic-call-box .clinic {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #80898A;
}

section.call-specialist .white-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 9px 20px;
    min-height: 46px;
    margin-bottom: 20px;
    color: #001315 !important;
    text-decoration: none !important;
}

section.call-specialist .white-button svg {
    margin-right: 10px;
}

section.call-specialist .cancel-button {
    margin-top: 20px;
}

section.call-specialist .voucher .subtitle {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #80898A;
    margin-top: 20px;
    margin-bottom: 10px;
}

section.call-specialist .voucher .voucher-box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 13px 20px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

section.call-specialist .voucher .voucher-box .details {
    display: flex;
    align-items: center;
    margin-right: auto;
}

section.call-specialist .voucher .voucher-box .details svg {
    margin-right: 20px;
}

section.call-specialist .voucher .voucher-box .details .title {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
    margin-bottom: 2px;
    padding-right: 10px;
}

section.call-specialist .voucher .voucher-box .details .code {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #80898A;
}

section.call-specialist .voucher .voucher-box button {
    padding: 0;
    border: 0;
    background: none;
}

section.call-specialist .voucher .voucher-info {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 11px 20px;
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #00CFB4;
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

section.call-specialist .voucher .voucher-info svg {
    margin-right: 20px;
}

section.call-specialist .card-details-section-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

section.call-specialist .card-details .subtitle {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #80898A;
    margin-top: 20px;
    margin-bottom: 10px;
}

section.call-specialist .card-details .custom-checkbox {
    margin-top: 20px;
    margin-bottom: 10px;
}

section.call-specialist .card-details .card-box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 13px 20px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

section.call-specialist .card-details .card-box .details {
    display: flex;
    align-items: center;
    margin-right: auto;
}

section.call-specialist .card-details .card-box .details img {
    margin-right: 20px;
}

section.call-specialist .card-details .card-box .details .number {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
    margin-bottom: 2px;
}

section.call-specialist .card-details .card-box .details .date {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #80898A;
}

section.call-specialist .card-details .card-box button {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: right;
    color: #FF6A39;
    padding: 0;
    border: 0;
    background: none;
}

section.call-specialist .medic-box {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
}

section.call-specialist .medic-box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 12px 20px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

section.call-specialist .medic-box .avatar {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    width: 36px;
    height: 36px;
    border-radius: 100%;
    border: 1px solid rgba(0, 19, 21, 0.1);
    position: relative;
    margin-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

section.call-specialist .medic-box .name {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
}

section.call-specialist .medic-box .rating {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #80898A;
    display: flex;
}

section.call-specialist .medic-box .rating svg {
    margin-right: 5px;
}
