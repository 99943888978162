section.account .account-delete-account .info-message {
    margin-bottom: 10px;
    border: 1px solid #E5E7E8;
}
section.account .account-delete-account input.form-control:focus,
section.account .account-delete-account input.form-control {
    border: 2px solid #E5E7E8!important;
}
section.account .account-delete-account button.orange-button {
    background: #EB3300;
    border-color: #EB3300;
    margin-top: 10px;
}