.persistent-notification-bar {
    background: #00CFB4;
    border-radius: 12px;
    padding: 12px 12px 12px 20px;
    display: flex;
    align-items: center;
    margin-top: 10px;
}
.persistent-notification-bar-bar .details {
    display: inline-flex;
    flex-direction: column;
}
.persistent-notification-bar .details .status-title {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
    margin-bottom: 2px;
}
.persistent-notification-bar .details .medic-fullname {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    color: rgba(255, 255, 255, 0.8);
}
.persistent-notification-bar .time-left-counter {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-align: right;
    color: #FFFFFF;
    display: inline-flex;
    align-items: center;
    margin-left: auto;
}
.persistent-notification-bar .time-left-counter p {
    margin: 0;
}
.persistent-notification-bar .time-left-counter svg {
    margin-left: 2px;
}
